import React, { useContext } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { Problems } from './problems'
import { QAs } from './qas'
import { Validation } from './validation'
import { Interviews } from './interviews'
import { Quotes } from './quotes'
import { Needs } from './needs'
import { OverviewFeedbacks } from './feedbacks'

export function Overview() {
  const { overview, validations, feedbacks, quotes } = useContext(ProjectContext)
  const starredQuotes = quotes.filter(quote => quote.star)

  return (
    <div className="w-[917px] px-[45px] pt-[24px] bg-white rounded-2xl flex-col justify-start items-center gap-9 inline-flex">
      <p className="w-full text-[#40424A] text-4xl font-normal font-['DM_Sans'] leading-[41.40px]">Overview</p>

      {overview.background && !!overview.background.length && (
        <div className="w-full">
          <p className="mb-4 w-full pb-[10px] text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px] border-b-2 border-grey-200">
            Background
          </p>
          <p className="text-[#40424A] text-lg font-normal font-['DM_Sans'] leading-[23px]">{overview.background}</p>
        </div>
      )}

      <Interviews />

      {Array.isArray(overview.problems) && !!overview.problems.length && <Problems problems={overview.problems} />}

      {!!starredQuotes.length && <Quotes quotes={starredQuotes} label={'Key Quotes'} />}

      <Needs />

      {Array.isArray(overview.qas) && !!overview.qas.length && <QAs QAs={overview.qas} />}

      {!!validations.length && <Validation validations={validations} />}

      {!!feedbacks.length && <OverviewFeedbacks />}

      <div className='min-h-[40px] w-full' />
    </div>
  )
}
