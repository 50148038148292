import React from 'react'
import MeetingExample from '../../../assets/signin-meeting-example.png'
import { CheckMark } from './checkMark'
import { Testimonial } from '../../utils/testimonial'

export function Sidebar () {
  return (
        <div className='mt-14'>
            <img src={MeetingExample} alt="meeting-example" className="w-[305px] h-[205px] mx-auto mb-8"/>
            <div className="mx-14 pl-28">
                {['Perfect user research on every call',
                  'Miss nothing, no matter how fast it goes by',
                  'Never feel overwhelmed'].map((text, index) => <CheckMark text={text} key={index} />)}
            </div>
            <div className='ml-14 mt-12'>
              <Testimonial />
            </div>
        </div>
  )
}
