import React from 'react'

export const AddNotesIcon = () => {
  return (
    <svg width="66" height="51" viewBox="0 0 66 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2344_8646)">
        <path
          d="M42.2363 42.5L57.1113 27.625"
          stroke="#62718D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.2363 42.5V29.75C42.2363 29.1864 42.4602 28.6459 42.8587 28.2474C43.2572 27.8489 43.7977 27.625 44.3613 27.625H57.1113V12.75C57.1113 11.6228 56.6636 10.5418 55.8665 9.7448C55.0695 8.94777 53.9885 8.5 52.8613 8.5H27.3613C26.2342 8.5 25.1532 8.94777 24.3561 9.7448C23.5591 10.5418 23.1113 11.6228 23.1113 12.75V38.25C23.1113 39.3772 23.5591 40.4582 24.3561 41.2552C25.1532 42.0522 26.2342 42.5 27.3613 42.5H42.2363Z"
          stroke="#62718D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_2344_8646)">
        <path d="M9.5625 19.9219V31.0781" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.98438 25.5H15.1406" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2344_8646">
          <rect width="51" height="51" fill="white" transform="translate(14.6113)" />
        </clipPath>
        <clipPath id="clip1_2344_8646">
          <rect width="19.125" height="19.125" fill="white" transform="translate(0 15.9375)" />
        </clipPath>
      </defs>
    </svg>
  )
}
