import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import * as PropTypes from 'prop-types'
import { useScrollTo } from '../../../../hooks/useScrollTo'

function Problem({ problem, scrollToProblem, setScrollToProblem, scrollTopContainer }) {
  const divRef = useScrollTo({
    id: problem.id,
    card: false,
    entityToScrollTo: scrollToProblem,
    setScrollTo: setScrollToProblem,
    scrollTopContainer: scrollTopContainer,
  })
  return (
    <li ref={divRef}>
      <span className="text-[#40424A] text-lg font-bold font-['Manrope'] leading-loose tracking-wide capitalize">
        {problem.referent}:
      </span>
      <span className="text-[#40424A] text-lg font-normal font-['Manrope'] leading-loose tracking-wide">
        {' '}
        {problem.content}
      </span>
    </li>
  )
}

Problem.propTypes = { problem: PropTypes.any }

export function Problems() {
  const { problems, scrollToProblem, setScrollToProblem, scrollTopContainer } = useContext(MeetingsContext)

  return (
    <ul className="list-disc ml-6">
      {problems.map((problem) => (
        <Problem
          key={problem.id}
          problem={problem}
          scrollToProblem={scrollToProblem}
          setScrollToProblem={setScrollToProblem}
          scrollTopContainer={scrollTopContainer}
        />
      ))}
    </ul>
  )
}
