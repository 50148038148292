import React from 'react'

const NeedsIcon = ({ checked }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3944_11561)">
        <path
          d="M5.00065 3.33398H15.0007C15.4427 3.33398 15.8666 3.50958 16.1792 3.82214C16.4917 4.1347 16.6673 4.55862 16.6673 5.00065V10.834H12.5007C12.0586 10.834 11.6347 11.0096 11.3221 11.3221C11.0096 11.6347 10.834 12.0586 10.834 12.5007V16.6673H5.00065C4.55862 16.6673 4.1347 16.4917 3.82214 16.1792C3.50958 15.8666 3.33398 15.4427 3.33398 15.0007V5.00065C3.33398 4.55862 3.50958 4.1347 3.82214 3.82214C4.1347 3.50958 4.55862 3.33398 5.00065 3.33398Z"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6673 10.834V10.9773C16.6672 11.4193 16.4916 11.8432 16.179 12.1557L12.1557 16.179C11.8432 16.4916 11.4193 16.6672 10.9773 16.6673H10.834"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3944_11561">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NeedsIcon
