import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DashboardContext } from '../../context/DashboardContext'
import { createPortal } from 'react-dom'
import NoProjects from '../../assets/no_projects_yet.png'
import PropTypes from 'prop-types'
import { ProjectLayout } from './dialog/projectLayout'
import { EditDelete } from '../utils/editDelete'
import toast from 'react-hot-toast'
import { RenameProject } from './dialog/renameProject'
import { useCustomFetch } from '../../hooks/useCustomFetch'

export function Projects({ limit }) {
  const [openMenu, setOpenMenu] = useState('')
  const [openRenameDialog, setOpenRenameDialog] = useState('')
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false)
  const { allProjects, filteredProjects, projectsLoading } = useContext(DashboardContext)
  const projects = limit && filteredProjects.length > limit ? filteredProjects.slice(0, limit) : filteredProjects
  const navigate = useNavigate()
  const customFetch = useCustomFetch()

  async function handleDeleteProject(id) {
    try {
      toast.loading('Deleting Project...')
      await customFetch('/deleteProject', 'DELETE', { projectId: id })
      toast.success('Project deleted successfully')
      await new Promise((resolve) => setTimeout(resolve, 1000))
      // this will change to just change state but for now is enough
      window.location.reload(false)
    } catch (error) {
      console.error('Error deleting project:', error)
    }
  }

  return (
    <>
      <div className=" bg-white rounded-2xl shadow pb-12 mt-12" style={{ width: '95%' }}>
        <div className="flex justify-between items-center " style={{ width: '95%' }}>
          <p className="pt-5 ms-10 mb-6 text-zinc text-[26px] font-semibold font-['Manrope']">Projects</p>
          <button
            className="w-[77px] h-9 px-3 py-2 bg-pink rounded shadow-inner flex justify-center items-center"
            onClick={() => setShowCreateProjectDialog(true)}
          >
            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1001_3724)">
                <path
                  d="M10 4.16602V15.8327"
                  stroke="white"
                  strokeWidth="2.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.16699 10H15.8337"
                  stroke="white"
                  strokeWidth="2.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1001_3724">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p className="ml-1 text-white text-base font-semibold font-['Manrope']">Add</p>
          </button>
          {showCreateProjectDialog &&
            createPortal(
              <ProjectLayout link="" importFile={false} handleClose={() => setShowCreateProjectDialog(false)} />,
              document.body
            )}
        </div>
        {projectsLoading && (
          <div className="flex justify-center items-center">
            <svg className="w-40 h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                <stop offset="0" stopColor="#FF156D"></stop>
                <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
                <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
                <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
                <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
              </radialGradient>
              <circle
                transform-origin="center"
                fill="none"
                stroke="url(#a1)"
                strokeWidth="15"
                strokeLinecap="round"
                strokeDasharray="200 1000"
                strokeDashoffset="0"
                cx="100"
                cy="100"
                r="70"
              >
                <animateTransform
                  type="rotate"
                  attributeName="transform"
                  calcMode="spline"
                  dur="2"
                  values="360;0"
                  keyTimes="0;1"
                  keySplines="0 0 1 1"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
              <circle
                transform-origin="center"
                fill="none"
                opacity=".2"
                stroke="#FF156D"
                strokeWidth="15"
                strokeLinecap="round"
                cx="100"
                cy="100"
                r="70"
              ></circle>
            </svg>
          </div>
        )}
        {!projectsLoading && allProjects.length === 0 && (
          <div className="flex justify-center items-center my-11">
            <div className='flex items-center gap-[36px]'>
              <div className='w-[441px]'>
                <p className="text-[#676d7f] text-xl font-bold font-['Manrope'] leading-[23px]">No Projects Yet</p>
                <p className="w-[420px] mt-6 text-[#676d7f] text-base font-normal font-['Manrope'] leading-[23px]">
                  Add one now by clicking the Add button or by pasting a meeting link above.
                </p>
              </div>
              <img className="w-[134px] h-[84px] mt-5 mx-auto" src={NoProjects} alt="No upcoming projects" />
            </div>
          </div>
        )}
        {!projectsLoading &&
          projects.map((project) => (
            <div
              key={project.id}
              className="mx-8 my-2 p-4 flex items-center overflow-x-auto border-b-2 border-grey-200"
            >
              <div
                className="w-1/3 cursor-pointer flex items-center"
                onClick={() => navigate(`/project/${project.id}`)}
              >
                <div className="w-1/3">
                  <svg width="64" height="63" viewBox="0 0 64 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1167_451)">
                      <path d="M53.6044 13.7812H39.8232V29.5312H53.6044V13.7812Z" fill="#E4E4E4" />
                      <path d="M39.8232 13.5H24.0732V37.125H39.8232V13.5Z" fill="#FF4A8E" />
                      <path
                        opacity="0.2"
                        d="M24.0732 29.5312H10.2919V51.1875C10.2919 51.7096 10.4994 52.2104 10.8686 52.5796C11.2378 52.9488 11.7385 53.1562 12.2607 53.1562H22.1044C22.6266 53.1562 23.1273 52.9488 23.4965 52.5796C23.8658 52.2104 24.0732 51.7096 24.0732 51.1875V29.5312Z"
                        fill="#FF4A8E"
                      />
                      <path
                        d="M10.2919 13.7812H53.6044V43.3125C53.6044 43.8346 53.397 44.3354 53.0278 44.7046C52.6586 45.0738 52.1578 45.2812 51.6357 45.2812H41.7919C41.2698 45.2812 40.769 45.0738 40.3998 44.7046C40.0306 44.3354 39.8232 43.8346 39.8232 43.3125V37.4062H24.0732V51.1875C24.0732 51.7096 23.8658 52.2104 23.4965 52.5796C23.1273 52.9488 22.6266 53.1562 22.1044 53.1562H12.2607C11.7385 53.1562 11.2378 52.9488 10.8686 52.5796C10.4994 52.2104 10.2919 51.7096 10.2919 51.1875V13.7812Z"
                        stroke="#AAB0BA"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24.0732 29.5312H10.2919"
                        stroke="#AAB0BA"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M24.0732 13.7812V37.4062"
                        stroke="#AAB0BA"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M39.8232 29.5312H53.6044"
                        stroke="#AAB0BA"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M39.8232 13.7812V37.4062"
                        stroke="#AAB0BA"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1167_451">
                        <rect width="63" height="63" fill="white" transform="translate(0.448181)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className=" ml-4" style={{ width: '95%' }}>
                  <div className="flex items-center">
                    <p className="text-zinc text-base font-semibold font-['Manrope']">
                      {project.name.length > 18 ? project.name.substring(0, 18) + '...' : project.name}
                    </p>
                    {!!project.live_meets && (
                      <div className="h-[20px] ml-2 p-2 bg-red rounded shadow justify-center items-center gap-1 inline-flex">
                        <p className="text-white text-xs font-extrabold font-['Manrope'] tracking-wide">LIVE</p>
                      </div>
                    )}
                  </div>
                  <p className="text-slate text-base font-normal font-['Manrope']">
                    {project.description.length > 30
                      ? project.description.substring(0, 30) + '...'
                      : project.description}
                  </p>
                </div>
              </div>
              <div className="w-2/3 flex items-center justify-end">
                <div className="mr-8">
                  <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">
                    {project.needs_count ? project.needs_count : 0}
                  </p>
                  <p className="text-slate text-base font-normal font-['Manrope']">Notes</p>
                </div>
                <div className="mr-8">
                  <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">
                    {project.categories_count}
                  </p>
                  <p className="text-slate text-base font-normal font-['Manrope']">Categories</p>
                </div>
                <div className="mr-8">
                  <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">{project.meetings_count}</p>
                  <p className="text-slate text-base font-normal font-['Manrope']">Sessions</p>
                </div>
                {/* <div className='mr-8'>
                <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">{project.speakers.length}</p>
                <p className="text-slate text-base font-normal font-['Manrope']">Speakers</p>
              </div> */}
              <div className='mr-8'>
                <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">
                  {project.method === 'user_interviews' ? 'User Interviews' : project.method === 'user_testing' ? 'User Testing' : project.method === 'focus_group' ? 'Focus Group' : 'Mixed Methods'}
                </p>
                <p className="text-slate text-base font-normal font-['Manrope']">Method</p>
              </div>
              <div className='flex items-center'>
                <div className='flex justify-end'>
                  <button onClick={() => setOpenMenu(project.id)}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1167_506)">
                        <path
                            d="M3.44818 12.5C3.44818 13.6819 3.68097 14.8522 4.13327 15.9442C4.58556 17.0361 5.24849 18.0282 6.08422 18.864C6.91995 19.6997 7.9121 20.3626 9.00403 20.8149C10.096 21.2672 11.2663 21.5 12.4482 21.5C13.6301 21.5 14.8004 21.2672 15.8923 20.8149C16.9843 20.3626 17.9764 19.6997 18.8121 18.864C19.6479 18.0282 20.3108 17.0361 20.7631 15.9442C21.2154 14.8522 21.4482 13.6819 21.4482 12.5C21.4482 11.3181 21.2154 10.1478 20.7631 9.05585C20.3108 7.96392 19.6479 6.97177 18.8121 6.13604C17.9764 5.30031 16.9843 4.63738 15.8923 4.18508C14.8004 3.73279 13.6301 3.5 12.4482 3.5C11.2663 3.5 10.096 3.73279 9.00403 4.18508C7.9121 4.63738 6.91995 5.30031 6.08422 6.13604C5.24849 6.97177 4.58556 7.96392 4.13327 9.05585C3.68097 10.1478 3.44818 11.3181 3.44818 12.5Z" stroke="#62718D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M8.44818 12.5V12.51" stroke="#62718D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M12.4482 12.5V12.51" stroke="#62718D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M16.4482 12.5V12.51" stroke="#62718D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1167_506">
                      <rect width="24" height="24" fill="white" transform="translate(0.448181 0.5)"/>
                      </clipPath>
                      </defs>
                    </svg>
                  </button>
                  {openMenu === project.id && (
                    <EditDelete
                      onClose={() => setOpenMenu('')}
                      handleEdit={() => {
                        setOpenMenu('')
                        setOpenRenameDialog({
                          id: project.id,
                          name: project.name,
                          description: project.description,
                          method: project.method
                        })
                      }}
                      handleDelete={() => {
                        if (confirm('Are you sure you want to delete this project?')) {
                          handleDeleteProject(project.id)
                        }
                        setOpenMenu('')
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {!!Object.keys(openRenameDialog).length &&
          createPortal(
            <RenameProject
              projectId={openRenameDialog.id}
              projectName={openRenameDialog.name}
              projectDescription={openRenameDialog.description}
              projectMethod={openRenameDialog.method}
              handleClose={() => setOpenRenameDialog({})}
            />,
            document.body
          )}
      </div>
    </>
  )
}

Projects.propTypes = {
  limit: PropTypes.number,
}
