import React from 'react'
import ProjectExample from '../../../assets/project-page-example.png'
import { CheckMark } from './checkMark'
import { Testimonial } from '../../utils/testimonial'

export function Sidebar () {
  return (
        <div className='mt-14 flex flex-col items-center'>
            <img src={ProjectExample} alt="meeting-example" className="w-[376px] h-[218px] mx-auto mb-8"/>
            <div className="pr-6">
                {['Take notes alongside AI',
                  'Collaborate with your team on interviews',
                  'Export to any tool'].map((text, index) => <CheckMark text={text} key={index} />)}
            </div>
            <div className='mt-12'>
              <Testimonial />
            </div>
        </div>
  )
}
