import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import { HelpIcon } from '../../../icons/Help'
import { ProblemTooltip } from '../../tooltips/problemTooltip'

export function TimelineProblems({ duration }) {
  const { problems } = useContext(MeetingsContext)

  return (
    <div className="w-full flex items-center gap-[10px]">
      <div className="flex w-[120px] items-center gap-[5px]">
        <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-3 tracking-wide">
          Pain Points
        </p>
        <HelpIcon content={'Problems and Pain Points identified'} />
      </div>

      <div className="relative w-full border-b border-b-[#E4E4E4]">
        {problems.map((problem) => (
          <div key={problem.id}>
            <Tippy
              interactive={true}
              placement="top"
              maxWidth={350}
              content={
                <ProblemTooltip
                  id={problem.id}
                  content={problem.content}
                  referent={problem.referent}
                  participantId={problem.participant_id}
                  whoSaidIt={problem.who_said_it}
                  startTime={problem.start_time}
                />
              }
            >
              <div
                style={{ left: `${((problem.start_time * 100) / duration).toFixed(2)}%` }}
                className="absolute top-[-4px] w-[8.68px] h-[8.68px] rounded-full bg-[#FF4A8E] border border-[#DCAE1D]"
              ></div>
            </Tippy>
          </div>
        ))}
      </div>
    </div>
  )
}

TimelineProblems.propTypes = {
  duration: PropTypes.number,
}
