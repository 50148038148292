import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import { QATooltip } from '../../../meeting/tooltips/qaTooltip'
import { HelpIcon } from '../../../icons/Help'

export function TimelineQas({ duration }) {
  const { qas } = useContext(MeetingsContext)

  return (
    <div className="w-full flex items-center gap-[10px]">
      <div className="flex w-[120px] items-center gap-[5px]">
        <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-3 tracking-wide">
          Interview
        </p>
        <HelpIcon content={'Facilitator questions and interviewee’s answers.'} />
      </div>

      <div className="relative w-full border-b border-b-[#E4E4E4]">
        {qas.map((qa) => (
          <div key={qa.id}>
            <Tippy
              interactive={true}
              placement="top"
              maxWidth={350}
              content={
                <QATooltip
                  id={qa.id}
                  content={qa.content}
                  facilitatorQuestion={qa.facilitator_question}
                  participantId={qa.participant_id}
                  whoSaidIt={qa.who_said_it}
                  startTime={qa.start_time}
                />
              }
            >
              <div
                style={{ left: `${((qa.start_time * 100) / duration).toFixed(2)}%` }}
                className="absolute top-[-4px] w-[8.68px] h-[8.68px] rounded-full bg-[#40424A] border border-black"
              ></div>
            </Tippy>
          </div>
        ))}
      </div>
    </div>
  )
}

TimelineQas.propTypes = {
  duration: PropTypes.number,
}
