import React, { useContext } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import { StarsFilter } from '../filters/stars'
import { MeetingsFilter } from '../filters/meetings'
import { CategoriesFilter } from '../filters/categories'
import { Notes } from '../../needs/notes'
import { DeleteNotesDialog } from '../../needs/deleteNotesDialog'
import { createPortal } from 'react-dom'

export function Needs () {
  const { filteredNotes, needsFilters, setNeedsFilters, showDeleteNotesDialog, setShowDeleteNotesDialog, handleDeleteNotes, selectedNotes } = useContext(ProjectContext)

  return (
    <>
      <p className="mb-4 w-full pb-[10px] text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px] border-b-2 border-grey-200">Needs</p>

      <div className="w-full h-12 mt-[20px] mb-[6px] bg-white rounded-[10px] flex items-center" style={{ boxShadow: '0px 0px 16px 8px rgba(0, 0, 0, 0.15)' }}>
        <StarsFilter filters={needsFilters} setFilters={setNeedsFilters} label={'Needs'} />
        <CategoriesFilter filters={needsFilters} setFilters={setNeedsFilters}  />
        <MeetingsFilter filters={needsFilters} setFilters={setNeedsFilters} />
        {/* <TagsFilter /> */}
      </div>

      <div className='w-full flex flex-wrap ml-[44px] relative'>
        <Notes notes={filteredNotes} />
      </div>
      
      {showDeleteNotesDialog &&
        createPortal(
          <DeleteNotesDialog
            handleDeleteNotes={handleDeleteNotes}
            notesCount={selectedNotes.length}
            handleClose={() => setShowDeleteNotesDialog(false)}
          />,
          document.body
        )}
    </>
  )
}
