import React, { useContext } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'

export function GeneralNotes () {
  const { setSelectedMeeting, setScrollToMeeting, setGroupNotesBy } = useContext(ProjectContext)

  return (
    <div className="cursor-pointer border-b-2 border-b-grey-200">
      <div onClick={() => {
        setGroupNotesBy('meeting')
        setScrollToMeeting(null)
        setSelectedMeeting(null)
      }}>
        <div className='w-full py-5 flex justify-between items-center'>
          <p className="text-zinc text-base font-semibold font-['Manrope'] leading-tight">
            General Notes
          </p>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="10.5" transform="rotate(-90 14 14)" fill="#EBEBEB"/>
            <path d="M12.834 10.5L16.334 14L12.834 17.5" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  )
}
