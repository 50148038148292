import React from 'react'

export const TrashIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1982_5440)">
        <path
          d="M4.625 7H20.625"
          stroke="#D40814"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.625 11V17"
          stroke="#D40814"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.625 11V17"
          stroke="#D40814"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.625 7L6.625 19C6.625 19.5304 6.83571 20.0391 7.21079 20.4142C7.58586 20.7893 8.09457 21 8.625 21H16.625C17.1554 21 17.6641 20.7893 18.0392 20.4142C18.4143 20.0391 18.625 19.5304 18.625 19L19.625 7"
          stroke="#D40814"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.625 7V4C9.625 3.73478 9.73036 3.48043 9.91789 3.29289C10.1054 3.10536 10.3598 3 10.625 3H14.625C14.8902 3 15.1446 3.10536 15.3321 3.29289C15.5196 3.48043 15.625 3.73478 15.625 4V7"
          stroke="#D40814"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1982_5440">
          <rect width="24" height="24" fill="white" transform="translate(0.625)" />
        </clipPath>
      </defs>
    </svg>
  )
}
