import React, { useContext } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import { StarsFilter } from '../filters/stars'
// import { TagsFilter } from './filters/tags'
import { MeetingsFilter } from '../filters/meetings'
import { Feedbacks } from '../../feedbacks/feedbacks'

export function OverviewFeedbacks() {
  const { filteredFeedbacksOverview, feedbacksOverviewFilters, setFeedbacksOverviewFilters } = useContext(ProjectContext)

  return (
    <div className="w-full">
      <div className="pb-[10px] border-b-2 border-grey-200 mb-[36px]">
        <p className="w-full text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Feedback</p>
      </div>

      <div
        className="h-12 bg-white rounded-[10px] flex item-center mb-[36px]"
        style={{ boxShadow: '0px 0px 16px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <StarsFilter filters={feedbacksOverviewFilters} setFilters={setFeedbacksOverviewFilters} label={'Feedback'} />
        <MeetingsFilter filters={feedbacksOverviewFilters} setFilters={setFeedbacksOverviewFilters} />
        {/* <TagsFilter /> */}
      </div>

      <div className="w-full flex flex-wrap ml-[22px] relative"> 
        <Feedbacks feedbacks={filteredFeedbacksOverview} overviewFlag={true} />
        {/* {filteredFeedbacks.map(feedback => <Feedback key={feedback.id} feedback={feedback} />)} */}
      </div>
    </div>
  )
}
