import React from 'react'

const TranscriptsIcon = ({ checked }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2272_3954)">
        <path
          d="M14.1667 16.6673H5C4.33696 16.6673 3.70107 16.4039 3.23223 15.9351C2.76339 15.4662 2.5 14.8304 2.5 14.1673C2.5 13.5043 2.76339 12.8684 3.23223 12.3996C3.70107 11.9307 4.33696 11.6673 5 11.6673H14.1667C13.5036 11.6673 12.8677 11.9307 12.3989 12.3996C11.9301 12.8684 11.6667 13.5043 11.6667 14.1673C11.6667 14.8304 11.9301 15.4662 12.3989 15.9351C12.8677 16.4039 13.5036 16.6673 14.1667 16.6673ZM14.1667 16.6673H15C15.663 16.6673 16.2989 16.4039 16.7678 15.9351C17.2366 15.4662 17.5 14.8304 17.5 14.1673V5.00065C17.5 4.55862 17.3244 4.1347 17.0118 3.82214C16.6993 3.50958 16.2754 3.33398 15.8333 3.33398H7.5C7.05797 3.33398 6.63405 3.50958 6.32149 3.82214C6.00893 4.1347 5.83333 4.55862 5.83333 5.00065V11.6673"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2272_3954">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TranscriptsIcon
