import React, { useContext, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { getMeetingProvider } from '../../../utils'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import { CreateProject } from './createProject'
import { ChooseExistingProject } from './chooseExistingProject'
import { ImportFiles } from './importFiles'
import toast from 'react-hot-toast'
import { DashboardContext } from '../../../context/DashboardContext'

export function ProjectLayout ({ link, importFile, handleClose }) {
  const navigate = useNavigate()
  const { setProjects } = useContext(DashboardContext)
  const [isNewProject, setIsNewProject] = useState(!link && !importFile)
  const [showImportDialog, setShowImportDialog] = useState(false)
  const [projectId, setProjectId] = useState('')
  const [projectName, setProjectName] = useState('')
  const createProjectClicked = useRef(false)
  const customFetch = useCustomFetch()

  async function handleCreateProject (projectName, projectDescription, method) {
    try {
      if (!projectName) return toast.error('Project name is required.')
      if (!projectDescription) return toast.error('Project description is required.')
      if (!method) return toast.error('Method is required.')
      if (createProjectClicked.current) return
      createProjectClicked.current = true

      const data = await customFetch('/createProject', 'POST', { projectName, projectDescription, method })
      if (getMeetingProvider(link) !== '') {
        await customFetch('/createBot', 'POST', { link, projectId: data.id, projectName, projectDescription })
        alert('Great! Go accept the bot into the meeting.')
      }
      if (importFile) {
        setShowImportDialog(true)
        setProjectId(data.id)
        setProjectName(projectName)
      } else {
        navigate(`/project/${data.id}`)
      }
      setProjects()
    } catch (error) {
      console.error('Error sending bot:', error)
    } finally {
      await new Promise(resolve => setTimeout(resolve, 6000))
      createProjectClicked.current = false
    }
  }

  async function handleChooseExistingProject (project) {
    try {
      if (getMeetingProvider(link) !== '') {
        await customFetch('/createBot', 'POST', { link, projectId: project.id, projectName: project.name, projectDescription: project.description })
        alert('Great! Go accept the bot into the meeting.')
      }
      if (importFile) {
        setShowImportDialog(true)
        setProjectId(project.id)
        setProjectName(project.name)
      } else {
        navigate(`/project/${project.id}`)
      }
    } catch (error) {
      console.error('Error sending bot:', error)
    }
  }

  if (showImportDialog) {
    return (
      <ImportFiles projectId={projectId} projectName={projectName} handleClose={handleClose} />
    )
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black z-20" onClick={() => handleClose(true)}>
      <div className="absolute left-1/4 right-1/4 bg-white p-[28px] rounded-xl" style={{ top: '10%' }} onClick={(e) => e.stopPropagation()}>
        <div className='mb-[29px] flex items-center justify-between'>
          <p className="text-zinc text-[26px] font-semibold font-['Manrope']">{isNewProject ? 'Add New Project' : 'Add to Existing Project'}</p>
          <div className='flex items-center'>
            {(link || importFile) && (
              <button className="mr-[18px] px-4 py-2.5 rounded-[100px] shadow-inner border border-pink justify-center items-center gap-3 inline-flex" onClick={() => setIsNewProject(!isNewProject)}>
                <p className="text-pink text-base font-semibold font-['Manrope'] leading-tight">{isNewProject ? 'Add to Existing Project' : 'Add to New Project'}</p>
              </button>
            )}
            <button onClick={() => handleClose(true)}>
              <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.8577 12L13.7158 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.7158 12L26.8577 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
        {isNewProject
          ? <CreateProject handleCreateProject={handleCreateProject} handleClose={handleClose} />
          : <ChooseExistingProject handleChooseExistingProject={handleChooseExistingProject} />
        }
      </div>
    </div>
  )
}

ProjectLayout.propTypes = {
  link: PropTypes.string,
  importFile: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
}
