import React, { useContext } from 'react'
import { ProjectContext } from '../../context/ProjectContext'
import OverviewIcon from '../icons/Overview'
import MeetingsIcon from '../icons/Meetings'
import NeedsIcon from '../icons/Needs'
import ProblemsIcon from '../icons/Problems'
import QASIcon from '../icons/QAS'
import FeedbackIcon from '../icons/Feedback'
import TranscriptsIcon from '../icons/Transcripts'
import QuotesIcon from '../icons/Quotes'

function MenuItem({ label, icon: Icon, active, count, onClick, width }) {
  return (
    <button
      className={`bg-white h-12 border items-center ${active ? 'border-pink' : 'border-grey-200'}`}
      style={{ flexBasis: `${width}%` }}
      onClick={onClick}
    >
      <div className="flex gap-4 items-center justify-center">
        <Icon checked={active} />
        <div className="text-black text-base font-bold font-['Manrope'] leading-tight">{label}</div>
        {active && count !== undefined && (
          <div className="p-2 rounded-[30px] justify-center items-center gap-1 flex bg-slate">
            <p className="text-white text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
              {count}
            </p>
          </div>
        )}
      </div>
    </button>
  )
}

export function Menu() {
  const {
    setScrollToCategory,
    setScrollToMeeting,
    notes,
    meetings,
    feedbacks,
    qas,
    overview,
    menu,
    setMenu,
    quotes,
    problems,
  } = useContext(ProjectContext)

  const conditions = [
    !!Object.keys(overview).length,
    !!overview.background,
    !!feedbacks.length,
    !!overview.problems?.length,
    !!overview.qas?.length,
    !!quotes.length,
  ]

  const menuItems = [
    {
      label: 'Overview',
      icon: OverviewIcon,
      active: menu === 'overview',
      condition: !!meetings.length && conditions.filter(Boolean).length >= 5,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('overview')
      },
    },
    {
      label: 'Meetings',
      icon: MeetingsIcon,
      active: menu === 'meetings',
      count: meetings.length,
      condition: !!meetings.length,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('meetings')
      },
    },
    {
      label: 'Interview',
      icon: QASIcon,
      active: menu === 'qas',
      count: qas.length,
      condition: !!qas.length,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('qas')
      },
    },
    {
      label: 'Quotes',
      icon: QuotesIcon,
      active: menu === 'quotes',
      count: quotes.length,
      condition: !!quotes.length,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('quotes')
      },
    },
    {
      label: 'Needs',
      icon: NeedsIcon,
      active: menu === 'notes',
      count: notes.length,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('notes')
      },
    },
    {
      label: 'Pain Points',
      icon: ProblemsIcon,
      active: menu === 'problems',
      count: problems.length,
      condition: !!problems.length,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('problems')
      },
    },
    {
      label: 'Feedback',
      icon: FeedbackIcon,
      active: menu === 'feedbacks',
      count: feedbacks.length,
      condition: !!feedbacks.length,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('feedbacks')
      },
    },
    {
      label: 'Transcripts',
      icon: TranscriptsIcon,
      active: menu === 'transcripts',
      count: meetings.length,
      onClick: () => {
        setScrollToCategory('')
        setScrollToMeeting('')
        setMenu('transcripts')
      },
    },
  ]

  // Filter items that meet the condition and calculate width
  const visibleMenuItems = menuItems.filter(({ condition }) => condition !== false)
  const itemWidth = 100 / visibleMenuItems.length

  return (
    <div>
      <div className="flex items-center shadow w-full">
        {visibleMenuItems.map(({ label, icon, active, count, onClick }) => (
          <MenuItem
            key={label}
            label={label}
            icon={icon}
            active={active}
            count={count}
            onClick={onClick}
            width={itemWidth}
          />
        ))}
      </div>
    </div>
  )
}
