import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import { FeedbackTooltip } from '../../tooltips/feedbackTooltip'
import { HelpIcon } from '../../../icons/Help'

export function TimelineFeedbacks({ duration }) {
  const { feedbacks } = useContext(MeetingsContext)

  return (
    <div className="w-full flex items-center gap-[10px]">
      <div className="flex w-[120px] items-center gap-[5px]">
        <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-3 tracking-wide">
          Feedback
        </p>
        <HelpIcon content={'Product feedback: Positive, negative, and neutral.'} />
      </div>

      <div className="relative w-full border-b border-b-[#E4E4E4]">
        {feedbacks.map((feedback) => (
          <div key={feedback.id}>
            <Tippy
              interactive={true}
              placement="top"
              maxWidth={350}
              content={
                <FeedbackTooltip
                  id={feedback.id}
                  content={feedback.content}
                  participantId={feedback.participant_id}
                  whoSaidIt={feedback.who_said_it}
                  startTime={feedback.start_time}
                  sentimentAnalysis={feedback.sentiment_analysis}
                />
              }
            >
              <div
                style={{ left: `${((feedback.start_time * 100) / duration).toFixed(2)}%` }}
                className={`absolute top-[-4px] w-[8.68px] h-[8.68px] rounded-full border ${feedback.sentiment_analysis === 'positive' ? 'bg-[#1FB912] border-[#259A1A]' : feedback.sentiment_analysis === 'negative' ? 'bg-[#D40814] border-[#A5111A]' : 'bg-[#FFF53C] border-[#DAD133]'}`}
              ></div>
            </Tippy>
          </div>
        ))}
      </div>
    </div>
  )
}

TimelineFeedbacks.propTypes = {
  duration: PropTypes.number,
}
