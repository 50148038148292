import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../../context/AuthContext'
import { useCustomFetch } from '../../../../hooks/useCustomFetch'
import { Interview } from './interview'
import { Event } from './event'
import { useNavigate } from 'react-router-dom'
import { ProjectContext } from '../../../../context/ProjectContext'

export function Interviews () {
  const [events, setEvents] = useState([])
  const { user } = useContext(AuthContext)
  const { project, sessions } = useContext(ProjectContext)
  const navigate = useNavigate()
  const customFetch = useCustomFetch()

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    const getCalendarEvents = async () => {
      const data = await customFetch('/getCalendarEvents', 'GET', null)
      if (data.error) return
      if (Array.isArray(data.events) && data.events.length) {
        setEvents(data.events.filter(event => event.projectId === project.id))
      }
    }

    getCalendarEvents()
  }, [user])

  return (
      <div className='w-full'>
        <div className='mb-[28px] pb-[10px] flex justify-between items-center border-b-2 border-grey-200'>
          <p className="w-full text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Interviews</p>
        </div>

        {events.length > 0 && (
          <>
          <p className="text-[#62718D] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Upcoming</p>
          <div className='my-[14px] flex items-center flex-wrap gap-4'>
            {events.map(event => <Event key={event.id} event={event} />)}
          </div>
          </>
        )}

        <p className="text-[#62718D] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">Recorded</p>
        <div className='my-[14px] flex items-center flex-wrap gap-4'>
          {sessions.map(meet => (
            <button key={meet.id} onClick={() => navigate(`/dashboard/meetings/${meet.id}`)} className="w-[365px] h-[79.37px] p-4 bg-white rounded-lg shadow border border-grey-200 justify-start items-center gap-4 inline-flex">
              <Interview meet={meet} />
            </button>
          ))}
        </div>
      </div>
  )
}
