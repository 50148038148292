/* eslint-disable react/no-unknown-property */
import React, { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { ProjectLayout } from './dialog/projectLayout'
import Meetings from '../../assets/welcomedashboard/apps/meetings.png'
import Calendars from '../../assets/welcomedashboard/apps/calendars.png'
import Upload from '../../assets/welcomedashboard/apps/upload.png'
import Calendar from '../../assets/welcomedashboard/apps/calendar.png'
import Microsoft from '../../assets/welcomedashboard/apps/microsoft.png'
import File from '../../assets/welcomedashboard/apps/file.png'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { AuthContext } from '../../context/AuthContext'
import { ImportFiles } from './dialog/importFiles'
import '../project/project.css'
import { VideoCameraIcon } from '../icons/VideoCamera'
import { HelpIcon } from '../icons/Help'
import clsx from 'clsx'
import { DashboardContext } from '../../context/DashboardContext'
import { useNavigate } from 'react-router-dom'

function ScheduleInterview(props) {
  return (
    <div className="p-5">
      <h2 className="mb-3 text-[#40424A] text-[18px]">Schedule Interview</h2>
      <p className="my-4 text-[#62718D] font-light">
        Add any meeting scheduled to this project by inviting Usermuse using this unique email address.
      </p>
      <div className="flex flex-wrap items-center mx-2 my-5">
        <p className="mr-1 text-xs font-semibold font-['Manrope'] mt-2">INVITE EMAIL</p>
        <HelpIcon
          className="mt-2"
          maxWidth={300}
          renderProps={(icon) => <div className="ml-1 mr-6">{icon}</div>}
          content={'Use this unique email to invite Usermuse to additional meetings for this project.'}
        />
        <button className="h-[27px] bg-white rounded-[50px] flex items-center p-4 mt-2" onClick={props.onClick}>
          <p className="text-zinc text-[13px] font-medium font-['Manrope'] mr-2">
            {props.project ? `invite+${props.project.stem || props.project.id}@usermuse.co` : 'invite@usermuse.co'}
          </p>
          <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 15 15" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
              fill="#FF4A8E"
            />
          </svg>
        </button>
      </div>
      <button className="bg-pink w-full text-white px-4 py-2 rounded-lg flex justify-center" onClick={props.onClick1}>
        {!props.user.integrations?.googleCalendar && !props.user.integrations?.microsoftCalendar ?
          'Connect Calendar'
        : (
          <>
          <img
            src={props.user.integrations?.googleCalendar ? Calendar : Microsoft}
            className="w-[20px] me-3"
            alt="Calendar"
          />{` Schedule on ${props.user.integrations?.googleCalendar ? 'Google Calendar' : 'Microsoft Calendar'}`}
          </>
        )}
      </button>
    </div>
  )
}

ScheduleInterview.propTypes = {
  onClick: PropTypes.func,
  project: PropTypes.any,
  onClick1: PropTypes.func,
  user: PropTypes.any,
}

function JoinLiveMeeting(props) {
  return (
    <div className="p-5">
      <h2 className="mb-3 text-[#40424A] text-[18px]">Join Live Meeting</h2>
      <p className="my-4 text-[#62718D] font-light">
        To start transcribing and analyzing a live meeting, just paste the meeting link here:
      </p>
      <div className="flex w-full h-full my-5">
        <div className="relative grow">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <VideoCameraIcon />
          </div>
          <input
            type="url"
            id="default-search"
            className="h-full w-full ps-10 py-2 text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border border-grey-200"
            placeholder="Paste meeting link"
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            required
          />
        </div>
      </div>
      {props.showDialog &&
        createPortal(
          <ProjectLayout link={props.link} importFile={props.importFile} handleClose={props.handleClose} />,
          document.body
        )}
      <button onClick={props.onClick} className="bg-pink w-full text-white px-4 py-2 rounded-lg">
        Join Meeting
      </button>
    </div>
  )
}

JoinLiveMeeting.propTypes = {
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  showDialog: PropTypes.bool,
  link: PropTypes.string,
  importFile: PropTypes.bool,
  handleClose: PropTypes.func,
  onClick: PropTypes.func,
}

function UploadRecordings(props) {
  return (
    <div className="p-5">
      <h2 className="mb-3 text-[#40424A] text-[18px]">Upload Recordings</h2>
      <p className="my-4 text-[#62718D] font-light">
        Upload your user session recordings as Audio or Video files to be processed.
        <br /> <br />
        Upload one file at a time up to 8GB. Acceptable file types:{' '}
        <span className="font-semibold">MP3, MP4, MP2, AAC, WAV, FLAC, PCM, M4A, Ogg, Opus, WebM.</span>
      </p>
      <button onClick={props.onClick} className="bg-pink w-full text-white px-4 py-2 rounded-lg flex justify-center">
        <img src={File} className="w-[20px] me-3" alt="" /> Upload Recordings
      </button>

      {props.project &&
        props.showImportDialog &&
        createPortal(
          <ImportFiles
            projectId={props?.project?.id}
            projectName={props?.project?.name}
            handleClose={props.handleClose}
          />,
          document.body
        )}
    </div>
  )
}

UploadRecordings.propTypes = {
  onClick: PropTypes.func,
  project: PropTypes.any,
  showImportDialog: PropTypes.bool,
  handleClose: PropTypes.func,
}

export function DynamicItems({ isFullWidth = false, project = null }) {
  const { user } = useContext(AuthContext)
  const { shouldFireZoomToast } = useContext(DashboardContext)
  const [showDialog, setShowDialog] = useState(false)
  const [importFile, setImportFile] = useState(false)
  const [link, setLink] = useState('')
  const [showImportDialog, setShowImportDialog] = useState(false)
  const navigate = useNavigate()

  async function copyToClipboard() {
    try {
      project !== null
        ? await navigator.clipboard.writeText(`invite+${project.stem || project.id}@usermuse.co`)
        : await navigator.clipboard.writeText(`invite@usermuse.co`)
      toast.success('Copied!')
    } catch {
      toast.error('Failed to copy!')
    }
  }

  const handleDialog = () => {
    if (project !== null) {
      setShowImportDialog(true)
    } else {
      setImportFile(true)
      setShowDialog(true)
    }
  }

  const generateCalendarLink = () => {
    if (user.integrations?.googleCalendar) {
      const baseUrl = 'https://calendar.google.com/calendar/render?action=TEMPLATE'
      const title = encodeURIComponent('New User Interview')
      const guests = encodeURIComponent(
        project !== null ? `invite+${project.stem || project.id}@usermuse.co` : 'invite@usermuse.co'
      )
      const eventLink = `${baseUrl}&text=${title}&add=${guests}`
      window.open(eventLink, '_blank')
    } else if (user.integrations?.microsoftCalendar) {
      const baseUrl = 'https://outlook.live.com/calendar/0/deeplink/compose'
      const title = encodeURIComponent('New User Interview')
      const guests = encodeURIComponent(
        project !== null ? `invite+${project.stem || project.id}@usermuse.co` : 'invite@usermuse.co'
      )
      const eventLink = `${baseUrl}?subject=${title}&to=${guests}`
      window.open(eventLink, '_blank')
    } else {
      navigate('/dashboard/integrations')
    }
  }

  return (
    <>
      <div
        className={clsx(
          'justify-center items-center grid py-8 pr-8 -ml-4',
          isFullWidth ? 'grid-cols-1' : 'gap-3 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-10'
        )}
      >
        <div className={clsx('h-full', isFullWidth ? 'dynamic-item-padding' : 'dynamic-item-aux-padding')}>
          <div className="rounded-xl h-full shadow-lg" style={{ backgroundColor: '#f8ffc8' }}>
            <div
              className={clsx(
                'rounded-t-xl flex justify-center items-center overflow-hidden bg-[#e2e8b7]',
                !isFullWidth && 'p-5'
              )}
            >
              <img
                src={Meetings}
                alt="meetings"
                className={clsx(isFullWidth ? 'w-[150px]' : 'w-[140px]')}
                style={{ transform: isFullWidth ? 'translateY(12px)' : null }}
              />
            </div>
            <JoinLiveMeeting
              onChange={(e) => setLink(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (link) {
                    const fireToast = shouldFireZoomToast(link)
                    if (!fireToast) setShowDialog(true)
                  } else {
                    alert('Please enter a link')
                  }
                }
              }}
              showDialog={showDialog}
              link={link}
              importFile={importFile}
              handleClose={() => {
                setImportFile(false)
                setShowDialog(false)
              }}
              onClick={() => (link ? setShowDialog(true) : alert('Please enter a link'))}
            />
          </div>
        </div>
        <div className={clsx('h-full', isFullWidth ? 'dynamic-item-padding' : 'dynamic-item-aux-padding')}>
          <div className="rounded-xl h-full shadow-lg" style={{ backgroundColor: '#fee4d9' }}>
            <div
              className={clsx(
                'rounded-t-xl flex justify-center items-center overflow-hidden bg-[#fed8c8]',
                !isFullWidth && 'p-5'
              )}
            >
              <img
                src={Calendars}
                alt="meetings"
                className={clsx(isFullWidth ? 'w-[180px]' : 'w-[185px]')}
                style={{ transform: isFullWidth ? 'translateY(12px)' : null }}
              />
            </div>
            <ScheduleInterview
              onClick={copyToClipboard}
              project={project}
              onClick1={generateCalendarLink}
              user={user}
            />
          </div>
        </div>
        <div className="h-full" style={{ padding: isFullWidth ? '15px 15px 15px 0px' : '0px' }}>
          <div className="rounded-xl h-full shadow-lg" style={{ backgroundColor: '#cdf2ff' }}>
            <div
              className={clsx('rounded-t-xl flex justify-center items-center overflow-hidden', !isFullWidth && 'p-5')}
              style={{ backgroundColor: '#abe6fc' }}
            >
              <img
                src={Upload}
                alt="meetings"
                className={clsx(isFullWidth ? 'w-[65px]' : 'w-[60px]')}
                style={{ transform: isFullWidth ? 'translateY(15px)' : null }}
              />
            </div>
            <UploadRecordings
              onClick={() => {
                handleDialog()
              }}
              project={project}
              showImportDialog={showImportDialog}
              handleClose={() => {
                setShowImportDialog(false)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

DynamicItems.propTypes = {
  isFullWidth: PropTypes.bool,
  project: PropTypes.object,
}
