import React, { useContext } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { AddFile } from './addFile'
import { AddMeeting } from './addMeeting'
import { Session } from './session'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import { GeneralNotes } from './generalNotes'

export function Sessions() {
  const { meetings, setMeetings, project, notes } = useContext(ProjectContext)
  const customFetch = useCustomFetch()

  async function handleCreateEmptyMeet() {
    const response = await customFetch('/createEmptyMeeting', 'POST', { projectId: project.id })
    setMeetings((prevMeetings) =>
      [response.meeting, ...prevMeetings].sort((a, b) => {
        let timeA = a.created_at
        let timeB = b.created_at
        if (a.start) {
          timeA = new Date(a.start).getTime()
        }
        if (b.start) {
          timeB = new Date(b.start).getTime()
        }
        return timeA < timeB ? 1 : -1
      })
    )
  }

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <p className="text-slate text-xs font-extrabold font-['Manrope'] tracking-wide">SESSIONS</p>
        <div className="flex items-center">
          <button
            onClick={handleCreateEmptyMeet}
            className="mr-2 px-2.5 bg-white rounded-[30px] shadow border border-grey-200 flex justify-center items-center gap-0.5"
          >
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2218_5406)">
                <path
                  d="M7 3.41602V11.5827"
                  stroke="#FF4A8E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.91602 7.5H11.0827"
                  stroke="#FF4A8E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2218_5406">
                  <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
            <p className="text-zinc-700 text-xs font-extrabold font-['Manrope'] leading-[13.20px] tracking-wide">ADD</p>
          </button>
          <AddFile />
        </div>
      </div>
      {notes.findIndex((note) => note.meeting_id == null) !== -1 && <GeneralNotes />}
      {meetings
        .filter((notNull) => notNull?.id)
        .map((meeting) => (
          <Session meeting={meeting} key={meeting.id} />
        ))}
      <div className="mt-4">
        <AddMeeting />
      </div>
    </>
  )
}
