import clsx from 'clsx'
import React from 'react'

export function IconButton({ onClick, icon: Icon, checked }) {
  return (
    <button
      className={clsx(
        'grow w-[64.75px] h-[38px] p-[7px] bg-white rounded shadow',
        'border border-[#86858F] flex-col items-center gap-2.5 inline-flex',
      )}
      onClick={onClick}
    >
      <Icon checked={checked} />
    </button>
  )
}