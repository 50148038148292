/* eslint-disable react/no-unknown-property */
import React, { useContext, useMemo, useState } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import toast from 'react-hot-toast'
import { Category } from './category'
import Tippy from '@tippyjs/react'
import cnt from '../../../constants'
// import 'tippy.js/dist/tippy.css'

export function Categories () {
  const {
    categories,
    setCategories,
    notes,
    setNotes,
    project,
    setGroupNotesBy,
    setCategoryCreated,
    workspace
  } = useContext(ProjectContext)
  const customFetch = useCustomFetch()
  const [isLoadingCategorize, setIsLoadingCategorize] = useState(false)
  const allCategories = [{ id: 'allNotes', content: 'All Notes', amount: categories.reduce((acc, cat) => acc + cat.amount, 0) }, ...categories]
  const shouldDisable = useMemo(() => {
    return notes.length < 30 || (workspace.status !== 'active' && workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed)
  }, [notes, workspace.status, workspace.additional_amount, workspace.sub_hours_left])

  async function categorize () {
    setIsLoadingCategorize(true)
    const response = await customFetch('/createNotesCategories', 'POST', { projectId: project.id })
    if (response.error) {
      toast.error('Whoops! Something went wrong. Please try again.')
      setGroupNotesBy('meeting')
      setIsLoadingCategorize(false)
      return
    }
    setNotes(response.notes)

    for (let i = 0; i < response.categories.length; i++) {
      const amount = response.notes.filter(note => note.category_id === response.categories[i].id).length
      response.categories[i].amount = amount
    }

    // Uncategorized should go first, Misc should go last, and the others ordered by amount
    response.categories.sort((a, b) => {
      if (a.content === 'Uncategorized') {
        return -1
      } else if (b.content === 'Uncategorized') {
        return 1
      } else if (a.content === 'Misc') {
        return 1
      } else if (b.content === 'Misc') {
        return -1
      } else if (a.amount > b.amount) {
        return -1
      } else if (a.amount === b.amount) {
        return (a.content > b.content) ? 1 : -1
      } else {
        return 1
      }
    })

    setCategories(response.categories)
    toast.success('Categories applied!')
    setGroupNotesBy('category')
    setIsLoadingCategorize(false)
  }

  async function createCategory () {
    const response = await customFetch('/createCategory', 'POST', { projectId: project.id })
    if (response.error) {
      toast.error('Whoops! Something went wrong. Please try again.')
      return
    }
    const newCategories = JSON.parse(JSON.stringify(categories)).concat({ ...response.category, amount: 0 })
    // Uncategorized should go first, Misc should go last, and the others ordered by amount
    newCategories.sort((a, b) => {
      if (a.content === 'Uncategorized') {
        return -1
      } else if (b.content === 'Uncategorized') {
        return 1
      } else if (a.content === 'Misc') {
        return 1
      } else if (b.content === 'Misc') {
        return -1
      } else if (a.amount > b.amount) {
        return -1
      } else if (a.amount === b.amount) {
        return (a.content > b.content) ? 1 : -1
      } else {
        return 1
      }
    })
    setCategories(newCategories)
    setCategoryCreated(response.category.id)
  }

  return (
    <>
      <div className='flex items-center justify-between mb-4'>
        <p className="text-slate text-xs font-extrabold font-['Manrope'] tracking-wide">CATEGORIES</p>
        <div className='flex items-center'>
          <button className="mr-2 px-2.5 bg-white rounded-[30px] shadow border border-grey-200 flex justify-center items-center gap-0.5" onClick={createCategory}>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2218_5406)">
              <path d="M7 3.41602V11.5827" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M2.91602 7.5H11.0827" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_2218_5406">
              <rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
              </defs>
            </svg>
            <p className="text-zinc-700 text-xs font-extrabold font-['Manrope'] leading-[13.20px] tracking-wide">ADD</p>
          </button>
          <Tippy disabled={workspace.status === 'active' || workspace.additional_amount + workspace.sub_hours_left > cnt.min_time_needed} maxWidth={300} content='This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features.'>
          <Tippy disabled={notes.length >= 30} maxWidth={300} content='Usermuse needs at least 30 notes before it can suggest categories.'>
            <div>
            <button disabled={shouldDisable} onClick={categorize} className={`px-2.5 bg-white rounded-[30px] shadow border border-grey-200 flex justify-center items-center gap-1 ${shouldDisable && 'opacity-50'}`}>
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 13.4242L13 3.54922L11 1.57422L1 11.4492L3 13.4242Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M4.99935 1.57422C4.99935 1.92342 5.13983 2.25832 5.38987 2.50524C5.63992 2.75217 5.97906 2.89089 6.33268 2.89089C5.97906 2.89089 5.63992 3.02961 5.38987 3.27653C5.13983 3.52345 4.99935 3.85835 4.99935 4.20755C4.99935 3.85835 4.85887 3.52345 4.60882 3.27653C4.35878 3.02961 4.01964 2.89089 3.66602 2.89089C4.01964 2.89089 4.35878 2.75217 4.60882 2.50524C4.85887 2.25832 4.99935 1.92342 4.99935 1.57422Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M11.6673 8.15625C11.6673 8.50545 11.8078 8.84035 12.0578 9.08727C12.3079 9.3342 12.647 9.47292 13.0007 9.47292C12.647 9.47292 12.3079 9.61164 12.0578 9.85856C11.8078 10.1055 11.6673 10.4404 11.6673 10.7896C11.6673 10.4404 11.5268 10.1055 11.2768 9.85856C11.0267 9.61164 10.6876 9.47292 10.334 9.47292C10.6876 9.47292 11.0267 9.3342 11.2768 9.08727C11.5268 8.84035 11.6673 8.50545 11.6673 8.15625Z" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9 3.54883L11 5.52383" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="text-zinc-700 text-xs font-extrabold font-['Manrope'] leading-[13.20px] tracking-wide">SUGGEST</p>
            </button>
            </div>
          </Tippy>
          </Tippy>
          {isLoadingCategorize && (<svg className='w-8 h-8' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>)}
        </div>
      </div>
      {allCategories.map((cat) => (cat.content !== 'Uncategorized' || cat.amount !== 0) && (
        <Category cat={cat} key={cat.id} />
      ))}
    </>
  )
}
