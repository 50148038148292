import React from 'react'
import PropTypes from 'prop-types'
import { ProjectFilter } from './projectFilter'
import { ValidationFilter } from './validationFilter'

export function MeetingsFilters ({ projects, projectIndex, setProjectIndex, validation, setValidation, projectFilterEnabled }) {
  return (
        <div className='flex items-center'>
          {projectFilterEnabled ? <ProjectFilter projects={projects} projectIndex={projectIndex} setProjectIndex={setProjectIndex} /> : null}
          <ValidationFilter validation={validation} setValidation={setValidation} />
        </div>
  )
}

MeetingsFilters.propTypes = {
  projects: PropTypes.array,
  projectIndex: PropTypes.number,
  setProjectIndex: PropTypes.func,
  validation: PropTypes.string,
  setValidation: PropTypes.func,
  projectFilterEnabled: PropTypes.bool
}
