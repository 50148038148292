import React from 'react'

export const CloseIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 12L12 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 12L24 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
