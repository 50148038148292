import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import { QuoteTooltip } from '../../tooltips/quoteTooltip'
import { HelpIcon } from '../../../icons/Help'

export function TimelineQuotes({ duration }) {
  const { quotes } = useContext(MeetingsContext)

  return (
    <div className="w-full flex items-center gap-[10px]">
      <div className="flex w-[120px] items-center gap-[5px]">
        <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-3 tracking-wide">
          Quotes
        </p>
        <HelpIcon content={'Insightful statements from the interviewees.'} />
      </div>

      <div className="relative w-full border-b border-b-[#E4E4E4]">
        {quotes.map((quote) => (
          <div key={quote.id}>
            <Tippy
              interactive={true}
              placement="top"
              maxWidth={350}
              content={
                <QuoteTooltip
                  id={quote.id}
                  originalTranscriptSegment={quote.original_transcript_segment}
                  participantId={quote.participant_id}
                  whoSaidIt={quote.who_said_it}
                  startTime={quote.start_time}
                />
              }
            >
              <div
                style={{ left: `${((quote.start_time * 100) / duration).toFixed(2)}%` }}
                className="absolute top-[-4px] w-[8.68px] h-[8.68px] rounded-full bg-[#34AED7] border border-[#2C8FB1]"
              ></div>
            </Tippy>
          </div>
        ))}
      </div>
    </div>
  )
}

TimelineQuotes.propTypes = {
  duration: PropTypes.number,
}
