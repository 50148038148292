import React, { useContext, useEffect } from 'react'
import { Categories } from './categories'
import { Sessions } from './sessions'
import { ProjectContext } from '../../../context/ProjectContext'

export function Sidebar({ hideCategories = false }) {
  const { groupNotesBy, sidebarScrollContainer } = useContext(ProjectContext)

  useEffect(() => {
    if (!sidebarScrollContainer) return

    sidebarScrollContainer.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [groupNotesBy, sidebarScrollContainer])

  if (groupNotesBy === 'meeting') {
    return (
      <>
        <div className="mb-[56px]">
          <Sessions />
        </div>
        <div>
          <Categories />
        </div>
      </>
    )
  }
  return (
    <>
      {!hideCategories && (
        <div className="mb-[56px]">
          <Categories />
        </div>
      )}
      <div>
        <Sessions />
      </div>
    </>
  )
}
