/* eslint-disable react/no-unknown-property */
import React, { useContext } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { StarsFilter } from '../overview/filters/stars'
import { MeetingsFilter } from '../overview/filters/meetings'
import { Feedbacks } from './feedbacks'
import FiltersIcon from '../../icons/Filters'

export function FeedbacksIndex() {
  const { isLoading, filteredFeedbacks, feedbacksFilters, setFeedbacksFilters } = useContext(ProjectContext)

  return (
    <div className="px-[50px] py-[30px] w-full overflow-y-auto">
      <div className="w-full mb-[15px] flex justify-between items-center">
        <p className="text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px] mr-4">Feedbacks</p>
        <div className="w-auto h-12 bg-white rounded-[10px] flex item-center" style={{ whiteSpace: 'nowrap' }}>
          <div className="relative border-r border-[#e4e4e4] flex justify-center items-center">
            <div className="py-3.5 px-5 flex justify-center items-center gap-1">
              <FiltersIcon />
              <p className="text-black text-base font-bold font-['Manrope'] leading-tight ms-1">FILTERS</p>
            </div>
          </div>
          <StarsFilter filters={feedbacksFilters} setFilters={setFeedbacksFilters} label={'Feedback'} />
          <MeetingsFilter filters={feedbacksFilters} setFilters={setFeedbacksFilters} />
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center">
          <svg className="w-40 h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
              <stop offset="0" stopColor="#FF156D"></stop>
              <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
              <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
              <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
              <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
            </radialGradient>
            <circle
              transform-origin="center"
              fill="none"
              stroke="url(#a1)"
              strokeWidth="15"
              strokeLinecap="round"
              strokeDasharray="200 1000"
              strokeDashoffset="0"
              cx="100"
              cy="100"
              r="70"
            >
              <animateTransform
                type="rotate"
                attributeName="transform"
                calcMode="spline"
                dur="2"
                values="360;0"
                keyTimes="0;1"
                keySplines="0 0 1 1"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              transform-origin="center"
              fill="none"
              opacity=".2"
              stroke="#FF156D"
              strokeWidth="15"
              strokeLinecap="round"
              cx="100"
              cy="100"
              r="70"
            ></circle>
          </svg>
        </div>
      ) : (
        <div className="w-full flex flex-wrap relative">
          <Feedbacks feedbacks={filteredFeedbacks} />
        </div>
      )}
    </div>
  )
}
