import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * CustomVideoPlayer component to display a specific segment of a video 
 * with customized controls like play/pause, scrubber, and replay functionality.
 * 
 * @param {Object} props - React props.
 * @param {string} props.videoSrc - The source URL of the video to be played.
 * @param {number} props.startTime - The starting point of the video segment (in seconds).
 * @param {number} props.endTime - The ending point of the video segment (in seconds).
 * @returns {JSX.Element} A video player component restricted to play a segment of the video.
 */
const CustomVideoPlayer = ({ videoSrc, startTime, endTime }) => {
  const videoRef = useRef(null); // Ref to access the video element
  const [isPlaying, setIsPlaying] = useState(false); // Track if the video is playing
  const [currentTime, setCurrentTime] = useState(0); // Displayed current time in the segment
  const [isEnded, setIsEnded] = useState(false); // Track if the video has ended
  const videoDuration = endTime - startTime; // Duration of the video segment

  /**
   * Formats a given time in seconds into a human-readable format (mm:ss).
   * 
   * @param {number} seconds - Time in seconds to format.
   * @returns {string} Formatted time in mm:ss.
   */
  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  /**
   * Toggles play and pause for the video. Plays if paused, pauses if playing.
   */
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (!isPlaying) {
      video.play();
    } else {
      video.pause();
    }
    setIsPlaying(!isPlaying);
  };

  /**
   * Handles the time update event from the video element to update the scrubber
   * and restrict video playback to the specified segment.
   */
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    const segmentTime = video.currentTime - startTime;
    setCurrentTime(segmentTime); // Track time relative to the segment

    // Pause the video if it reaches the segment's end time
    if (video.currentTime >= endTime) {
      video.pause();
      setIsPlaying(false);
      setIsEnded(true); // Mark the video as ended
    }
  };

  /**
   * Handles the loaded metadata event to initialize the video to the segment's
   * start time and set the duration of the segment.
   */
  const handleLoadedMetadata = () => {
    const video = videoRef.current;
    video.currentTime = startTime; // Start the video at the segment's start time
    setCurrentTime(0); // Reset scrubber to 0 for the segment
  };

  /**
   * Handles changes to the scrubber position, allowing the user to scrub
   * through the video segment only.
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} e - The scrubber input change event.
   */
  const handleScrubberChange = (e) => {
    const scrubbedTime = startTime + (e.target.value / 100) * (endTime - startTime);
    videoRef.current.currentTime = scrubbedTime; // Scrub to the new time within the segment
  };

  /**
   * Handles the replay of the video by resetting the video to the segment's start time.
   */
  const handleReplay = () => {
    const video = videoRef.current;
    video.currentTime = startTime; // Restart video at the start of the segment
    setIsPlaying(true);
    setIsEnded(false); // Reset the ended state
    video.play();
  };

  return (
    <div className="video-container" style={{ position: "relative", maxWidth: "640px", height: "100%", margin: "0 auto" }}>
      {/* Video element */}
      <video
        ref={videoRef}
        src={videoSrc}
        preload="auto"
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onClick={togglePlayPause}
        width="100%"
        style={{ cursor: "pointer" }}
      />

      {/* Overlay play button - Only when video is paused or ended */}
      {(!isPlaying || isEnded) && (
        <div
          className="overlay-play"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "60px",
            color: "rgba(255, 255, 255, 0.7)",
            cursor: "pointer"
          }}
          onClick={isEnded ? handleReplay : togglePlayPause}
        >
          <svg width="70" height="70" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="18.7314" cy="18.8159" rx="18.1982" ry="18.2905" fill="white"/>
            <path d="M27.2122 18.3131C27.5308 18.5719 27.5308 19.0582 27.2122 19.317L15.236 29.0464C14.8133 29.3897 14.1814 29.089 14.1814 28.5444L14.1814 9.0857C14.1814 8.54114 14.8133 8.24037 15.236 8.58373L27.2122 18.3131Z" fill="black"/>
          </svg>
        </div>
      )}

      {/* Custom controls */}
      <div className="controls" style={{ position: "absolute", bottom: 0, width: '100%', display: "flex", justifyContent: "space-between", padding: "10px", background: "#333", color: "#fff" }}>
        {/* Play/Pause button */}
        <button onClick={togglePlayPause} style={{ color: "#fff", background: "none", border: "none" }}>
          {isPlaying
            ? <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  className="icon icon-tabler icons-tabler-filled icon-tabler-player-pause"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 4h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z" /><path d="M17 4h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2z" /></svg>
            : <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  className="icon icon-tabler icons-tabler-filled icon-tabler-player-play"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z" /></svg>
          }
        </button>

        {/* Scrubber for the video segment */}
        <div className="scrubber" style={{ flexGrow: 1, margin: "0 10px" }}>
          <input
            type="range"
            min="0"
            max="100"
            value={(currentTime / (endTime - startTime)) * 100}
            onChange={handleScrubberChange}
            style={{ width: "100%" }}
            className='accent-[#CF3F75]'
          />
        </div>

        {/* Display current time and total segment duration */}
        <span>{`${formatTime(currentTime)} / ${formatTime(videoDuration)}`}</span>
      </div>
    </div>
  );
};

export default CustomVideoPlayer;

CustomVideoPlayer.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
};
