import React from 'react'
import PropTypes from 'prop-types'

export function CheckMark ({ text }) {
  return (
        <div className="flex w-full">
            <svg width="30" height="30" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_524_4479)">
                    <path d="M6 16.5455L12.5455 23.0909L25.6364 10" stroke="#27AE60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_524_4479">
                        <rect width="36" height="36" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            <p className="text-zinc font-medium font-['Manrope']">{ text }</p>
        </div>
  )
}

CheckMark.propTypes = {
  text: PropTypes.string.isRequired
}
