export function getMeetingProvider(link) {
  const googleMeetURLRegex = /^(https:\/\/)?meet\.google\.com\/(lookup\/)?[a-zA-Z0-9-?=&]+$/
  const zoomURLRegex = /^(https?:\/\/)?(zoom\.us|us\d+web\.zoom\.us)\/j\/\d{9,11}(\/?|\?\S+)$/
  const teamsURLRegex = /^https:\/\/(?:teams\.microsoft\.com\/(?:l\/meetup-join|meeting)\/|teams\.live\.com\/meet\/|teams\.microsoft\.us\/l\/meetup-join\/)[\w\-.~:/?#[\]@!$&'()*+,;=%]+$/i;

  if (googleMeetURLRegex.test(link)) return 'Google'
  if (zoomURLRegex.test(link)) return 'Zoom'
  if (teamsURLRegex.test(link)) return 'Teams'
  return ''
}
