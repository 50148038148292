import React, { useContext } from 'react'
import { QA } from './qa'
import { MeetingsContext } from '../../../../context/MeetingContext'

export function QAs() {
  const { qas, qasRef } = useContext(MeetingsContext)

  return (
    <div className="w-full" ref={qasRef}>
      <div className="pb-[10px] border-b-2 border-grey-200">
        <p className="w-full text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">
          Interview at a Glance
        </p>
      </div>

      {qas.map((qa) => (
        <QA key={qa.id} question={qa.facilitator_question} answer={qa.content} id={qa.id} />
      ))}
    </div>
  )
}
