import React from 'react'

const QuotesIcon = ({ checked }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5015 11H6.50146C6.23625 11 5.98189 10.8946 5.79436 10.7071C5.60682 10.5196 5.50146 10.2652 5.50146 10V7C5.50146 6.73478 5.60682 6.48043 5.79436 6.29289C5.98189 6.10536 6.23625 6 6.50146 6H9.50146C9.76668 6 10.021 6.10536 10.2086 6.29289C10.3961 6.48043 10.5015 6.73478 10.5015 7V13C10.5015 15.667 9.16846 17.333 6.50146 18"
        stroke={checked ? '#FF4A8E' : '#40424A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5015 11H15.5015C15.2362 11 14.9819 10.8946 14.7944 10.7071C14.6068 10.5196 14.5015 10.2652 14.5015 10V7C14.5015 6.73478 14.6068 6.48043 14.7944 6.29289C14.9819 6.10536 15.2362 6 15.5015 6H18.5015C18.7667 6 19.021 6.10536 19.2086 6.29289C19.3961 6.48043 19.5015 6.73478 19.5015 7V13C19.5015 15.667 18.1685 17.333 15.5015 18"
        stroke={checked ? '#FF4A8E' : '#40424A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default QuotesIcon
