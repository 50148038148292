import React, { useContext, useEffect, useState } from 'react'
import { DashboardContext } from '../../../context/DashboardContext'
import { EventsWrap } from '../events/eventsWrap'
import { MeetingsFilters } from './filters'
import { Meeting } from './meeting'
import { NoMeetings } from './NoMeetings'

export function Meetings() {
  const { meetingsOverview, allProjects, allSessions, sessions, setSessions, sessionsLoading } =
    useContext(DashboardContext)
  const [projectFilter, setProjectFilter] = useState(-1)
  const [validationFilter, setValidationFilter] = useState('')

  function handleProjectFilter(projectIndex) {
    setProjectFilter(projectIndex)
    if (projectIndex === -1) {
      setSessions(allSessions)
    } else {
      const projectId = allProjects[projectIndex].id
      setSessions(allSessions.filter((meeting) => meeting.project_id === projectId))
    }
  }

  function handleValidationFilter(isProspectToUseIt) {
    setValidationFilter(isProspectToUseIt)
    if (isProspectToUseIt === '') {
      setSessions(allSessions)
    } else {
      const filteredSessions = allSessions.filter(
        (meeting) => meetingsOverview[meeting.id]?.validation?.is_prospect_to_use_it === isProspectToUseIt
      )
      setSessions(filteredSessions)
    }
  }

  useEffect(() => {
    const resetFilters = () => {
      handleProjectFilter(-1)
      setValidationFilter('')
    }
    return () => resetFilters()
  }, [])

  return (
    <>
      {!sessionsLoading && allSessions.length === 0 ? (
        <NoMeetings />
      ) : (
        <div className="pr-[28px]">
          <p className="pt-8 text-[#3f424a] text-[26px] font-semibold font-['Manrope'] leading-[29.90px]">Meetings</p>

          <EventsWrap />

          <div className="w-full mb-[15px] flex justify-between items-center">
            <p className="text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">
              Recent Meetings
            </p>
            <MeetingsFilters
              projects={allProjects}
              projectIndex={projectFilter}
              setProjectIndex={handleProjectFilter}
              validation={validationFilter}
              setValidation={handleValidationFilter}
              projectFilterEnabled={true}
            />
          </div>

          {sessionsLoading ? (
            <div className="flex justify-center items-center">
              <svg className="w-40 h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                  <stop offset="0" stopColor="#FF156D"></stop>
                  <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
                  <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
                  <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
                  <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
                </radialGradient>
                <circle
                  transform-origin="center"
                  fill="none"
                  stroke="url(#a1)"
                  strokeWidth="15"
                  strokeLinecap="round"
                  strokeDasharray="200 1000"
                  strokeDashoffset="0"
                  cx="100"
                  cy="100"
                  r="70"
                >
                  <animateTransform
                    type="rotate"
                    attributeName="transform"
                    calcMode="spline"
                    dur="2"
                    values="360;0"
                    keyTimes="0;1"
                    keySplines="0 0 1 1"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
                <circle
                  transform-origin="center"
                  fill="none"
                  opacity=".2"
                  stroke="#FF156D"
                  strokeWidth="15"
                  strokeLinecap="round"
                  cx="100"
                  cy="100"
                  r="70"
                ></circle>
              </svg>
            </div>
          ) : (
            <div className="flex flex-wrap gap-4">
              {sessions.map((meeting) => (
                <Meeting
                  key={meeting.id}
                  meeting={meeting}
                  validation={meetingsOverview[meeting.id]?.validation?.is_prospect_to_use_it}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  )
}
