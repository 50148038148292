import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { ProjectContext } from '../../../../context/ProjectContext'
import { useCustomFetch } from '../../../../hooks/useCustomFetch'
import toast from 'react-hot-toast'
import { createPortal } from 'react-dom'
import { ImportFiles } from '../../../dashboard/dialog/importFiles'
import cnt from '../../../../constants'
import Tippy from '@tippyjs/react'
import { AddNotesIcon } from '../../../icons/AddNotes'
import { CloudImportIcon } from '../../../icons/CloudImport'
import { CameraIcon } from '../../../icons/Camera'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object().shape({
  link: yup.string().url('Please enter a valid URL').required('URL is required'),
})

export function EmptyMeeting({ meeting }) {
  const { setListenRealTime, setMeetings, setSelectedMeeting, project, notesInputRef, workspace } =
    useContext(ProjectContext)
  const [showImportDialog, setShowImportDialog] = useState(false)
  const customFetch = useCustomFetch()

  const shouldDisable =
    workspace.status !== 'active' && workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed

  const {
    register,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const sendBot = async (url) => {
    try {
      setListenRealTime(false)
      const newMeet = await customFetch('/createBot', 'POST', {
        link: url,
        projectId: project.id,
        projectName: project.name,
        projectDescription: project.description,
        meetingId: meeting.id,
      })
      if (newMeet?.meeting) {
        setMeetings((prevMeetings) => prevMeetings.map((meet) => (meet?.id === meeting?.id ? newMeet.meeting : meet)))
        setListenRealTime(true)
        toast.success('Great! Go accept the bot into the meeting.', { duration: 3000 })
      } else {
        toast.error('Could not create bot. Please try again later.')
      }
    } catch (error) {
      toast.error('Error sending bot')
      console.error('Error sending bot:', error)
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      const isValid = await trigger('link')
      if (isValid) {
        const url = getValues('link')
        await sendBot(url)
      }
    }
  }

  return (
    <>
      <p className="mt-[18px] text-slate text-base font-normal font-['Manrope'] leading-[23px]">
        You can add notes manually to this meeting, upload a meeting to be analyzed, or paste a live meeting link for
        live transcription.
      </p>
      <div className="mt-[30px] flex items-center">
        <div className="pt-[30px] flex flex-col items-center w-[225px] h-[134px] bg-white rounded-lg shadow border border-neutral-200">
          <p className="text-center text-strongSlate text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            Add Live Meeting
          </p>
          <Tippy
            disabled={!shouldDisable}
            maxWidth={300}
            content="This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features."
          >
            <div className="mt-4 ml-1 w-[184px] h-[43px] relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-4 pointer-events-none">
                <CameraIcon />
              </div>
              <input
                disabled={shouldDisable}
                type="url"
                id="default-search"
                className={`h-full w-full ps-[50px] text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border ${errors.link ? 'border-red-500' : 'border-grey-200'}`}
                placeholder="Paste link"
                {...register('link')}
                onKeyDown={handleKeyDown}
                onChange={(e) => setValue('link', e.target.value)}
                required
              />
              {errors.link && <p className="text-red text-xs">{errors.link.message}</p>}
            </div>
          </Tippy>
        </div>

        <div className="mx-5 pt-[30px] flex flex-col items-center w-[225px] h-[134px] bg-white rounded-lg shadow border border-neutral-200">
          <p className="text-center text-strongSlate text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            Upload Recording
          </p>
          <Tippy
            disabled={!shouldDisable}
            maxWidth={300}
            content="This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features."
          >
            <div>
              <button
                className={`mt-1 ${shouldDisable && 'opacity-50'}`}
                onClick={() => setShowImportDialog(true)}
                disabled={!!shouldDisable}
              >
                <CloudImportIcon />
              </button>
            </div>
          </Tippy>
        </div>
        {showImportDialog &&
          createPortal(
            <ImportFiles
              projectId={project.id}
              projectName={project.name}
              handleClose={() => setShowImportDialog(false)}
              setMeetings={setMeetings}
              limitFiles={1}
              meetingId={meeting.id}
            />,
            document.body
          )}

        <div className="pt-[30px] flex flex-col items-center w-[225px] h-[134px] bg-white rounded-lg shadow border border-neutral-200">
          <p className="text-center text-strongSlate text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
            ADD NOTES
          </p>
          <button
            className="mt-3"
            onClick={() => {
              notesInputRef.focus()
              setSelectedMeeting(meeting.id)
            }}
          >
            <AddNotesIcon />
          </button>
        </div>
      </div>
    </>
  )
}

EmptyMeeting.propTypes = {
  meeting: PropTypes.object,
}
