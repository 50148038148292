/* eslint-disable react/no-unknown-property */
import React, { useContext, useState } from 'react'
import thumbnailDefault from '../../../../assets/audio-thumbnail.png'
import { createPortal } from 'react-dom'
import { QuoteDialog } from './quoteDialog'
import { formatFileDuration } from '../../../../utils'
import { ProjectContext } from '../../../../context/ProjectContext'

export function Quotes({ label, quotes }) {
  const [currentQuote, setCurrentQuote] = useState(-1)
  const { participants, sessions } = useContext(ProjectContext)

  const truncateMeetingName = (name) => {
    if (!name) return ''
    if (name.length < 23) {
      return name
    } else {
      return name.slice(0, 21) + '...'
    }
  }

  if (!quotes.length) {
    return (
      <div className="w-full">
        {label && (
          <p className="mb-4 w-full pb-[10px] text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px] border-b-2 border-grey-200">
            {label}
          </p>
        )}
        <div className="flex justify-center">
          <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
              <stop offset="0" stopColor="#FF156D"></stop>
              <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
              <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
              <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
              <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
            </radialGradient>
            <circle
              transform-origin="center"
              fill="none"
              stroke="url(#a1)"
              strokeWidth="15"
              strokeLinecap="round"
              strokeDasharray="200 1000"
              strokeDashoffset="0"
              cx="100"
              cy="100"
              r="70"
            >
              <animateTransform
                type="rotate"
                attributeName="transform"
                calcMode="spline"
                dur="2"
                values="360;0"
                keyTimes="0;1"
                keySplines="0 0 1 1"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              transform-origin="center"
              fill="none"
              opacity=".2"
              stroke="#FF156D"
              strokeWidth="15"
              strokeLinecap="round"
              cx="100"
              cy="100"
              r="70"
            ></circle>
          </svg>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="w-full">
        {label && (
          <p className="mb-4 w-full pb-[10px] text-[#40424A] text-[26px] font-semibold font-['Manrope'] leading-[29.90px] border-b-2 border-grey-200">
            {label}
          </p>
        )}
        <div className="flex flex-wrap gap-2.5">
          {Array.isArray(quotes) &&
            quotes.map((quote, index) => (
              <div
                key={index}
                className="relative w-[237.68px] h-[362px] p-3.5 bg-white rounded-sm shadow border border-[#e4e4e4] cursor-pointer"
                onClick={() => setCurrentQuote(index)}
              >
                <div className="flex justify-between items-center">
                  <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
                    {quote.participant_id && participants[quote.participant_id]
                      ? participants[quote.participant_id]
                      : quote.who_said_it}
                  </p>
                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_3874_14613)">
                      <path
                        d="M10.1279 9.89357H6.46308C6.22009 9.89357 5.98705 9.79988 5.81523 9.63311C5.6434 9.46634 5.54688 9.24016 5.54688 9.00431V6.33653C5.54688 6.10068 5.6434 5.87449 5.81523 5.70772C5.98705 5.54096 6.22009 5.44727 6.46308 5.44727H9.21171C9.4547 5.44727 9.68774 5.54096 9.85956 5.70772C10.0314 5.87449 10.1279 6.10068 10.1279 6.33653V11.6721C10.1279 14.0438 8.90661 15.5253 6.46308 16.1184"
                        stroke="#62718D"
                        strokeWidth="0.970103"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.374 9.89357H14.7092C14.4662 9.89357 14.2331 9.79988 14.0613 9.63311C13.8895 9.46634 13.793 9.24016 13.793 9.00431V6.33653C13.793 6.10068 13.8895 5.87449 14.0613 5.70772C14.2331 5.54096 14.4662 5.44727 14.7092 5.44727H17.4578C17.7008 5.44727 17.9338 5.54096 18.1057 5.70772C18.2775 5.87449 18.374 6.10068 18.374 6.33653V11.6721C18.374 14.0438 17.1527 15.5253 14.7092 16.1184"
                        stroke="#62718D"
                        strokeWidth="0.970103"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3874_14613">
                        <rect width="21.989" height="21.3423" fill="white" transform="translate(0.964844 0.113281)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="flex justify-center my-[10px]">
                  <div className="relative w-[206.96px] h-[117.06px] bg-black/60 rounded-sm border border-[#3f424a]">
                    <img
                      className={`w-full h-full rounded ${sessions.find((session) => session.id === quote.meetingId)?.thumbnailUrl ? 'opacity-70' : ''}`}
                      src={
                        quote.thumbnailUrl ||
                        sessions.find((session) => session.id === quote.meetingId)?.thumbnailUrl ||
                        thumbnailDefault
                      }
                      alt="quote"
                    />
                    <div className="absolute" style={{ top: 'calc(50% - 19px)', left: 'calc(50% - 19px)' }}>
                      <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <ellipse cx="18.7314" cy="18.8159" rx="18.1982" ry="18.2905" fill="white" />
                        <path
                          d="M27.2122 18.3131C27.5308 18.5719 27.5308 19.0582 27.2122 19.317L15.236 29.0464C14.8133 29.3897 14.1814 29.089 14.1814 28.5444L14.1814 9.0857C14.1814 8.54114 14.8133 8.24037 15.236 8.58373L27.2122 18.3131Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div className="absolute bottom-0 right-0 w-[42px] h-[23px] px-1.5 py-[5px] bg-black/60 rounded-[3px] justify-center items-center gap-2.5 inline-flex">
                      <div className="text-white text-[10px] font-bold font-['Manrope'] leading-3">
                        {quote.end_time <= quote.start_time ? '1.00' : (quote.end_time - quote.start_time).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
                <p className="text-[#3f424a] text-xs font-medium font-['Manrope'] leading-[13.80px]">
                  “
                  {quote.original_transcript_segment.length > 380
                    ? quote.original_transcript_segment.slice(0, 380) + '...'
                    : quote.original_transcript_segment}
                  ”
                </p>
                <p className="absolute bottom-[10px] left-[14px] text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-none">
                  {truncateMeetingName(sessions.find((session) => session.id === quote.meetingId)?.name)} @ 0:
                  {formatFileDuration(Math.round(quote.start_time))}
                </p>
              </div>
            ))}
        </div>
      </div>

      {currentQuote >= 0 &&
        createPortal(
          <QuoteDialog
            meet={sessions.find((session) => session.id === quotes[currentQuote]?.meetingId)}
            quote={quotes[currentQuote]}
            currentQuote={currentQuote}
            totalQuotes={quotes.length}
            thumbnail={
              quotes[currentQuote]?.thumbnailUrl ||
              sessions.find((session) => session.id === quotes[currentQuote]?.meetingId)?.thumbnailUrl ||
              thumbnailDefault
            }
            next={currentQuote > 0 ? () => setCurrentQuote((prev) => prev - 1) : null}
            prev={currentQuote < quotes.length - 1 ? () => setCurrentQuote((prev) => prev + 1) : null}
            closeDialog={() => setCurrentQuote(-1)}
          />,
          document.body
        )}
    </>
  )
}
