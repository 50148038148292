/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useState } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import { Meeting } from './meeting'

export function GridByMeeting() {
  const { meetings, notes, showDuplicates } = useContext(ProjectContext)
  const [groupNotesByMeetings, setGroupNotesByMeetings] = useState([])

  useEffect(() => {
    const meetingsWithNotes = []
    const notesWithNoMeetings = notes.filter((note) => !note.meeting_id)
    if (notesWithNoMeetings.length) {
      // General Notes
      meetingsWithNotes.push({ id: 0, notes: notesWithNoMeetings })
    }
    for (const meet of meetings) {
      let notesByMeeting = notes.filter((note) => note.meeting_id === meet?.id)
      notesByMeeting = notesByMeeting.filter((note) => showDuplicates || !note.duplicate)
      notesByMeeting.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
      meetingsWithNotes.push({ ...meet, notes: notesByMeeting })
    }
    setGroupNotesByMeetings(meetingsWithNotes)
  }, [meetings, notes, showDuplicates])

  return groupNotesByMeetings.map((meeting) => <Meeting meeting={meeting} key={meeting.id} />)
}
