import React from 'react'

const AddPinkIcon = () => {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6456_3337)">
      <path d="M18.4961 7.9707V28.9707" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.00586 18.4727H29.0059" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_6456_3337">
      <rect width="36" height="36" fill="white" transform="translate(0.5 0.472656)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default AddPinkIcon
