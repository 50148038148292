import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { authCustomFetch } from '../hooks/useCustomFetch'
import useNetwork from '../hooks/useNetwork'

export const AuthContext = createContext()

const scopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/calendar.readonly',
]

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({}) // { id, email, name, picture, workspaces, active_workspace }
  const [isLoading, setIsLoading] = useState(true)
  const [prevRoute, setPrevRoute] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  useNetwork(user)

  function redirect2workspace(data) {
    setUser(data)
    if (!data.active_workspace) {
      if (prevRoute) {
        const startIndex = prevRoute.indexOf('plan_id=')
        if (startIndex !== -1) {
          let planId = prevRoute.substring(startIndex)
          const endIndex = planId.indexOf('&')
          if (endIndex !== -1) {
            planId = planId.substring(0, endIndex)
          }
          navigate('/workspace/create?' + planId)
        } else {
          navigate('/workspace/create')
        }
      } else {
        navigate('/workspace/create')
      }
    } else {
      if (prevRoute && prevRoute !== '/signin') navigate(prevRoute)
      else navigate('/dashboard/home')
    }
  }

  const googleSignIn = async () => {
    window.google.accounts.oauth2
      .initCodeClient({
        client_id: import.meta.env.VITE_OAUTH_CLIENT_ID,
        scope: scopes.join(' '),
        ux_mode: 'popup',
        callback: async (response) => {
          const data = await authCustomFetch('/auth/login/google', 'POST', { code: response.code })
          if (data.error) return
          localStorage.setItem('signin_method', data.signin_method || 'google')
          if (data.picture && data.picture.endsWith('=s96-c')) {
            data.picture =  data.picture.substr(0, data.picture.length - 6)
          }
          redirect2workspace(data)
        },
      })
      .requestCode()
  }

  const googleSignOut = async () => {
    // await authCustomFetch('/auth/logout', 'POST', null)
    localStorage.removeItem('access_token')
    localStorage.removeItem('signin_method')
    setUser({})
    navigate('/signin')
  }

  const microsoftSignIn = async () => {
    // construct the url for the Microsoft login
    const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID
    const redirectUri = encodeURIComponent(import.meta.env.VITE_CLIENT_URL + '/callback')
    const scopes = encodeURIComponent('User.Read Calendars.Read offline_access')
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&response_mode=query`
  }

  const sendMicrosoftCode = async (code) => {
    const data = await authCustomFetch('/auth/login/microsoft', 'POST', { code })
    if (data.error) {
      navigate('/signin')
      return
    }
    localStorage.setItem('signin_method', data.signin_method || 'microsoft')
    redirect2workspace(data)
  }

  const microsoftSignOut = async () => {
    // await authCustomFetch('/auth/logout', 'POST', null)
    localStorage.removeItem('access_token')
    localStorage.removeItem('signin_method')
    setUser({})
    navigate('/signin')
  }

  useEffect(() => {
    if (typeof user === 'object' && Object.keys(user).length > 0) return

    const getUserInfo = async () => {
      try {
        const data = await authCustomFetch('/auth/getUserInfo', 'GET', null)
        if (!data.error) {
          const userInfo = data.userInfo
          if (userInfo?.picture && userInfo.picture.endsWith('=s96-c')) {
            userInfo.picture = userInfo.picture.substr(0, userInfo.picture.length - 6)
          }
          setUser(userInfo)
        }
      } catch (error) {
        console.error(error)
        const signInMethod = localStorage.getItem('signin_method')
        if (signInMethod === 'google') {
          googleSignOut()
        } else if (signInMethod === 'microsoft') {
          microsoftSignOut()
        }
      }
      setPrevRoute(location.pathname + location.search)
      setIsLoading(false)
    }

    getUserInfo()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        googleSignIn,
        googleSignOut,
        microsoftSignIn,
        microsoftSignOut,
        sendMicrosoftCode,
        user,
        setUser,
        isLoading,
        setPrevRoute,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.node,
}

export const ProtectRoutes = ({ children }) => {
  const { user, isLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if (typeof user !== 'object' || Object.keys(user).length === 0) {
        navigate('/signin')
      } else if (!user.active_workspace) {
        if (!location.pathname.startsWith('/workspace/create')) navigate('/workspace/create')
      }
    }
  }, [isLoading])

  return <>{children}</>
}

ProtectRoutes.propTypes = {
  children: PropTypes.node,
}

export const LoggedRoute = ({ children }) => {
  const { user, isLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && typeof user === 'object' && Object.keys(user).length > 0) {
      if (user.active_workspace) {
        navigate('/dashboard/home')
      } else {
        navigate('/workspace/create')
      }
    }
  }, [isLoading])

  return <>{children}</>
}

LoggedRoute.propTypes = {
  children: PropTypes.node,
}
