import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { TimelineQuotes } from './quotes'
import { TimelineNeeds } from './needs'
import { TimelineQas } from './qas'
import { TimelineFeedbacks } from './feedbacks'
import { TimelineProblems } from './problems'

export function Timeline() {
  const { meeting } = useContext(MeetingsContext)

  return (
    <div className="flex flex-col gap-[18px]">
      <TimelineQuotes duration={meeting.duration} />

      <TimelineNeeds duration={meeting.duration} />

      <TimelineQas duration={meeting.duration} />

      <TimelineFeedbacks duration={meeting.duration} />

      <TimelineProblems duration={meeting.duration} />
    </div>
  )
}
