import React from 'react'

const GroupNotesByCategoryIcon = ({ groupNotesBy }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1287_4888)">
        <path
          d="M9.33317 4.66602H6.99984C5.71117 4.66602 4.6665 5.71068 4.6665 6.99935V20.9993C4.6665 22.288 5.71117 23.3327 6.99984 23.3327H9.33317C10.6218 23.3327 11.6665 22.288 11.6665 20.9993V6.99935C11.6665 5.71068 10.6218 4.66602 9.33317 4.66602Z"
          stroke={groupNotesBy === 'category' ? '#FF4A8E' : 'black'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0002 4.66602H18.6668C17.3782 4.66602 16.3335 5.71068 16.3335 6.99935V13.9993C16.3335 15.288 17.3782 16.3327 18.6668 16.3327H21.0002C22.2888 16.3327 23.3335 15.288 23.3335 13.9993V6.99935C23.3335 5.71068 22.2888 4.66602 21.0002 4.66602Z"
          stroke={groupNotesBy === 'category' ? '#FF4A8E' : 'black'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1287_4888">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GroupNotesByCategoryIcon
