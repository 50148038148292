import React from 'react'

const MeetingsIcon = ({ checked }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 8.00061L17.553 5.72461C17.7054 5.64844 17.8748 5.61249 18.045 5.62015C18.2152 5.62782 18.3806 5.67885 18.5256 5.76841C18.6706 5.85797 18.7902 5.98308 18.8733 6.13188C18.9563 6.28067 18.9999 6.44822 19 6.61861V13.3826C18.9999 13.553 18.9563 13.7205 18.8733 13.8693C18.7902 14.0181 18.6706 14.1432 18.5256 14.2328C18.3806 14.3224 18.2152 14.3734 18.045 14.3811C17.8748 14.3887 17.7054 14.3528 17.553 14.2766L13 12.0006V8.00061Z"
        stroke={checked ? '#FF4A8E' : '#40424A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H11C11.5304 4 12.0391 4.21071 12.4142 4.58579C12.7893 4.96086 13 5.46957 13 6V14C13 14.5304 12.7893 15.0391 12.4142 15.4142C12.0391 15.7893 11.5304 16 11 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V6Z"
        stroke={checked ? '#FF4A8E' : '#40424A'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MeetingsIcon
