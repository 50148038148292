import React from 'react'

export function Section({ title, children }) {
  return (
    <div className="py-[15px] border-b border-[#D3D3D6]">
      {!!title && (
        <p className="mb-[8px] text-[#86858F] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">
          {title}
        </p>
      )}
      {children}
    </div>
  )
}
