/* eslint-disable react/no-unknown-property */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { formatFileDuration, getDayAndMonth, getHour } from '../../../../utils'
import thumbnailDefault from '../../../../assets/audio-thumbnail.png'
import { MeetingsContext } from '../../../../context/MeetingContext'
import CustomVideoPlayer from '../../../../utils/CustomVideoPlayer'

export function QuoteDialog ({ quote, closeDialog, prev, next, currentQuote, totalQuotes }) {
  const { participants, url, meeting, thumbnail } = useContext(MeetingsContext)
  const endTime = Math.ceil(quote.end_time)
  const startTime = Math.floor(quote.start_time)

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-opacity-30 bg-black z-20" onClick={closeDialog}>
    {next && (
      <button className='absolute z-10' style={{ left: 'calc(50% - 510px - 30px)', top: 'calc(50% - 31px)' }} onClick={(e) => {
        e.stopPropagation()
        next()
      }}>
        <LeftArrow />
      </button>
    )}
    {prev && (
      <button className='absolute z-10' style={{ right: 'calc(50% - 510px - 30px)', top: 'calc(50% - 31px)' }} onClick={(e) => {
        e.stopPropagation()
        prev()
      }}>
        <RightArrow />
      </button>
    )}
    <div className="absolute w-[1020px] h-[500px] px-7 py-[21px] bg-white rounded-2xl" style={{ left: 'calc(50% - 510px)', top: 'calc(50% - 250px)' }} onClick={(e) => e.stopPropagation()}>
      <div className='flex items-center justify-between'>
        <p><span className="text-[#3f424a] text-[22px] font-medium font-['DM_Sans'] leading-relaxed">Quote #{(currentQuote + 1).toString().padStart(3, '0')} </span><span className="text-[#62708d] text-[22px] font-medium font-['DM_Sans'] leading-relaxed">- {meeting.name}</span></p>
        <button onClick={closeDialog}>
          <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.8577 12L13.7158 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.7158 12L26.8577 24" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
      <div className="flex flex-wrap items-center text-[#62708d] text-base font-normal font-['Manrope'] leading-[23px]">
        {!meeting.start
          ? (
            <>
              <p>{getDayAndMonth(new Date(meeting.created_at))}・</p>
              <p>{getHour(new Date(meeting.created_at))}</p>
            </>
            )
          : (
            <>
              <p>{getDayAndMonth(new Date(meeting.start))}・</p>
              <p>{getHour(new Date(meeting.start))}</p>
            </>
            )
        }
        {!!meeting.duration && (
          <>
            <p>・</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3792_25591)">
              <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 7V12L15 15" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3792_25591">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <p>&nbsp;0:{formatFileDuration(Math.round(quote.end_time - quote.start_time))}s</p>
          </>
        )}
      </div>
      <div className='mt-[21px] w-[960px] h-[360px] justify-between items-start inline-flex'>
        {url && startTime && endTime
          ? <div className="relative w-[640px] h-[360px] bg-cover bg-center" style={{ backgroundImage: `url(${quote.thumbnailUrl || thumbnail || thumbnailDefault})` }}>
              <CustomVideoPlayer
                key={quote.id}
                videoSrc={url}
                startTime={startTime}
                endTime={endTime <= startTime ? startTime + 1 : endTime}
              />
              {/* <ReactPlayer
                ref={playerRef}
                onStart={() => playerRef.current.seekTo(Math.ceil(quote.start_time))}
                onClickPreview={() => setClickedPreview(true)}
                style={{ borderRadius: '4.31px', overflow: 'hidden' }}
                url={url}
                width="640px"
                height="360px"
                light={
                  <div className="w-full h-full relative">
                    <img src={quote.thumbnailUrl || thumbnail || thumbnailDefault} alt="Thumbnail" width={640} height={360} />
                    <button className='absolute z-10' style={{ left: 'calc(50% - 49px)', top: 'calc(50% - 53px)' }}>
                      <svg width="98" height="106" viewBox="0 0 98 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_3792_25601)">
                        <ellipse cx="48.5675" cy="53.0264" rx="38.419" ry="42.501" fill="white"/>
                        <path d="M67.4327 52.7066C67.6186 52.8728 67.6186 53.1638 67.4327 53.33L39.6638 78.1601C39.3944 78.4009 38.967 78.2097 38.967 77.8484L38.967 28.1882C38.967 27.8268 39.3944 27.6356 39.6638 27.8765L67.4327 52.7066Z" fill="black"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_3792_25601" x="0.113372" y="0.490325" width="96.91" height="105.072" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feMorphology radius="3.34502" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3792_25601"/>
                        <feOffset/>
                        <feGaussianBlur stdDeviation="3.34502"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3792_25601"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3792_25601" result="shape"/>
                        </filter>
                        </defs>
                      </svg>
                    </button>
                  </div>
                }
                controls
              /> */}
            </div>
          : <div className='w-full flex items-center justify-center'><svg className='w-16 h-16' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg></div>
        }
        <div className='w-[279px] h-full overflow-auto hideScrollbar'>
          <div className="h-[70px] py-[17px] border-t border-b border-[#e4e4e4] flex justify-between items-center">
            <div>
              <p className="w-[156px] text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-none tracking-wide">{quote.participant_id && participants[quote.participant_id]?.name ? participants[quote.participant_id].name : quote.who_said_it}</p>
              <p className="text-[#62708d] text-[13px] font-medium font-['Manrope'] leading-[18.20px]">Quote {currentQuote + 1} of {totalQuotes}</p>
            </div>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_3792_26468)">
              <path d="M14.1693 15.625H8.5026C8.12688 15.625 7.76655 15.4801 7.50087 15.2223C7.23519 14.9644 7.08594 14.6147 7.08594 14.25V10.125C7.08594 9.76033 7.23519 9.41059 7.50087 9.15273C7.76655 8.89487 8.12688 8.75 8.5026 8.75H12.7526C13.1283 8.75 13.4887 8.89487 13.7543 9.15273C14.02 9.41059 14.1693 9.76033 14.1693 10.125V18.375C14.1693 22.0421 12.2809 24.3329 8.5026 25.25" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M26.9193 15.625H21.2526C20.8769 15.625 20.5165 15.4801 20.2509 15.2223C19.9852 14.9644 19.8359 14.6147 19.8359 14.25V10.125C19.8359 9.76033 19.9852 9.41059 20.2509 9.15273C20.5165 8.89487 20.8769 8.75 21.2526 8.75H25.5026C25.8783 8.75 26.2387 8.89487 26.5043 9.15273C26.77 9.41059 26.9193 9.76033 26.9193 10.125V18.375C26.9193 22.0421 25.0309 24.3329 21.2526 25.25" stroke="#FF4A8E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3792_26468">
              <rect width="34" height="33" fill="white" transform="translate(0 0.5)"/>
              </clipPath>
              </defs>
            </svg>
          </div>
          <p className="mt-[26px] text-[#3f424a] text-lg font-normal font-['DM_Sans'] leading-[23px]">“{quote.original_transcript_segment}”</p>
        </div>
      </div>
    </div>
  </div>
  )
}

QuoteDialog.propTypes = {
  quote: PropTypes.object,
  currentQuote: PropTypes.number,
  totalQuotes: PropTypes.number,
  closeDialog: PropTypes.func,
  next: PropTypes.func,
  prev: PropTypes.func
}

function LeftArrow () {
  return (
    <svg width="59" height="62" viewBox="0 0 59 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3792_26553)" filter="url(#filter0_d_3792_26553)">
      <ellipse cx="22.125" cy="22.125" rx="22.125" ry="22.125" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.625 51.625)" fill="#FF4A8E"/>
      <path d="M31.957 22.125L24.582 29.5L31.957 36.875" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <filter id="filter0_d_3792_26553" x="-4.84211" y="0" width="68.6842" height="68.6842" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4.84211"/>
      <feGaussianBlur stdDeviation="2.42105"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3792_26553"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3792_26553" result="shape"/>
      </filter>
      <clipPath id="clip0_3792_26553">
      <rect width="59" height="59" fill="white" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 59 59)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

function RightArrow () {
  return (
    <svg width="59" height="62" viewBox="0 0 59 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3792_26557)" filter="url(#filter0_d_3792_26557)">
      <ellipse cx="29.5" cy="29.5" rx="22.125" ry="22.125" transform="rotate(-90 29.5 29.5)" fill="#FF4A8E"/>
      <path d="M27.043 22.125L34.418 29.5L27.043 36.875" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <filter id="filter0_d_3792_26557" x="-4.84211" y="0" width="68.6842" height="68.6842" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4.84211"/>
      <feGaussianBlur stdDeviation="2.42105"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3792_26557"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3792_26557" result="shape"/>
      </filter>
      <clipPath id="clip0_3792_26557">
      <rect width="59" height="59" fill="white" transform="translate(0 59) rotate(-90)"/>
      </clipPath>
      </defs>
    </svg>
  )
}
