import React from 'react'

export const CameraIcon = () => {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.666 5.50061L18.472 3.22461C18.6328 3.14844 18.8116 3.11249 18.9913 3.12015C19.171 3.12782 19.3456 3.17885 19.4986 3.26841C19.6516 3.35797 19.7779 3.48308 19.8656 3.63188C19.9532 3.78067 19.9993 3.94822 19.9993 4.11861V10.8826C19.9993 11.053 19.9532 11.2205 19.8656 11.3693C19.7779 11.5181 19.6516 11.6432 19.4986 11.7328C19.3456 11.8224 19.171 11.8734 18.9913 11.8811C18.8116 11.8887 18.6328 11.8528 18.472 11.7766L13.666 9.50061V5.50061Z"
        stroke="#FF4A8E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5556 1.5H3.11111C1.94518 1.5 1 2.39543 1 3.5V11.5C1 12.6046 1.94518 13.5 3.11111 13.5H11.5556C12.7215 13.5 13.6667 12.6046 13.6667 11.5V3.5C13.6667 2.39543 12.7215 1.5 11.5556 1.5Z"
        stroke="#FF4A8E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
