import React from 'react'

const FeedbackIcon = ({ checked }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill={checked ? '#FF4A8E' : '#40424A'}>
        <path
          d="M2453 5106 c-159 -40 -286 -153 -345 -308 -12 -32 -23 -98 -29 -170
            -10 -118 -34 -219 -71 -293 -11 -22 -164 -257 -340 -522 l-320 -483 -298 0
            c-334 0 -423 -10 -546 -61 -184 -77 -344 -239 -419 -426 -56 -138 -56 -143
            -52 -1088 3 -966 -1 -908 72 -1060 98 -205 287 -359 509 -415 70 -18 113 -20
            362 -20 l284 0 502 -127 503 -128 605 -3 c388 -2 628 1 672 8 127 19 245 70
            351 150 140 106 134 95 666 1160 369 738 493 993 507 1050 25 99 25 287 0 379
            -67 244 -247 445 -474 528 -132 49 -180 52 -724 53 l-508 0 0 534 c0 581 -4
            642 -56 772 -67 167 -206 318 -366 398 -51 25 -120 53 -153 62 -82 21 -266 27
            -332 10z m213 -511 c64 -19 118 -65 152 -129 l27 -51 3 -542 3 -543 -144 0
            c-213 0 -280 -27 -341 -140 -52 -95 -35 -214 42 -293 75 -78 -11 -71 1032 -77
            922 -5 936 -5 980 -26 104 -48 154 -125 154 -237 l0 -68 -466 -932 c-418 -837
            -470 -937 -511 -974 -79 -73 -78 -73 -687 -73 l-536 0 -399 100 c-219 55 -400
            100 -402 100 -1 0 -3 458 -3 1018 l0 1017 411 615 c225 338 428 645 450 682
            78 133 141 328 154 486 5 53 12 82 19 82 7 0 35 -7 62 -15z m-1616 -2800 l0
            -1025 -146 0 c-84 0 -164 5 -186 12 -50 15 -122 78 -151 132 l-22 41 -3 805
            c-2 568 0 820 8 857 14 66 57 124 116 159 61 36 93 41 252 43 l132 1 0 -1025z"
        />
      </g>
    </svg>
  )
}

export default FeedbackIcon
