import React, { useContext, useRef, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { useCustomFetch } from '../../hooks/useCustomFetch'
import useOutsideClick from '../../hooks/useOutsideClick'
import { DashboardContext } from '../../context/DashboardContext'

export function WorkspaceSelection () {
  const { user, setUser } = useContext(AuthContext)
  const { workspaces } = useContext(DashboardContext)
  const [openMenu, setOpenMenu] = useState(false)
  const workspaceSelectorRef = useRef(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const customFetch = useCustomFetch()

  useOutsideClick(workspaceSelectorRef, () => {
    if (workspaceSelectorRef) {
      setOpenMenu(false)
    }
  })

  async function handleChangeActiveWorkspace (newActiveWorkspace) {
    if (user.active_workspace === newActiveWorkspace) return
    try {
      setUser(prevUser => ({ ...prevUser, active_workspace: newActiveWorkspace }))
      const response = await customFetch('/updateActiveWorkspace', 'PUT', { workspaceId: newActiveWorkspace })
      if (response.error) console.error(response.error)
    } catch (err) {
      console.error(err)
    }
  }

  const planName = useMemo(() => {
    if (!workspaces || !user.active_workspace || !Object.keys(workspaces).length || !Object.keys(user.active_workspace).length) return ''
    let text = workspaces[user.active_workspace].plan_id
    if (!text) return ''
    text = text.replace('USD-', '').replaceAll('-', ' ')
    return text
  }, [workspaces, user.active_workspace])

  const planPrice = useMemo(() => {
    if (!workspaces || !user.active_workspace || !Object.keys(workspaces).length || !Object.keys(user.active_workspace).length) return 0
    if (!workspaces[user.active_workspace].plan_price) return 0
    return (workspaces[user.active_workspace].plan_price / 100).toFixed(2)
  }, [workspaces, user.active_workspace])

  const billingPeriodUnit = useMemo(() => {
    if (!workspaces || !user.active_workspace || !Object.keys(workspaces).length || !Object.keys(user.active_workspace).length) return ''
    if (!workspaces[user.active_workspace].billing_period_unit) return ''
    const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1)
    return capitalizeFirstLetter(workspaces[user.active_workspace].billing_period_unit)
  }, [workspaces, user.active_workspace])

  if (!workspaces || !user.active_workspace || !Object.keys(workspaces).length || !Object.keys(user.active_workspace).length) {
    return (
      <div className='mt-[40px] w-[600px] px-[16px] py-[8px] bg-white rounded shadow border border-grey-200 relative'>
        <button className='mx-2 flex items-center w-full justify-between'>
          <p className="opacity-80 text-black text-[13px] font-bold font-['Manrope'] leading-none">Loading...</p>
          <div className='mr-2'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2628_18782)">
              <circle cx="10" cy="10" r="7.5" fill="#EBEBEB"/>
              <path d="M12.5 9.16602L10 11.666L7.5 9.16602" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_2628_18782">
              <rect width="20" height="20" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
        </button>
      </div>
    )
  }

  return (
      <div className='mt-[40px] w-[600px] px-[16px] py-[8px] bg-white rounded shadow border border-grey-200 relative' ref={workspaceSelectorRef}>
        <button className='mx-2 flex items-center w-full justify-between' onClick={() => setOpenMenu(!openMenu)}>
          <p className="opacity-80 text-black text-[13px] font-bold font-['Manrope'] leading-none">{workspaces[user.active_workspace].name}</p>
          {/* <p className="opacity-80 text-[#40424A] text-[13px] font-semibold font-['Manrope'] leading-none">{workspaces[user.active_workspace].plan_id} (${workspaces[user.active_workspace].plan_price / 100} / {workspaces[user.active_workspace].billing_period_unit})</p> */}
          {planName && planPrice && billingPeriodUnit && <p className="opacity-80 text-[#40424A] text-[13px] font-semibold font-['Manrope'] leading-none">{planName} (${planPrice} / {billingPeriodUnit})</p>}
          <div className='mr-2'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2628_18782)">
              <circle cx="10" cy="10" r="7.5" fill="#EBEBEB"/>
              <path d="M12.5 9.16602L10 11.666L7.5 9.16602" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_2628_18782">
              <rect width="20" height="20" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
        </button>
        {openMenu && (
          <div className="absolute w-[600px] left-[-1px] top-8 px-[16px] py-[8px] bg-white shadow rounded-b border-x border-grey-200 z-10">
            {Object.keys(workspaces).map(workspace => (
              <button key={workspace} className="p-1 flex items-center gap-[8px] my-[5px]" onClick={() => handleChangeActiveWorkspace(workspace)}>
                {user.active_workspace === workspace
                  ? (
                  <>
                    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 6L6 11L16 1" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <p className="opacity-80 text-black text-[13px] font-bold font-['Manrope'] leading-none">{workspaces[workspace].name}</p>
                  </>
                    )
                  : (
                  <p className="ml-[25px] opacity-80 text-black text-[13px] font-medium font-['Manrope'] leading-none">{workspaces[workspace].name}</p>
                    )}
              </button>
            ))}
            <button className='border-t w-[568px] pt-[15px] pb-[8px] border-grey-200 flex grow items-center justify-start gap-[14px]' onClick={() => navigate(`/workspace/create?plan_id=${searchParams.get('plan_id')}`)}>
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2628_27952)">
                <path d="M6.5 2.70898V10.2923" stroke="#FF4A8E" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2.70898 6.5H10.2923" stroke="#FF4A8E" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_2628_27952">
                <rect width="13" height="13" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              <p className="opacity-80 text-black text-[13px] font-medium font-['Manrope'] leading-none">Add Workspace</p>
            </button>
          </div>
        )}
      </div>
  )
}
