import React from 'react'
import { Dashboard } from './components/dashboard'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { SignIn } from './components/signin'
import { AuthContextProvider, LoggedRoute, ProtectRoutes } from './context/AuthContext'
import { Project } from './components/project'
import { ProjectContextProvider } from './context/ProjectContext'
import { DashboardContextProvider } from './context/DashboardContext'
import { EventsContextProvider } from './context/EventsContext'
import { MeetingsContextProvider } from './context/MeetingContext'
import { Callback } from './components/signin/callback'
import { Toaster } from 'react-hot-toast'
import { CreateWorkspace } from './components/workspace/create'
import { SelectWorkspace } from './components/workspace/select'
import { Meeting } from './components/meeting'

function App() {
  return (
    <main className="m-0 h-screen">
      <Toaster />
      <BrowserRouter>
        <AuthContextProvider>
          <EventsContextProvider>
            <Routes>
              <Route path="/callback" element={<Callback />} />
              <Route
                path="/signin"
                element={
                  <LoggedRoute>
                    <SignIn />
                  </LoggedRoute>
                }
              />
              <Route
                path="/workspace"
                element={
                  <ProtectRoutes>
                    <Outlet />
                  </ProtectRoutes>
                }
              >
                <Route path="" element={<Navigate to="create" />} />
                <Route path="create" element={<CreateWorkspace />} />
                <Route
                  path="select"
                  element={
                    <DashboardContextProvider>
                      <SelectWorkspace />
                    </DashboardContextProvider>
                  }
                />
              </Route>
              <Route path="/dashboard" element={<Navigate to="/dashboard/home" />} />
              <Route
                path="/dashboard/:menu"
                element={
                  <ProtectRoutes>
                    <DashboardContextProvider>
                      <Dashboard />
                    </DashboardContextProvider>
                  </ProtectRoutes>
                }
              />
              <Route
                path="/dashboard/meetings/:meetingId"
                element={
                  <ProtectRoutes>
                    <MeetingsContextProvider>
                      <Meeting />
                    </MeetingsContextProvider>
                  </ProtectRoutes>
                }
              />
              <Route
                path="/project/:id"
                element={
                  <ProtectRoutes>
                    <DashboardContextProvider>
                      <ProjectContextProvider>
                        <Project />
                      </ProjectContextProvider>
                    </DashboardContextProvider>
                  </ProtectRoutes>
                }
              />
              <Route path="*" element={<Navigate to="/signin" />} />
            </Routes>
          </EventsContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </main>
  )
}

export default App
