import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

export function CreateProject ({ handleCreateProject, handleClose }) {
  const [projectName, setProjectName] = useState('')
  const [projectDescription, setProjectDescription] = useState('')
  const [method, setMethod] = useState('user_interviews')

  return (
    <>
      <div className='mb-[30px]'>
        <p className="text-zinc text-xs tracking-wide font-extrabold font-['Manrope'] mb-2">PROJECT NAME *</p>
        <input
          className="w-full h-12 pl-4 pr-8 py-2.5 shadow-inner border border-grey-200 bg-grey-100 rounded text-zinc text-base font-medium font-['Manrope']"
          placeholder="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </div>
      <div className='mb-[30px]'>
        <p className="text-zinc text-xs tracking-wide font-extrabold font-['Manrope'] mb-2">DESCRIPTION *</p>
        <input
          className="w-full h-12 pl-4 pr-8 py-2.5 shadow-inner border border-grey-200 bg-grey-100 rounded text-zinc text-base font-medium font-['Manrope']"
          placeholder="Descriptions help Usermuse AI take more relevant notes."
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
        />
      </div>
      <div className='mb-[30px]'>
        <p className="text-zinc text-xs tracking-wide font-extrabold font-['Manrope'] mb-2">METHOD</p>
        <div className='flex items-center'>
          <button className={`relative w-[165px] h-[135px] bg-white rounded-lg ${method === 'user_interviews' ? 'border-4 border-pink' : 'border-2 border-grey-200'}`} onClick={() => setMethod('user_interviews')}>
            <Tippy
              interactive={true}
              maxWidth={350}
              content={
                <div className='p-2 w-[300px] text-left'>
                  <p className='font-bold'>User Interviews</p>
                  <p>User Interviews are one-on-one conversations with users that help you gather detailed insights about their experiences, needs, and behaviors.</p>
                  <a className='text-linkBlue font-semibold' href='' onClick={(e) => e.preventDefault()} alt=''>Learn More</a>
                </div>
              }
            >
              <div className='absolute right-2 top-2'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.25">
                  <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7 10.332V10.3373" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.99935 8.00076C6.98707 7.78434 7.04545 7.5698 7.16569 7.38944C7.28593 7.20908 7.46152 7.07267 7.66602 7.00076C7.9166 6.90494 8.14152 6.75225 8.32306 6.55473C8.50461 6.35721 8.63783 6.12024 8.71223 5.86249C8.78663 5.60473 8.80019 5.33322 8.75183 5.06934C8.70348 4.80545 8.59453 4.55639 8.43356 4.34177C8.27259 4.12715 8.064 3.95282 7.82421 3.83251C7.58442 3.7122 7.31998 3.64919 7.0517 3.64844C6.78342 3.6477 6.51863 3.70924 6.27818 3.82822C6.03773 3.9472 5.82817 4.12037 5.66602 4.33409" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                </svg>
              </div>
            </Tippy>
            <div className='h-2/3 flex justify-center items-center bg-amber'>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2449_10292)">
                <path d="M16 14C16 16.1217 16.8429 18.1566 18.3431 19.6569C19.8434 21.1571 21.8783 22 24 22C26.1217 22 28.1566 21.1571 29.6569 19.6569C31.1571 18.1566 32 16.1217 32 14C32 11.8783 31.1571 9.84344 29.6569 8.34315C28.1566 6.84285 26.1217 6 24 6C21.8783 6 19.8434 6.84285 18.3431 8.34315C16.8429 9.84344 16 11.8783 16 14Z" stroke="#FFC921" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 42V38C12 35.8783 12.8429 33.8434 14.3431 32.3431C15.8434 30.8429 17.8783 30 20 30H28C30.1217 30 32.1566 30.8429 33.6569 32.3431C35.1571 33.8434 36 35.8783 36 38V42" stroke="#FFC921" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_2449_10292">
                <rect width="48" height="48" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <div className='h-1/3 pt-1'>
              <p className="flex pl-5 text-strongSlate text-[13px] font-extrabold font-['Manrope']">User Interviews</p>
              <p className="flex pl-5 text-strongSlate text-xs font-normal font-['Manrope']">Chat with solo users</p>
            </div>
          </button>

          <button className={`relative w-[165px] h-[135px] ml-[18px] bg-white rounded-lg ${method === 'user_testing' ? 'border-4 border-pink' : 'border-2 border-grey-200'}`} onClick={() => setMethod('user_testing')}>
            <Tippy
              interactive={true}
              maxWidth={350}
              content={
                <div className='p-2 w-[300px] text-left'>
                  <p className='font-bold'>User Testing</p>
                  <p>User Testing involves observing users as they interact with your product or prototype to uncover usability issues and areas for improvement.</p>
                  <a className='text-linkBlue font-semibold' href='' onClick={(e) => e.preventDefault()} alt=''>Learn More</a>
                </div>
              }
            >
              <div className='absolute right-2 top-2'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.25">
                  <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7 10.332V10.3373" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.99935 8.00076C6.98707 7.78434 7.04545 7.5698 7.16569 7.38944C7.28593 7.20908 7.46152 7.07267 7.66602 7.00076C7.9166 6.90494 8.14152 6.75225 8.32306 6.55473C8.50461 6.35721 8.63783 6.12024 8.71223 5.86249C8.78663 5.60473 8.80019 5.33322 8.75183 5.06934C8.70348 4.80545 8.59453 4.55639 8.43356 4.34177C8.27259 4.12715 8.064 3.95282 7.82421 3.83251C7.58442 3.7122 7.31998 3.64919 7.0517 3.64844C6.78342 3.6477 6.51863 3.70924 6.27818 3.82822C6.03773 3.9472 5.82817 4.12037 5.66602 4.33409" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                </svg>
              </div>
            </Tippy>
            <div className='h-2/3 flex justify-center items-center bg-lightPink'>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2449_10287)">
                <path d="M25.999 18C25.999 17.4696 26.2097 16.9609 26.5848 16.5858C26.9599 16.2107 27.4686 16 27.999 16H39.999C40.5295 16 41.0382 16.2107 41.4132 16.5858C41.7883 16.9609 41.999 17.4696 41.999 18V38C41.999 38.5304 41.7883 39.0391 41.4132 39.4142C41.0382 39.7893 40.5295 40 39.999 40H27.999C27.4686 40 26.9599 39.7893 26.5848 39.4142C26.2097 39.0391 25.999 38.5304 25.999 38V18Z" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M35.999 16V10C35.999 9.46957 35.7883 8.96086 35.4132 8.58579C35.0382 8.21071 34.5295 8 33.999 8H7.99902C7.46859 8 6.95988 8.21071 6.58481 8.58579C6.20974 8.96086 5.99902 9.46957 5.99902 10V34C5.99902 34.5304 6.20974 35.0391 6.58481 35.4142C6.95988 35.7893 7.46859 36 7.99902 36H25.999" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M31.999 18H35.999" stroke="#FF4A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_2449_10287">
                <rect width="48" height="48" fill="white" transform="translate(-0.000976562)"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <div className='h-1/3 pt-1'>
              <p className="flex pl-5 text-strongSlate text-[13px] font-extrabold font-['Manrope']">User Testing</p>
              <p className="flex pl-5 text-strongSlate text-xs font-normal font-['Manrope']">Users test design</p>
            </div>
          </button>

          <button className={`relative w-[165px] h-[135px] ml-[18px] bg-white rounded-lg ${method === 'focus_group' ? 'border-4 border-pink' : 'border-2 border-grey-200'}`} onClick={() => setMethod('focus_group')}>
          <Tippy
              interactive={true}
              maxWidth={350}
              content={
                <div className='p-2 w-[300px] text-left'>
                  <p className='font-bold'>Focus Groups</p>
                  <p>Focus Groups are facilitated discussions with a small group of users where you can explore their perceptions, opinions, and attitudes towards your product or concept.</p>
                  <a className='text-linkBlue font-semibold' href='' onClick={(e) => e.preventDefault()} alt=''>Learn More</a>
                </div>
              }
            >
              <div className='absolute right-2 top-2'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.25">
                  <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7 10.332V10.3373" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.99935 8.00076C6.98707 7.78434 7.04545 7.5698 7.16569 7.38944C7.28593 7.20908 7.46152 7.07267 7.66602 7.00076C7.9166 6.90494 8.14152 6.75225 8.32306 6.55473C8.50461 6.35721 8.63783 6.12024 8.71223 5.86249C8.78663 5.60473 8.80019 5.33322 8.75183 5.06934C8.70348 4.80545 8.59453 4.55639 8.43356 4.34177C8.27259 4.12715 8.064 3.95282 7.82421 3.83251C7.58442 3.7122 7.31998 3.64919 7.0517 3.64844C6.78342 3.6477 6.51863 3.70924 6.27818 3.82822C6.03773 3.9472 5.82817 4.12037 5.66602 4.33409" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                </svg>
              </div>
            </Tippy>
            <div className='h-2/3 flex justify-center items-center bg-sky'>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2449_10279)">
                <path d="M20.001 26C20.001 27.0609 20.4224 28.0783 21.1726 28.8284C21.9227 29.5786 22.9401 30 24.001 30C25.0618 30 26.0793 29.5786 26.8294 28.8284C27.5795 28.0783 28.001 27.0609 28.001 26C28.001 24.9391 27.5795 23.9217 26.8294 23.1716C26.0793 22.4214 25.0618 22 24.001 22C22.9401 22 21.9227 22.4214 21.1726 23.1716C20.4224 23.9217 20.001 24.9391 20.001 26Z" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.001 42V40C16.001 38.9391 16.4224 37.9217 17.1726 37.1716C17.9227 36.4214 18.9401 36 20.001 36H28.001C29.0618 36 30.0793 36.4214 30.8294 37.1716C31.5795 37.9217 32.001 38.9391 32.001 40V42" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M30.001 10C30.001 11.0609 30.4224 12.0783 31.1726 12.8284C31.9227 13.5786 32.9401 14 34.001 14C35.0618 14 36.0793 13.5786 36.8294 12.8284C37.5795 12.0783 38.001 11.0609 38.001 10C38.001 8.93913 37.5795 7.92172 36.8294 7.17157C36.0793 6.42143 35.0618 6 34.001 6C32.9401 6 31.9227 6.42143 31.1726 7.17157C30.4224 7.92172 30.001 8.93913 30.001 10Z" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M34.001 20H38.001C39.0618 20 40.0793 20.4214 40.8294 21.1716C41.5795 21.9217 42.001 22.9391 42.001 24V26" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.001 10C10.001 11.0609 10.4224 12.0783 11.1725 12.8284C11.9227 13.5786 12.9401 14 14.001 14C15.0618 14 16.0793 13.5786 16.8294 12.8284C17.5795 12.0783 18.001 11.0609 18.001 10C18.001 8.93913 17.5795 7.92172 16.8294 7.17157C16.0793 6.42143 15.0618 6 14.001 6C12.9401 6 11.9227 6.42143 11.1725 7.17157C10.4224 7.92172 10.001 8.93913 10.001 10Z" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.00098 26V24C6.00098 22.9391 6.4224 21.9217 7.17255 21.1716C7.92269 20.4214 8.94011 20 10.001 20H14.001" stroke="#34AED7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_2449_10279">
                <rect width="48" height="48" fill="white" transform="translate(0.000976562)"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <div className='h-1/3 pt-1'>
              <p className="flex pl-3 text-strongSlate text-[13px] font-extrabold font-['Manrope']">Focus Group</p>
              <p className="flex pl-3 text-strongSlate text-xs font-normal font-['Manrope']">Chat with groups</p>
            </div>
          </button>

          <button className={`relative w-[165px] h-[135px] ml-[18px] bg-white rounded-lg ${method === 'mixed_methods' ? 'border-4 border-pink' : 'border-2 border-grey-200'}`} onClick={() => setMethod('mixed_methods')}>
            <Tippy
              interactive={true}
              maxWidth={350}
              content={
                <div className='p-2 w-[300px] text-left'>
                  <p className='font-bold'>Mixed Methods</p>
                  <p>Mixed Methods combine different research methodologies, such as interviews, testing, and focus groups, to provide a comprehensive understanding of user experiences and behaviors.</p>
                  <a className='text-linkBlue font-semibold' href='' onClick={(e) => e.preventDefault()} alt=''>Learn More</a>
                </div>
              }
            >
              <div className='absolute right-2 top-2'>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.25">
                  <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7 10.332V10.3373" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.99935 8.00076C6.98707 7.78434 7.04545 7.5698 7.16569 7.38944C7.28593 7.20908 7.46152 7.07267 7.66602 7.00076C7.9166 6.90494 8.14152 6.75225 8.32306 6.55473C8.50461 6.35721 8.63783 6.12024 8.71223 5.86249C8.78663 5.60473 8.80019 5.33322 8.75183 5.06934C8.70348 4.80545 8.59453 4.55639 8.43356 4.34177C8.27259 4.12715 8.064 3.95282 7.82421 3.83251C7.58442 3.7122 7.31998 3.64919 7.0517 3.64844C6.78342 3.6477 6.51863 3.70924 6.27818 3.82822C6.03773 3.9472 5.82817 4.12037 5.66602 4.33409" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                </svg>
              </div>
            </Tippy>
            <div className='h-2/3 flex justify-center items-center bg-amber'>
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_2449_10296)">
                <path d="M36 8L42 14L36 20" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M36 40L42 34L36 28" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6 14H12C14.6522 14 17.1957 15.0536 19.0711 16.9289C20.9464 18.8043 22 21.3478 22 24C22 26.6522 23.0536 29.1957 24.9289 31.0711C26.8043 32.9464 29.3478 34 32 34H42" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M42 14H32C29.8358 13.9968 27.7295 14.6989 26 16M18 32C16.2701 33.3003 14.1641 34.0023 12 34H6" stroke="#D1EC26" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_2449_10296">
                <rect width="48" height="48" fill="white"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <div className='h-1/3 pt-1'>
              <p className="flex pl-5 text-strongSlate text-[13px] font-extrabold font-['Manrope']">Mixed Methods</p>
              <p className="flex pl-5 text-strongSlate text-xs font-normal font-['Manrope']">Multiple methods</p>
            </div>
          </button>
        </div>
      </div>
      <div className='mt-[10px] flex items-center'>
        <button className="flex h-10 mx-2 font-bold px-4 rounded border items-center bg-pink text-white shadow" onClick={() => handleCreateProject(projectName, projectDescription, method)}>
            <p className="text-white text-base font-semibold font-['Manrope']">Create Project</p>
        </button>
        <button className="flex h-10 mx-2 font-bold px-4 rounded border items-center bg-white text-pink" onClick={() => handleClose(true)}>
          <p className="text-pink-500 text-base font-semibold font-['Manrope']">Cancel</p>
        </button>
      </div>
    </>
  )
}

CreateProject.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleCreateProject: PropTypes.func.isRequired
}
