/* eslint-disable react/no-unknown-property */
import React from 'react'
import NoEvents from '../../../assets/no_upcoming_events.png'
import PropTypes from 'prop-types'
import { Event } from './event'

function Loader() {
  return (
    <div className="flex justify-center items-center">
      <svg className="w-40 h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
          <stop offset="0" stopColor="#FF156D"></stop>
          <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
          <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
          <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
          <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
        </radialGradient>
        <circle
          transform-origin="center"
          fill="none"
          stroke="url(#a1)"
          strokeWidth="15"
          strokeLinecap="round"
          strokeDasharray="200 1000"
          strokeDashoffset="0"
          cx="100"
          cy="100"
          r="70"
        >
          <animateTransform
            type="rotate"
            attributeName="transform"
            calcMode="spline"
            dur="2"
            values="360;0"
            keyTimes="0;1"
            keySplines="0 0 1 1"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
        <circle
          transform-origin="center"
          fill="none"
          opacity=".2"
          stroke="#FF156D"
          strokeWidth="15"
          strokeLinecap="round"
          cx="100"
          cy="100"
          r="70"
        ></circle>
      </svg>
    </div>
  )
}

export function Events({ limit, events, eventsLoading }) {
  return (
    <>
      <div className="mt-12">
        <div className="rounded-2xl shadow pt-2 pb-3" style={{ width: '95%', backgroundColor: '#fdfdfd' }}>
          {eventsLoading ? (
            <Loader />
          ) : events.length > 0 ? (
            <>
              <div className="p-8">
                <div className="text-zinc text-[1.625rem] font-semibold font-['Manrope']">Upcoming interviews</div>
                <div className="my-[14px] grid grid-rows-2 grid-flow-col gap-[17px] items-center overflow-x-auto">
                  {events.slice(0, limit).map((event) => (
                    <Event key={event.id} event={event} />
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-between items-center my-6">
              <div className="ml-9">
                <div className="text-zinc text-[1.625rem] font-semibold font-['Manrope']">Upcoming interviews</div>
                <p className="w-[420px] mt-6 text-grey-500 text-base font-normal font-['Manrope'] leading-[23px]">
                  The
                  <span className="text-grey-500 text-base font-bold font-['Manrope'] leading-[23px]">
                    {' '}
                    Usermuse Bot{' '}
                  </span>
                  has not been invited to any user meetings yet. Simply invite him at:
                  <span className="text-blue text-base font-bold font-['Manrope'] leading-[23px]">
                    {' '}
                    invite@usermuse.co{' '}
                  </span>
                  (Or use a project-specific email to group sessions.)
                </p>
              </div>
              <img className="mr-10" src={NoEvents} alt="No upcoming interviews" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

Events.propTypes = {
  limit: PropTypes.number,
  events: PropTypes.array,
  eventsLoading: PropTypes.bool,
}
