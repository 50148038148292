import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { DisconnectDialog } from './disconnectDialog'

export function Card ({ connected, logo, name, description, handleConnect, handleDisconnect }) {
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false)

  return (
      <div className='w-[225px] h-[303px] bg-white p-[16.37px] rounded border border-[#e4e4e4] flex flex-col justify-between gap-[14px]'>
        <div className='p-[16.37px] flex justify-center items-center'>
          {logo}
        </div>

        <p className="text-[#3f424a] text-sm font-semibold font-['Manrope'] leading-[17.50px]">{name}</p>

        <p className="text-[#62708d] text-xs font-medium font-['Manrope'] leading-[15px]">{description}</p>

        {connected
          ? (
          <div className='w-full h-[26px] flex justify-between items-center'>
            <div className='py-[3px] pl-[4px] pr-[6px] bg-white flex items-center justify-center gap-[3px]' style={{ boxShadow: '0px 0px 22.399999618530273px rgba(14.96, 21.41, 55.25, 0.05)', borderRadius: 30, overflow: 'hidden', border: '1px #E4E4E4 solid' }}>
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11.1541" cy="11.4134" r="9.21611" fill="white"/>
                <path d="M15.7621 3.43212C17.1522 4.23471 18.3085 5.38642 19.1166 6.77324C19.9248 8.16006 20.3567 9.73389 20.3697 11.3389C20.3826 12.944 19.9762 14.5246 19.1906 15.9243C18.4049 17.324 17.2674 18.4942 15.8905 19.3191C14.5136 20.1441 12.9451 20.5951 11.3403 20.6276C9.73558 20.6601 8.15015 20.2729 6.74101 19.5043C5.33187 18.7358 4.14789 17.6125 3.30627 16.2458C2.46465 14.879 1.99458 13.3161 1.9426 11.7119L1.93799 11.4133L1.9426 11.1147C1.99421 9.52304 2.45736 7.97194 3.28689 6.6126C4.11643 5.25325 5.28404 4.13204 6.67589 3.35829C8.06774 2.58453 9.63633 2.18463 11.2287 2.19757C12.8212 2.21051 14.3831 2.63585 15.7621 3.43212ZM14.5705 8.91847C14.4118 8.75979 14.2007 8.66447 13.9767 8.65039C13.7527 8.6363 13.5313 8.70443 13.354 8.84198L13.2673 8.91847L10.2325 11.9524L9.04084 10.7617L8.95421 10.6852C8.77688 10.5477 8.55549 10.4797 8.33157 10.4938C8.10765 10.508 7.89657 10.6033 7.73792 10.7619C7.57927 10.9206 7.48395 11.1317 7.46982 11.3556C7.45569 11.5795 7.52374 11.8009 7.66119 11.9782L7.73768 12.0648L9.5809 13.9081L9.66753 13.9846C9.82916 14.11 10.0279 14.178 10.2325 14.178C10.437 14.178 10.6358 14.11 10.7974 13.9846L10.8841 13.9081L14.5705 10.2216L14.647 10.135C14.7845 9.95768 14.8527 9.73626 14.8386 9.51228C14.8245 9.28831 14.7292 9.07717 14.5705 8.91847Z" fill="#1FB912"/>
              </svg>

              <p className="text-[#3f424a] text-[10px] font-bold font-['Manrope'] leading-3">Connected</p>
            </div>

            <button className='py-[3px] pl-[4px] pr-[6px] bg-white flex items-center justify-center gap-[3px]' style={{ boxShadow: '0px 0px 22.399999618530273px rgba(14.96, 21.41, 55.25, 0.05)', borderRadius: 30, overflow: 'hidden', border: '1px #E4E4E4 solid' }} onClick={() => setShowDisconnectDialog(true)}>
              <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.7041 6.46289H17.0374" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.7041 9.79492V14.7949" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.0371 9.79492V14.7949" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.53711 6.46289L5.37044 16.4629C5.37044 16.9049 5.54604 17.3288 5.8586 17.6414C6.17116 17.954 6.59508 18.1296 7.03711 18.1296H13.7038C14.1458 18.1296 14.5697 17.954 14.8823 17.6414C15.1948 17.3288 15.3704 16.9049 15.3704 16.4629L16.2038 6.46289" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.87061 6.46224V3.96224C7.87061 3.74123 7.9584 3.52926 8.11468 3.37298C8.27096 3.2167 8.48293 3.12891 8.70394 3.12891H12.0373C12.2583 3.12891 12.4702 3.2167 12.6265 3.37298C12.7828 3.52926 12.8706 3.74123 12.8706 3.96224V6.46224" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <p className="text-[#3f424a] text-[10px] font-bold font-['Manrope'] leading-3">Disconnect</p>
            </button>
            {showDisconnectDialog && createPortal(
              <DisconnectDialog name={name} handleClose={() => setShowDisconnectDialog(false)} handleDisconnect={handleDisconnect} />,
              document.body
            )}
          </div>
            )
          : (
          <button className='w-full h-[29px] p-[16.37px] bg-white flex flex-col justify-center items-center' style={{ boxShadow: '0px 0px 18.334714889526367px rgba(147.93, 159.11, 217.81, 0.05)', borderRadius: 6.71, border: '0.84px #E4E4E4 solid' }} onClick={handleConnect}>
            <p className="text-[#3f424a] text-[13px] font-semibold font-['Manrope'] leading-none">Connect</p>
          </button>
            )}
      </div>
  )
}

Card.propTypes = {
  connected: PropTypes.bool,
  logo: PropTypes.node,
  name: PropTypes.string,
  description: PropTypes.string,
  handleConnect: PropTypes.func,
  handleDisconnect: PropTypes.func
}
