import React, { useContext, useEffect, useRef, useState } from 'react'
import { ProjectContext } from '../../../../context/ProjectContext'
import { truncateText } from '../../../../utils'

export function MeetingsFilter({ filters, setFilters }) {
  const { meetings } = useContext(ProjectContext)
  const [isOpen, setIsOpen] = useState(false)
  const meetingFilterRef = useRef(null)

  // Handle outside clicks
  useEffect(() => {
    function handleClickOutside(event) {
      if (meetingFilterRef.current && !meetingFilterRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const handleSelect = (option) => {
    if (filters.meetings.includes(option)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        meetings: prevFilters.meetings.filter((item) => item !== option),
      }))
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, meetings: [...prevFilters.meetings, option] }))
    }
  }

  return (
    <div className="relative w-1/2 border-r border-[#e4e4e4] flex justify-center items-center" ref={meetingFilterRef}>
      <button className="px-2 py-3.5 flex justify-center items-center gap-2" onClick={() => setIsOpen(!isOpen)}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3498_24376)">
            <path
              d="M12.8887 8.61932L16.6828 6.72265C16.8099 6.65918 16.951 6.62922 17.0928 6.63561C17.2347 6.642 17.3725 6.68453 17.4933 6.75916C17.6141 6.83379 17.7139 6.93805 17.7831 7.06205C17.8522 7.18604 17.8886 7.32566 17.8887 7.46765V13.1043C17.8886 13.2463 17.8522 13.3859 17.7831 13.5099C17.7139 13.6339 17.6141 13.7382 17.4933 13.8128C17.3725 13.8874 17.2347 13.93 17.0928 13.9364C16.951 13.9428 16.8099 13.9128 16.6828 13.8493L12.8887 11.9527V8.61932Z"
              stroke="#40424A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.88867 6.95182C2.88867 6.5098 3.06427 6.08587 3.37683 5.77331C3.68939 5.46075 4.11331 5.28516 4.55534 5.28516H11.222C11.664 5.28516 12.088 5.46075 12.4005 5.77331C12.7131 6.08587 12.8887 6.5098 12.8887 6.95182V13.6185C12.8887 14.0605 12.7131 14.4844 12.4005 14.797C12.088 15.1096 11.664 15.2852 11.222 15.2852H4.55534C4.11331 15.2852 3.68939 15.1096 3.37683 14.797C3.06427 14.4844 2.88867 14.0605 2.88867 13.6185V6.95182Z"
              stroke="#40424A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3498_24376">
              <rect width="20" height="20" fill="white" transform="translate(0.388672 0.285156)" />
            </clipPath>
          </defs>
        </svg>
        <p className="whitespace-nowrap text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight">
          {!filters.meetings.length
            ? 'Any Meetings'
            : filters.meetings.length === 1
              ? truncateText(meetings.find((meet) => meet.id === filters.meetings[0]).name, 21)
              : filters.meetings.length + ' Selected'}
        </p>
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3498_24364)">
            <circle cx="10.4925" cy="10.2835" r="7.27964" fill="#EBEBEB" />
            <path
              d="M12.9195 9.47656L10.493 11.9031L8.06641 9.47656"
              stroke="#40424A"
              strokeWidth="1.3866"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3498_24364">
              <rect width="19.4124" height="19.4124" fill="white" transform="translate(0.787109 0.578125)" />
            </clipPath>
          </defs>
        </svg>
      </button>

      {isOpen && (
        <div className="absolute top-10 z-10 mt-2 min-w-56 max-w-[350px] overflow-hidden rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {meetings.map((meet) => (
              <div
                key={meet.id}
                className="flex items-center px-4 py-2 cursor-pointer hover:bg-grey-100"
                onClick={() => handleSelect(meet.id)}
              >
                <input
                  type="checkbox"
                  className="form-checkbox min-h-5 min-w-5 text-blue-600"
                  checked={filters.meetings.includes(meet.id)}
                  readOnly
                />
                <span className="whitespace-nowrap ml-2 text-gray-700">{meet.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
