import React, { useEffect, useState } from 'react'
import { collection, getDocs, limit, query } from 'firebase/firestore'
import { db, storage } from '../../firebase'
import { getDownloadURL, ref } from 'firebase/storage'

export function Testimonial () {
  const [testimonial, setTestimonial] = useState({
    photo: '',
    name: '',
    quote: '',
    job: ''
  })
  
  async function fetchTestimonial () {
    try {
      const q = query(collection(db, 'testimonials'), limit(1))
      const querySnapshot = await getDocs(q)

      if (querySnapshot.empty) return

      const firstDoc = querySnapshot.docs[0]
      console.log('data', firstDoc.data())

      let photoUrl = ''
      try {
        const storageRef = ref(storage, `testimonials/${firstDoc.data().image}`)
        photoUrl = await getDownloadURL(storageRef)
      } catch (error) {
        return console.error('Error getting quote thumbnail URL:', error)
      }

      setTestimonial({
        photo: photoUrl,
        name: firstDoc.data().name,
        quote: firstDoc.data().quote,
        job: firstDoc.data().job
      })

    } catch (error) {
      console.error('Error fetching:', error)
    }
  }

  useEffect(() => {
    fetchTestimonial()
  }, [])

  if (!testimonial.photo || !testimonial.name || !testimonial.quote) return null

  return (
    <div className="w-[496px] h-auto bg-white rounded-2xl pl-[39px] py-[39px]" style={{ boxShadow: '0px 0px 22.399999618530273px rgba(147.93, 159.11, 217.81, 0.05)' }}>
      <div className='flex flex-col gap-[30px]'>
        <div className='flex items-center gap-[24px]'>
          <img className="w-[70px] h-[70px] rounded-full" src={testimonial.photo} />
          <div className='flex flex-col'>
            <p className="text-[#3f424a] text-[22px] font-medium font-['DM_Sans'] leading-relaxed">{testimonial.name}</p>
            {testimonial.job && <p className="text-[#62708d] text-lg font-normal font-['DM_Sans'] leading-[23px]">{testimonial.job}</p>}
          </div>
        </div>
        <p className="text-[#62708d] text-lg font-normal font-['DM_Sans'] leading-[23px]">{testimonial.quote}</p>
      </div>
    </div>
  )
}
