import React from 'react'

const OverviewIcon = ({ checked }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3585_5560)">
        <path
          d="M8.33203 10.8327C8.33203 11.2747 8.50763 11.6986 8.82019 12.0112C9.13275 12.3238 9.55667 12.4993 9.9987 12.4993C10.4407 12.4993 10.8646 12.3238 11.1772 12.0112C11.4898 11.6986 11.6654 11.2747 11.6654 10.8327C11.6654 10.3907 11.4898 9.96673 11.1772 9.65417C10.8646 9.34161 10.4407 9.16602 9.9987 9.16602C9.55667 9.16602 9.13275 9.34161 8.82019 9.65417C8.50763 9.96673 8.33203 10.3907 8.33203 10.8327Z"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.207 9.62435L12.9154 7.91602"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33333 16.6662C4.11512 15.698 3.22826 14.3747 2.79565 12.8799C2.36304 11.3851 2.4061 9.7928 2.91887 8.32356C3.43164 6.85433 4.38873 5.58098 5.65749 4.67997C6.92625 3.77897 8.44386 3.29492 10 3.29492C11.5561 3.29492 13.0737 3.77897 14.3425 4.67997C15.6113 5.58098 16.5684 6.85433 17.0811 8.32356C17.5939 9.7928 17.637 11.3851 17.2044 12.8799C16.7717 14.3747 15.8849 15.698 14.6667 16.6662H5.33333Z"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3585_5560">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OverviewIcon
