import React from 'react'

export const CloudImportIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2344_8604)">
        <path
          d="M18.6665 47.9992C15.3393 47.9992 12.1483 46.7349 9.79557 44.4845C7.44284 42.2341 6.12109 39.1818 6.12109 35.9992C6.12109 32.8166 7.44284 29.7644 9.79557 27.5139C12.1483 25.2635 15.3393 23.9992 18.6665 23.9992C19.4524 20.4984 21.7513 17.4218 25.0574 15.4465C26.6945 14.4683 28.5296 13.79 30.4579 13.4502C32.3863 13.1104 34.3701 13.1157 36.2962 13.4659C38.2222 13.8161 40.0528 14.5043 41.6833 15.4912C43.3139 16.4781 44.7125 17.7443 45.7992 19.2177C46.886 20.691 47.6397 22.3426 48.0173 24.0781C48.3949 25.8137 48.389 27.5991 47.9999 29.3326H50.6665C53.1419 29.3326 55.5159 30.3159 57.2662 32.0662C59.0166 33.8166 59.9999 36.1905 59.9999 38.6659C59.9999 41.1412 59.0166 43.5152 57.2662 45.2655C55.5159 47.0159 53.1419 47.9992 50.6665 47.9992H47.9999"
          stroke="#62718D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M24 40L32 32L40 40" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M32 32V56" stroke="#62718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g filter="url(#filter0_d_2344_8604)">
        <g filter="url(#filter1_d_2344_8604)">
          <rect x="39.166" y="39.7168" width="14.8515" height="16.5432" rx="7.42573" fill="#FF001F" />
        </g>
        <ellipse cx="46.5918" cy="47.9893" rx="3.71286" ry="4.1358" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_2344_8604"
          x="36.166"
          y="38.7168"
          width="18.8516"
          height="20.543"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2344_8604" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2344_8604" result="shape" />
        </filter>
        <filter
          id="filter1_d_2344_8604"
          x="38.3299"
          y="39.4381"
          width="15.9664"
          height="17.6578"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.278716" dy="0.278716" />
          <feGaussianBlur stdDeviation="0.278716" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2344_8604" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2344_8604" result="shape" />
        </filter>
        <clipPath id="clip0_2344_8604">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
