import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { Notes } from './notes'
import { StarsFilter } from './filters/stars'
import { CategoriesFilter } from './filters//categories'
// import { TagsFilter } from './filters/tags'

export function Needs () {
  const { filteredNotes } = useContext(MeetingsContext)

  return (
    <>
      <div className="w-full h-12 bg-white rounded-[10px] flex item-center" style={{ boxShadow: '0px 0px 16px 8px rgba(0, 0, 0, 0.15)' }}>
        <StarsFilter />
        <CategoriesFilter />
        {/* <TagsFilter /> */}
      </div>

      <div className='ml-[22px]'>
        <Notes notes={filteredNotes} />
      </div>
    </>
  )
}
