import { useEffect } from 'react'

function useOutsideClick (ref, callback) {
  useEffect(() => {
    function handleClickOutside (event) {
      if(ref instanceof Array){
        if (ref.every(refItem => refItem.current && !refItem.current.contains(event.target))) {
          callback()
        }
      } else {
        if (ref.current && !ref.current.contains(event.target)) {
          callback()
        }
      }

    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}

export default useOutsideClick
