import React, { useContext } from 'react'
import { MeetingsContext } from '../../../context/MeetingContext'
import { formatDateHMMSS } from '../../../utils/formatDate'
import PropTypes from 'prop-types'

export function ProblemTooltip({ id, content, participantId, whoSaidIt, startTime, referent }) {
  const { setScrollToProblem, participants } = useContext(MeetingsContext)

  return (
    <div className="p-2 w-[300px] text-left flex flex-col gap-[6px]">
      <p className="font-bold text-[16px] font-['Manrope'] capitalize">{referent}</p>
      <p className="font-normal text-[14px] font-['Manrope']">{content}</p>
      <div className="flex items-center">
        <p className="font-semibold text-[14px] font-['Manrope']">
          {participantId ? participants[participantId]?.name : whoSaidIt} @ {formatDateHMMSS(startTime)}・
        </p>
        <button
          className="text-linkBlue font-semibold text-[14px] font-['Manrope']"
          onClick={() => setScrollToProblem(id)}
        >
          See Pain Point ❯
        </button>
      </div>
    </div>
  )
}

ProblemTooltip.propTypes = {
  id: PropTypes.string,
  content: PropTypes.string,
  participantId: PropTypes.string,
  whoSaidIt: PropTypes.string,
  startTime: PropTypes.number,
}
