import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getDayAndMonth, getHour, getMeetingProvider } from '../../../../utils'
import { MeetingProvider } from '../../../utils/meetingProvider'

export function Event ({ event }) {
  const provider = useMemo(() => getMeetingProvider(event.meetingUrl), [event.meetingUrl])

  return (
    <div key={event.id} className="w-[365px] h-[84.82px] p-4 bg-white rounded-lg shadow border border-[#FF4A8E] justify-start items-center gap-4 inline-flex">
      <div className='relative'>
        <svg width="58" height="56" viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_3851_3223)">
          <rect x="5.47266" y="4.63672" width="46.2774" height="40.3636" rx="3.5" fill="white" stroke="#E4E4E4"/>
          <rect x="10.8828" y="36.252" width="9.22819" height="5.19034" rx="1" fill="#E4E4E4"/>
          <rect x="10.8828" y="28.4668" width="9.22819" height="5.19034" rx="1" fill="#E4E4E4"/>
          <rect x="23.4062" y="36.252" width="9.22819" height="5.19034" rx="1" fill="#E4E4E4"/>
          <rect x="23.4062" y="28.4668" width="9.22819" height="5.19034" rx="1" fill="#FF4A8E"/>
          <rect x="23.4062" y="20.6816" width="9.22819" height="5.19034" rx="1" fill="#E4E4E4"/>
          <rect x="35.9316" y="28.4668" width="9.22818" height="5.19034" rx="1" fill="#E4E4E4"/>
          <rect x="35.9316" y="20.6816" width="9.22818" height="5.19034" rx="1" fill="#E4E4E4"/>
          <path d="M4.97266 8.13672C4.97266 5.92758 6.76352 4.13672 8.97266 4.13672H48.2501C50.4592 4.13672 52.2501 5.92758 52.2501 8.13672V16.5458H4.97266V8.13672Z" fill="#FF4A8E"/>
          <rect x="12.5645" y="0.5" width="4.90968" height="9.34091" rx="2.45484" fill="white" stroke="#E4E4E4"/>
          <rect x="39.75" y="0.5" width="4.90968" height="9.34091" rx="2.45484" fill="white" stroke="#E4E4E4"/>
          </g>
          <defs>
          <filter id="filter0_d_3851_3223" x="0.130551" y="0" width="56.9616" height="55.1842" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4.84211"/>
          <feGaussianBlur stdDeviation="2.42105"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3851_3223"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3851_3223" result="shape"/>
          </filter>
          </defs>
        </svg>
        <div className='absolute' style={{ bottom: '2px', right: '-5px' }}>
          <MeetingProvider provider={provider} />
        </div>
      </div>
      <div>
        <p className="text-[#40424A] text-base font-semibold font-['Manrope'] leading-tight">{event.summary}</p>
        <div className="mt-[3px] flex flex-wrap gap-y-1 items-center text-[#62718D] text-[13px] font-medium font-['Manrope'] leading-none">
          {!event.start
            ? (
              <>
                <p>{getDayAndMonth(new Date(event.created_at))}・</p>
                <p>{getHour(new Date(event.created_at))}</p>
                <p>{event.end ? <span>&nbsp;- {getHour(new Date(event.end))}</span> : ''}</p>
              </>
              )
            : (
              <>
                <p>{getDayAndMonth(new Date(event.start))}・</p>
                <p>{getHour(new Date(event.start))}</p>
                <p>{event.end ? <span>&nbsp;- {getHour(new Date(event.end))}</span> : ''}</p>
              </>
              )
          }
          </div>
      </div>
    </div>
  )
}

Event.propTypes = {
  event: PropTypes.object.isRequired
}
