/* eslint-disable react/no-unknown-property */
import React, { useContext } from 'react'
import { Event } from './event'
import { DashboardContext } from '../../../context/DashboardContext'
// import NoEvents from '../../../assets/no_upcoming_events.png'

export function EventsWrap () {
  const { events, eventsLoading } = useContext(DashboardContext)

  return (
    <div className='my-[37px]'>
    {!eventsLoading && events.length > 0 && (
      <>
        <p className="text-[#62708d] text-lg font-bold font-['Manrope'] leading-tight tracking-wide">Upcoming Meetings</p>
        <div className='my-[14px] flex items-center flex-wrap gap-4'>
            {events.map(event => <Event key={event.id} event={event} />)}
        </div>
      </>
    )}
    </div>
  )
}
