import React from 'react'

export function Dropdown({ options, value, onChange, icon }) {
  return (
    <div className="relative">
      {icon && <div className="absolute left-0 top-[2px]">{icon}</div>}
      <select
        className="pl-6 w-full rounded text-[#44566C] text-lg font-bold font-['Manrope'] leading-tight tracking-wide cursor-pointer"
        onChange={onChange}
        value={value}
      >
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    </div>
  )
}