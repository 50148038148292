import { json2csv } from './json2csv'
import { exportcsv } from './exportcsv'
import { getMeetingProvider } from './getMeetingProvider'
import { getDayAndMonth, getHour, formatFileDuration, timeAgo } from './formatDate'

const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.slice(0, limit) + '...'
  }
  return text
}

export {
  json2csv,
  exportcsv,
  getMeetingProvider,
  getDayAndMonth,
  getHour,
  formatFileDuration,
  timeAgo,
  truncateText,
}
