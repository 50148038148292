import React, { useContext } from 'react'
import { GridByMeeting } from './groupByMeeting'
import { GridByCategory } from './groupByCategory'
import PropTypes from 'prop-types'
import { ProjectContext } from '../../../context/ProjectContext'
import { DeleteNotesDialog } from '../needs/deleteNotesDialog'
import { createPortal } from 'react-dom'

export function StickyGrid ({ groupNotesBy }) {
  const { showDeleteNotesDialog, setShowDeleteNotesDialog, handleDeleteNotes, selectedNotes } = useContext(ProjectContext)

  return (
    <>
        {groupNotesBy === 'meeting' ? <GridByMeeting /> : <GridByCategory />}

        {showDeleteNotesDialog &&
          createPortal(
            <DeleteNotesDialog
              handleDeleteNotes={handleDeleteNotes}
              notesCount={selectedNotes.length}
              handleClose={() => setShowDeleteNotesDialog(false)}
            />,
            document.body
          )}
    </>
  )
}

StickyGrid.propTypes = {
  groupNotesBy: PropTypes.string
}
