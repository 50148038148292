import React, { useCallback, useContext, useRef, useState } from 'react'
import { useCustomFetch } from '../../hooks/useCustomFetch'
import { ProjectContext } from '../../context/ProjectContext'
import toast from 'react-hot-toast'
import cnt from '../../constants'
import useOutsideClick from '../../hooks/useOutsideClick'

export function NotesInput () {
  const {
    project,
    meetings,
    setMeetings,
    setNotesInputRef,
    selectedMeeting,
    selectedCategory,
    groupNotesBy
  } = useContext(ProjectContext)
  const [noteText, setNoteText] = useState('')
  const [color, setColor] = useState(cnt.default_notes_color)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)
  const colorPickerRef = useRef()
  const customFetch = useCustomFetch()

  const setContainerRef = useCallback((node) => {
    if (node !== null) {
      setNotesInputRef(node)
    }
  }, [setNotesInputRef])

  useOutsideClick(colorPickerRef, () => {
    if (showColorPicker) {
      setShowColorPicker(false)
    }
  })

  async function handleCreateNote () {
    try {
      if (!noteText) {
        toast.error('Note content is required', { duration: 2000 })
        return
      }
      setNoteText('')
      const data = {
        projectId: project.id,
        content: noteText,
        color,
        meetingId: (groupNotesBy === 'meeting' && selectedMeeting) ? selectedMeeting : null,
        categoryId: (groupNotesBy === 'category' && selectedCategory) ? selectedCategory : null
      }
      await customFetch('/createNote', 'POST', data)
      if (groupNotesBy === 'meeting' && selectedMeeting) {
        const index = meetings.findIndex(meet => meet.id === selectedMeeting)
        if (meetings[index].isEmpty) {
          setMeetings(prevMeetings => prevMeetings.map(meet => meet.id === selectedMeeting ? { ...meet, isEmpty: undefined } : meet))
        }
      }
      // Not doing setNotes because I am reading them in real time.
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="flex items-center p-5 bg-white shadow border border-grey-200">
      <div className={`grow h-full py-[10px] px-7 bg-grey-200 rounded-[100px] shadow-inner flex items-center ${hasFocus ? 'border-2 border-black' : 'border border-grey-200'}`}>
        <div className="ml-4 relative">
          <button onClick={() => setShowColorPicker(true)}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18" cy="18" r="16.5" fill={color} stroke="#62718D" strokeWidth="3"/>
            </svg>
          </button>
          {showColorPicker && (
            <div ref={colorPickerRef} style={{ bottom: 'calc(100% + 42px)', left: 'calc(0% - 28px - 16px)' }} className="absolute">
              <div className="w-[327.35px] h-11 px-[5px] py-2.5 bg-white rounded-[30px] shadow border-t border-[#E4E4E4] flex justify-center items-center gap-[5.67px]">
                {Object.values(cnt.colors).map(c => (
                  <button key={c} onClick={() => setColor(c)} className='relative w-[24px] h-[24px]'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="11.6453" fill={c} stroke="#A4ABB6" strokeWidth="0.709302"/>
                    </svg>
                    {c === color && (
                      <div style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} className="w-[32px] h-[32px] absolute rounded-full border-2 border-[#40424A]" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <input
            ref={setContainerRef}
            type="text"
            className="focus:outline-none ml-4 h-10 w-full text-sm placeholder-grey-400 text-grey-400 bg-grey-200 rounded border border-grey-200"
            placeholder="Type insight to add"
            value={noteText}
            onChange={e => setNoteText(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleCreateNote()}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            required
        />
        <button className='self-end mr-[30px]' onClick={handleCreateNote}>
          <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_2458_7242)">
            <circle cx="20.5" cy="19" r="17.5" fill="#FF4A8E"/>
            <circle cx="20.5" cy="19" r="17" stroke="#E63A7B"/>
            </g>
            <g clipPath="url(#clip0_2458_7242)">
            <path d="M21 11.5V25.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M27 17.5L21 11.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 17.5L21 11.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <filter id="filter0_d_2458_7242" x="0" y="0.5" width="39" height="39" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="-1" dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2458_7242"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2458_7242" result="shape"/>
            </filter>
            <clipPath id="clip0_2458_7242">
            <rect width="24" height="24" fill="white" transform="translate(9 6.5)"/>
            </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  )
}
