import React, { useContext, useEffect, useRef, useState } from 'react'
import { MeetingsContext } from '../../../../../context/MeetingContext'

export function StarsFilter () {
  const { feedbacksFilters, setFeedbacksFilters } = useContext(MeetingsContext)
  const [isOpen, setIsOpen] = useState(false)
  const starsFilterRef = useRef(null)

  // Custom logic to handle outside clicks
  useEffect(() => {
    function handleClickOutside(event) {
      if (starsFilterRef.current && !starsFilterRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const handleSelect = (option) => {
    setFeedbacksFilters(prevFilters => ({ ...prevFilters, star: option }))
  }

  return (
    <div className='relative w-full border-r border-[#e4e4e4] flex justify-center items-center'>
      <button className="px-2 py-3.5 flex justify-center items-center gap-2" onClick={() => setIsOpen(!isOpen)}>
        {feedbacksFilters.star === true &&
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3498_24360)">
            <path d="M11.0193 15.1992C10.8736 15.1224 10.6994 15.1223 10.5535 15.1989L6.53993 17.3091C6.17311 17.502 5.74438 17.1905 5.81445 16.782L6.58117 12.3124C6.60901 12.1501 6.55514 11.9845 6.43712 11.8696L3.18593 8.7049C2.88879 8.41567 3.0526 7.91126 3.46297 7.85179L7.94872 7.20168C8.11165 7.17807 8.25251 7.07576 8.32537 6.92813L10.3323 2.86163C10.5157 2.49001 11.0456 2.49001 11.229 2.86163L13.2359 6.92813C13.3088 7.07576 13.4497 7.17807 13.6126 7.20168L18.0983 7.85179C18.5087 7.91126 18.6725 8.41567 18.3754 8.7049L15.1242 11.8696C15.0062 11.9845 14.9523 12.1501 14.9801 12.3124L15.7467 16.7809C15.8168 17.1895 15.3876 17.501 15.0208 17.3077L11.0193 15.1992Z" fill="#FFC921" stroke="#FFC921" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_3498_24360">
            <rect width="20" height="20" fill="white" transform="translate(0.787109 0.285156)"/>
            </clipPath>
            </defs>
          </svg>
        }
        <p className="text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight"></p>
        <p className="text-black text-base font-bold font-['Manrope'] leading-tight">{feedbacksFilters.star === true ? 'Starred Only' : feedbacksFilters.star === false ? 'Unstarred Only' : 'All Feedback'}</p>
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3498_24364)">
          <circle cx="10.4925" cy="10.2835" r="7.27964" fill="#EBEBEB"/>
          <path d="M12.9195 9.47656L10.493 11.9031L8.06641 9.47656" stroke="#40424A" strokeWidth="1.3866" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_3498_24364">
          <rect width="19.4124" height="19.4124" fill="white" transform="translate(0.787109 0.578125)"/>
          </clipPath>
          </defs>
        </svg>
      </button>
      
      {isOpen && (
        <div className="absolute top-10 z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" ref={starsFilterRef}>
          <div className="py-1">
            <div className="flex items-center px-4 py-2 cursor-pointer hover:bg-grey-100" onClick={() => handleSelect(true)}>
              <input
                type="radio"
                className="min-h-5 min-w-5 text-blue-600"
                checked={feedbacksFilters.star === true}
                readOnly
              />
              <span className="ml-2 text-gray-700">Starred Only</span>
            </div>
            {/* <div className="flex items-center px-4 py-2 cursor-pointer hover:bg-grey-100" onClick={() => handleSelect(false)}>
              <input
                type="radio"
                className="min-h-5 min-w-5 text-blue-600"
                checked={feedbacksFilters.star === false}
                readOnly
              />
              <span className="ml-2 text-gray-700">Unstarred Only</span>
            </div> */}
            <div className="flex items-center px-4 py-2 cursor-pointer hover:bg-grey-100" onClick={() => handleSelect('all')}>
              <input
                type="radio"
                className="min-h-5 min-w-5 text-blue-600"
                checked={feedbacksFilters.star === 'all'}
                readOnly
              />
              <span className="ml-2 text-gray-700">All Feedback</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
