import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore'
import { db, storage } from '../firebase'
import { useDebounce } from '../hooks/useDebounce'
import { useCustomFetch } from '../hooks/useCustomFetch'
import toast from 'react-hot-toast'
import { AuthContext } from './AuthContext'
import { NeedTooltip } from '../components/meeting/tooltips/needTooltip'
import { QuoteTooltip } from '../components/meeting/tooltips/quoteTooltip'
import { QATooltip } from '../components/meeting/tooltips/qaTooltip'
import { FeedbackTooltip } from '../components/meeting/tooltips/feedbackTooltip'
import cnt from '../constants'
import { getDownloadURL, ref } from 'firebase/storage'

export const MeetingsContext = createContext()

export const MeetingsContextProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const customFetch = useCustomFetch()
  const param = useParams()
  const navigate = useNavigate()
  const [meetingLoading, setMeetingLoading] = useState(true)
  const [quotesLoading, setQuotesLoading] = useState(true)
  const [meeting, setMeeting] = useState({})
  const [project, setProject] = useState({})
  const [categories, setCategories] = useState([])
  const [meetingOverview, setMeetingOverview] = useState({})
  const [participants, setParticipants] = useState({})
  const [transcripts, setTranscripts] = useState([])
  const [quotes, setQuotes] = useState([])
  const [qas, setQas] = useState([])
  const [problems, setProblems] = useState([])
  const [feedbacks, setFeedbacks] = useState([])
  const [needs, setNeeds] = useState([])
  const [url, setUrl] = useState('')
  const [thumbnail, setThumbnail] = useState('')

  const setUrlAndThumbnail = async (storedName, storedThumbnail) => {
    if (storedName) {
      try {
        const storageRef = ref(storage, `meets/${storedName}`)
        const url = await getDownloadURL(storageRef)
        setUrl(url)
      } catch (error) {
        console.error('Error getting file URL:', error)
        setUrl(null)
      }
    }
    if (storedThumbnail) {
      try {
        const storageRef = ref(storage, `thumbs/${storedThumbnail}`)
        const url = await getDownloadURL(storageRef)
        setThumbnail(url)
      } catch (error) {
        console.error('Error getting thumbnail URL:', error)
        setThumbnail(null)
      }
    }
  }

  const setQuotesWithThumbnails = async (quotes) => {
    for (let i = 0; i < quotes.length; i++) {
      if (quotes[i].thumb_stored_name) {
        try {
          const storageRef = ref(storage, `quotes/${quotes[i].thumb_stored_name}`)
          const url = await getDownloadURL(storageRef)
          quotes[i].thumbnailUrl = url
        } catch (error) {
          console.error('Error getting quote thumbnail URL:', error)
        }
      }
    }
    setQuotes(quotes)
    setQuotesLoading(false)
  }

  const initialData = async () => {
    const meetingId = param.meetingId
    const meetDb = await getDoc(doc(db, 'meetings', meetingId))
    if (!meetDb.exists()) {
      toast.error('No documents with that meeting id!')
      navigate('/dashboard/meetings')
      return
    }

    setUrlAndThumbnail(meetDb.data().stored_name, meetDb.data().thumbnail)

    const projectDb = await getDoc(doc(db, 'projects', meetDb.data().project_id))
    if (!projectDb.exists()) {
      toast.error('No project with that meeting id!')
      navigate('/dashboard/meetings')
      return
    }
    if (!Object.keys(user.workspaces).includes(projectDb.data().workspace_id)) {
      toast.error('You do not have access to this meeting')
      navigate('/dashboard/meetings')
      return
    }
    setMeeting({ ...meetDb.data(), id: meetDb.id })
    setProject({ ...projectDb.data(), id: projectDb.id })

    const meetingOverviewDb = await getDoc(doc(db, 'meetingsOverview', meetingId))
    if (!meetingOverviewDb.exists()) {
      toast.error('No overview with that meeting id!')
      navigate('/dashboard/meetings')
      return
    }
    setMeetingOverview({ ...meetingOverviewDb.data() })

    const quotes = []
    let q = query(collection(db, 'quotes'), where('meetingId', '==', meetingId), orderBy('start_time', 'asc'))
    let querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        quotes.push({ ...doc.data(), id: doc.id })
      })
      setQuotesWithThumbnails(quotes)
    } else setQuotesLoading(false)

    const qas = []
    q = query(collection(db, 'qas'), where('meetingId', '==', meetingId), orderBy('start_time', 'asc'))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        qas.push({ ...doc.data(), id: doc.id })
      })
      setQas(qas)
    }

    const feedbacks = []
    q = query(collection(db, 'feedbacks'), where('meetingId', '==', meetingId), orderBy('start_time', 'asc'))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        feedbacks.push({ ...doc.data(), id: doc.id })
      })
      setFeedbacks(feedbacks)
    }

    const problems = []
    q = query(collection(db, 'problems'), where('meetingId', '==', meetingId), orderBy('start_time', 'asc'))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        problems.push({ ...doc.data(), id: doc.id })
      })
      setProblems(problems)
    }

    const needs = []
    q = query(collection(db, 'needs'), where('meeting_id', '==', meetingId), orderBy('start_time', 'asc'))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        needs.push({ ...doc.data(), id: doc.id })
      })
      setNeeds(needs)
      console.log('settingneeds', needs.length)
    }

    q = query(collection(db, 'categories'), where('project_id', '==', projectDb.id))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const categories = []
      querySnapshot.forEach((doc) => {
        categories.push({ ...doc.data(), id: doc.id })
      })
      setCategories(categories)
    }

    const participants = {}
    q = query(collection(db, 'participants'), where('meeting_id', '==', meetingId))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        participants[doc.id] = {
          name: doc.data().name,
          role: doc.data().role,
        }
      })
      setParticipants(participants)
    }

    q = query(collection(db, 'transcripts'), where('meeting_id', '==', meetingId))
    querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const transcripts = []
      querySnapshot.forEach((doc) => {
        transcripts.push({ ...doc.data(), id: doc.id })
      })
      transcripts.sort((a, b) => {
        // Extract the first number in square brackets using a regular expression.
        const numberPattern = /\[(\d+(\.\d+)?)\]/
        const numberA = a.transcript.match(numberPattern) ? parseFloat(a.transcript.match(numberPattern)[1]) : 0
        const numberB = b.transcript.match(numberPattern) ? parseFloat(b.transcript.match(numberPattern)[1]) : 0
        return numberA - numberB
      })

      let lastSpeaker = ''
      const groupBySpeaker = []
      transcripts.forEach((doc) => {
        if (lastSpeaker === doc.participant_id) {
          groupBySpeaker[groupBySpeaker.length - 1].transcript += '\n' + doc.transcript.trim()
        } else {
          lastSpeaker = doc.participant_id
          const time = doc.transcript.match(/\[[^\]]+\]/)[0]
          const timeSeconds = parseFloat(time.substring(1, time.length - 1))
          const hours = Math.floor(timeSeconds / 60 / 60)
          const minutes = Math.floor(timeSeconds / 60)
          const secondsLeft = Math.floor(timeSeconds % 60)
          const timeFormatted = `${hours}:${(minutes < 10 && '0') + minutes}:${(secondsLeft < 10 && '0') + secondsLeft}`
          groupBySpeaker.push({
            participant_id: doc.participant_id,
            time: timeFormatted,
            transcript: doc.transcript.trim(),
          })
        }
      })

      // Split the transcript into sentences
      const splittedTranscripts = []
      groupBySpeaker.forEach((doc) => {
        const regex = /\[(\d+\.\d+)\]\s*(.+)/g
        let match

        const sentences = []
        while ((match = regex.exec(doc.transcript.trim())) !== null) {
          const start = parseFloat(match[1])
          const text = match[2]
          sentences.push({ text, start })
        }
        splittedTranscripts.push({ participant_id: doc.participant_id, time: doc.time, sentences })
      })

      const highlights = []
      for (const need of needs) {
        highlights.push({
          note_type: 'need',
          start_time: need.start_time,
          end_time: need.end_time,
          color: cnt.notesColors.needs,
          tooltip: (
            <NeedTooltip
              id={need.id}
              content={need.content}
              startTime={need.start_time}
              participantId={need.participant_id}
              whoSaidIt={need.who_said_it}
            />
          ),
        })
      }
      for (const feedback of feedbacks) {
        highlights.push({
          note_type: 'feedback',
          start_time: feedback.start_time,
          end_time: feedback.end_time,
          color:
            feedback.sentiment_analysis === 'positive'
              ? cnt.notesColors.feedback.positive
              : feedback.sentiment_analysis === 'negative'
                ? cnt.notesColors.feedback.negative
                : cnt.notesColors.feedback.neutral,
          tooltip: (
            <FeedbackTooltip
              id={feedback.id}
              content={feedback.content}
              startTime={feedback.start_time}
              participantId={feedback.participant_id}
              whoSaidIt={feedback.who_said_it}
              sentimentAnalysis={feedback.sentiment_analysis}
            />
          ),
        })
      }
      for (const quote of quotes) {
        highlights.push({
          note_type: 'quote',
          start_time: quote.start_time,
          end_time: quote.end_time,
          color: cnt.notesColors.quotes,
          tooltip: (
            <QuoteTooltip
              id={quote.id}
              originalTranscriptSegment={quote.original_transcript_segment}
              startTime={quote.start_time}
              participantId={quote.participant_id}
              whoSaidIt={quote.who_said_it}
            />
          ),
        })
      }
      for (const qa of qas) {
        highlights.push({
          note_type: 'qa',
          start_time: qa.start_time,
          end_time: qa.end_time,
          color: cnt.notesColors.qas,
          tooltip: (
            <QATooltip
              id={qa.id}
              content={qa.content}
              facilitatorQuestion={qa.facilitator_question}
              startTime={qa.start_time}
              participantId={qa.participant_id}
              whoSaidIt={qa.who_said_it}
            />
          ),
        })
      }
      highlights.sort((a, b) => a.start_time - b.start_time)
      if (highlights.length) {
        let highlightIndex = 0
        let transcriptIndex = 0
        while (highlightIndex < highlights.length && transcriptIndex < splittedTranscripts.length) {
          const transcript = splittedTranscripts[transcriptIndex]
          let sentenceIndex = 0
          while (sentenceIndex < transcript.sentences.length) {
            const sentence = transcript.sentences[sentenceIndex]
            while (highlightIndex < highlights.length && highlights[highlightIndex].end_time < sentence.start) {
              highlightIndex++
            }
            if (highlightIndex === highlights.length) break
            if (
              highlights[highlightIndex].start_time <= sentence.start &&
              highlights[highlightIndex].end_time >= sentence.start
            ) {
              transcript.sentences[sentenceIndex].highlight = highlights[highlightIndex]
            }
            sentenceIndex++
          }
          if (highlightIndex === highlights.length) break
          transcriptIndex++
        }
      }

      const newTranscripts = []
      for (const transcript of splittedTranscripts) {
        // const participant = participants[transcript.participantId]
        let hasHighlights = false
        const joinedSentences = []
        for (const sentence of transcript.sentences) {
          if (!joinedSentences.length) {
            joinedSentences.push(sentence)
            hasHighlights = !!sentence.highlight
            continue
          }
          if ((hasHighlights && sentence.highlight) || (!hasHighlights && !sentence.highlight)) {
            joinedSentences[joinedSentences.length - 1].text += ' ' + sentence.text
          } else {
            hasHighlights = !!sentence.highlight
            joinedSentences.push(sentence)
          }
        }
        newTranscripts.push({ ...transcript, sentences: joinedSentences })
      }

      setTranscripts(newTranscripts)
    }

    setMeetingLoading(false)
  }

  useEffect(() => {
    if (!param.meetingId) return navigate('/dashboard/meetings')
    if (typeof user !== 'object' || typeof user.workspaces === 'undefined') return
    initialData()
  }, [user])

  // ----- SCROLLING ------
  const [scrollToMenu, setScrollToMenu] = useState('overview')
  const [scrollTopContainer, setScrollTopContainer] = useState(null)
  const overviewRef = useRef(null)
  const quotesRef = useRef(null)
  const problemsRef = useRef(null)
  const needsRef = useRef(null)
  const qasRef = useRef(null)
  const feedbackRef = useRef(null)

  const setContainerRef = useCallback(
    (node) => {
      if (node !== null) {
        setScrollTopContainer(node)
      }
    },
    [setScrollTopContainer]
  )

  useEffect(() => {
    // scroll to ref
    if (scrollTopContainer && scrollToMenu) {
      let element
      if (scrollToMenu === 'overview') {
        element = overviewRef.current
      } else if (scrollToMenu === 'quotes') {
        element = quotesRef.current
      } else if (scrollToMenu === 'problems') {
        element = problemsRef.current
      } else if (scrollToMenu === 'needs') {
        element = needsRef.current
      } else if (scrollToMenu === 'qas') {
        element = qasRef.current
      } else if (scrollToMenu === 'feedbacks') {
        element = feedbackRef.current
      }
      scrollTopContainer.scrollTo({
        top: element.offsetTop - 20,
        behavior: 'smooth',
      })
    }
  }, [scrollToMenu])

  const [scrollToQuote, setScrollToQuote] = useState('')
  const [scrollToNeed, setScrollToNeed] = useState('')
  const [scrollToQA, setScrollToQA] = useState('')
  const [scrollToFeedback, setScrollToFeedback] = useState('')
  const [scrollToProblem, setScrollToProblem] = useState('')

  const [scrollQuotesContainer, setScrollQuotesContainer] = useState(null)
  const setQuotesContainerRef = useCallback(
    (node) => {
      if (node !== null) {
        setScrollQuotesContainer(node)
      }
    },
    [setScrollQuotesContainer]
  )

  // ----- DEBOUNCING ------
  const debouncedMeetingName = useDebounce(meeting.name, 500)

  useEffect(() => {
    if (!meeting.name) return

    async function updateProject() {
      await customFetch('/updateMeetingName', 'PUT', { meetingId: meeting.id, newValue: meeting.name })
    }

    updateProject()
  }, [debouncedMeetingName])

  const [newParticipant, setNewParticipant] = useState('')
  const [newParticipantId, setNewParticipantId] = useState('')
  const debouncedParticipantName = useDebounce(newParticipant, 500)

  function handleRenameParticipant(newName, participantId) {
    setNewParticipant(newName)
    setNewParticipantId(participantId)
    let newParticipants
    try {
      newParticipants = JSON.parse(JSON.stringify(participants))
    } catch (error) {
      console.error('Error renaming participant:', error)
    }
    newParticipants[participantId].name = newName
    setParticipants(newParticipants)
  }

  useEffect(() => {
    if (newParticipant === '' || newParticipantId === '') return

    async function updateNotesCategory() {
      await customFetch('/renameParticipant', 'PUT', { name: newParticipant, id: newParticipantId })
    }

    updateNotesCategory()
  }, [debouncedParticipantName])

  /******************* SELECTIONS *******************/

  const [selectedNotes, setSelectedNotes] = useState([])
  const [firstNoteSelected, setFirstNoteSelected] = useState(null)
  const [noteHover, setNoteHover] = useState(null)
  const [showDeleteNotesDialog, setShowDeleteNotesDialog] = useState(false)

  const [selectedFeedbacks, setSelectedFeedbacks] = useState([])
  const [firstFeedbackSelected, setFirstFeedbackSelected] = useState(null)
  const [feedbackHover, setFeedbackHover] = useState(null)
  const [showDeleteFeedbacksDialog, setShowDeleteFeedbacksDialog] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        if (
          document.activeElement &&
          (document.activeElement.tagName === 'INPUT' ||
            document.activeElement.tagName === 'TEXTAREA' ||
            document.activeElement.isContentEditable)
        ) {
          return
        }

        if (selectedNotes.length) prepareDeleteNotes()
        if (selectedFeedbacks.length) prepareDeleteFeedbacks()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedNotes, selectedFeedbacks])

  const prepareDeleteNotes = async () => {
    if (selectedNotes.length === 1) {
      const prevNotes = [...needs]
      setNeeds((prevNotes) => prevNotes.filter((n) => n.id !== selectedNotes[0]))
      customFetch('/deleteNotes', 'DELETE', { notes: [selectedNotes[0]], projectId: project.id })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedNotes.length} Notes Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setNeeds(prevNotes)
                customFetch('/undoDeleteNotes', 'PUT', { noteId: selectedNotes[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } else {
      setShowDeleteNotesDialog(true)
    }
  }

  const handleDeleteNotes = async () => {
    try {
      const prevNotes = [...needs]
      setNeeds((prevNotes) => prevNotes.filter((note) => !selectedNotes.includes(note.id)))
      setSelectedNotes([])
      setFirstNoteSelected(null)
      setNoteHover(null)
      setShowDeleteNotesDialog(false)
      customFetch('/deleteNotes', 'DELETE', {
        notes: selectedNotes,
        projectId: project.id,
      })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedNotes.length} Notes Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setNeeds(prevNotes)
                customFetch('/undoDeleteNotes', 'PUT', { noteId: selectedNotes[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  const prepareDeleteFeedbacks = async () => {
    if (selectedFeedbacks.length === 1) {
      const prevFeedbacks = [...feedbacks]
      setFeedbacks((prevFeedbacks) => prevFeedbacks.filter((n) => n.id !== selectedFeedbacks[0]))
      customFetch('/deleteFeedbacks', 'DELETE', { feedbacks: [selectedFeedbacks[0]], projectId: project.id })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedFeedbacks.length} Feedback Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setFeedbacks(prevFeedbacks)
                customFetch('/undoDeleteFeedbacks', 'PUT', { feedbackId: selectedFeedbacks[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } else {
      setShowDeleteFeedbacksDialog(true)
    }
  }

  const handleDeleteFeedbacks = async () => {
    try {
      const prevFeedbacks = [...feedbacks]
      setFeedbacks((prevFeedbacks) => prevFeedbacks.filter((feedback) => !selectedFeedbacks.includes(feedback.id)))
      setSelectedFeedbacks([])
      setFirstFeedbackSelected(null)
      setFeedbackHover(null)
      setShowDeleteFeedbacksDialog(false)
      customFetch('/deleteFeedbacks', 'DELETE', {
        feedbacks: selectedFeedbacks,
        projectId: project.id,
      })

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            {selectedFeedbacks.length} Feedback Permanently Deleted!&nbsp;
            <button
              style={{ color: '#0052CC' }}
              onClick={() => {
                setFeedbacks(prevFeedbacks)
                customFetch('/undoDeleteFeedbacks', 'PUT', { feedbackId: selectedFeedbacks[0] })
              }}
            >
              Undo
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  /******************* FILTERS *******************/

  const [filteredNotes, setFilteredNotes] = useState([])
  const [needsFilters, setNeedsFilters] = useState({
    star: 'all', // true, false, all
    tags: [],
    categories: [],
  })

  useEffect(() => {
    console.log('needs', needs.length)
    if (needs.length) {
      let newNotes = JSON.parse(JSON.stringify(needs))
      if (typeof needsFilters.star === 'boolean') {
        if (needsFilters.star) {
          newNotes = newNotes.filter((note) => note.star)
        } else {
          newNotes = newNotes.filter((note) => !note.star)
        }
      }
      if (needsFilters.tags.length) {
        newNotes = newNotes.filter((note) => needsFilters.tags.every((tag) => note.tags.includes(tag)))
      }
      if (needsFilters.categories.length) {
        newNotes = newNotes.filter((note) => needsFilters.categories.includes(note.category_id))
      }
      newNotes.sort((a, b) => a.start_time - b.start_time)
      setFilteredNotes(newNotes)
    } else {
      setFilteredNotes([])
    }
  }, [needs, needsFilters])

  const [filteredFeedbacks, setFilteredFeedbacks] = useState([])
  const [feedbacksFilters, setFeedbacksFilters] = useState({
    star: 'all', // true, false, all
    tags: [],
  })

  useEffect(() => {
    if (feedbacks.length) {
      let newFeedbacks = JSON.parse(JSON.stringify(feedbacks))
      if (typeof feedbacksFilters.star === 'boolean') {
        if (feedbacksFilters.star) {
          newFeedbacks = newFeedbacks.filter((feedback) => feedback.star)
        } else {
          newFeedbacks = newFeedbacks.filter((feedback) => !feedback.star)
        }
      }
      if (feedbacksFilters.tags.length) {
        newFeedbacks = newFeedbacks.filter((feedback) =>
          feedbacksFilters.tags.every((tag) => feedback.tags.includes(tag))
        )
      }
      newFeedbacks.sort((a, b) => a.start_time - b.start_time)
      setFilteredFeedbacks(newFeedbacks)
    } else {
      setFilteredFeedbacks([])
    }
  }, [feedbacks, feedbacksFilters])

  return (
    <MeetingsContext.Provider
      value={{
        thumbnail,
        url,
        setUrl,
        quotesLoading,
        meetingLoading,
        meeting,
        setMeeting,
        meetingOverview,
        project,
        setProject,
        categories,
        quotes,
        qas,
        problems,
        feedbacks,
        setFeedbacks,
        needs,
        setNeeds,
        participants,
        transcripts,
        handleRenameParticipant,
        setContainerRef,
        scrollTopContainer,
        scrollToMenu,
        setScrollToMenu,
        overviewRef,
        quotesRef,
        problemsRef,
        needsRef,
        qasRef,
        feedbackRef,
        scrollToQuote,
        setScrollToQuote,
        scrollToNeed,
        setScrollToNeed,
        scrollToQA,
        setScrollToQA,
        scrollToFeedback,
        setScrollToFeedback,
        scrollToProblem,
        setScrollToProblem,
        setQuotesContainerRef,
        scrollQuotesContainer,
        firstNoteSelected,
        noteHover,
        showDeleteNotesDialog,
        setShowDeleteNotesDialog,
        firstFeedbackSelected,
        feedbackHover,
        showDeleteFeedbacksDialog,
        setShowDeleteFeedbacksDialog,
        handleDeleteNotes,
        handleDeleteFeedbacks,
        filteredNotes,
        needsFilters,
        setNeedsFilters,
        filteredFeedbacks,
        feedbacksFilters,
        setFeedbacksFilters,
        prepareDeleteNotes,
        prepareDeleteFeedbacks,
        selectedFeedbacks,
        setSelectedFeedbacks,
        setFirstFeedbackSelected,
        setFeedbackHover,
        selectedNotes,
        setSelectedNotes,
        setFirstNoteSelected,
        setNoteHover,
      }}
    >
      {children}
    </MeetingsContext.Provider>
  )
}

MeetingsContextProvider.propTypes = {
  children: PropTypes.node,
}
