import { useEffect, useRef, useState } from 'react'
import isOnline from 'is-online'
import toast from 'react-hot-toast'
import { authCustomFetch } from '../hooks/useCustomFetch'

const Errors = {
  SERVER_DOWN: 'Error connecting to server. Please try again later',
  OFFLINE: 'No internet connection. Please check your internet',
}

function useNetwork(user) {
  const [isAppOnline, setIsAppOnline] = useState(true)
  const [reason, setReason] = useState('')
  const toastIdRef = useRef(null) // Ref to keep track of the toast ID

  useEffect(() => {
    if (!Object.keys(user).length) return

    const checkConnection = async () => {
      const online = await isOnline()
      setIsAppOnline(online)

      if (!online) {
        const mReason = Errors.OFFLINE
        if (reason !== mReason) {
          setReason(mReason)
          showOrUpdateToast(mReason) // Show toast if the reason changes
        }
        return // Exit early if offline
      }

      try {
        const response = await authCustomFetch('/ping')
        if (!response.ok) {
          const mReason = Errors.SERVER_DOWN
          if (reason !== mReason) {
            setReason(mReason)
            showOrUpdateToast(mReason)
          }
          setIsAppOnline(false)
        } else if (reason) {
          // Clear reason if server is reachable again
          setReason('')
          clearToast() // Clear toast when back online
        }
      } catch (error) {
        const mReason = Errors.SERVER_DOWN
        if (reason !== mReason) {
          setReason(mReason)
          showOrUpdateToast(mReason)
        }
        setIsAppOnline(false)
      }
    }

    const showOrUpdateToast = (message) => {
      if (toastIdRef.current) {
        toast.dismiss(toastIdRef.current) // Dismiss previous toast if it exists
      }
      toastIdRef.current = toast.error(message, { duration: Infinity })
    }

    const clearToast = () => {
      if (toastIdRef.current) {
        toast.dismiss(toastIdRef.current)
        toastIdRef.current = null
      }
    }

    const interval = setInterval(checkConnection, 20000)

    return () => clearInterval(interval)
  }, [user, reason])

  return [isAppOnline, reason]
}

export default useNetwork
