import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { StarsFilter } from './filters/stars'
// import { TagsFilter } from './filters/tags'
import { Feedbacks } from './feedbacks'

export function FeedbacksCanvas () {
  const { filteredFeedbacks } = useContext(MeetingsContext)

  return (
    <>
      <div className="w-full h-12 bg-white rounded-[10px] flex item-center" style={{ boxShadow: '0px 0px 16px 8px rgba(0, 0, 0, 0.15)' }}>
        <StarsFilter />
        {/* <TagsFilter /> */}
      </div>

      <div className='ml-[22px] pb-[40px]'>
        <Feedbacks feedbacks={filteredFeedbacks} />
      </div>
    </>
  )
}
