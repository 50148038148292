import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useCustomFetch } from '../../../../hooks/useCustomFetch'
import toast from 'react-hot-toast'
import { SelectBar } from '../../selectBar'
import { createPortal } from 'react-dom'
import { useDebounce } from '../../../../hooks/useDebounce'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { EditDialog } from '../../../dialogs/editDialog'
import { useScrollTo } from '../../../../hooks/useScrollTo'

export const Feedback = ({ feedback, selected, handleFeedbackSelect, feedbackHover, setFeedbackHover }) => {
  const {
    setFeedbacks,
    meeting,
    project,
    setProject,
    url,
    participants,
    scrollTopContainer,
    scrollToFeedback,
    setScrollToFeedback,
  } = useContext(MeetingsContext)
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [feedbackContent, setFeedbackContent] = useState('')
  const debouncedFeedbackContent = useDebounce(feedbackContent, 1000)
  const textRef = useRef(null)
  const [smallFontSize, setSmallFontSize] = useState(false)
  const [truncateText, setTruncateText] = useState(0)
  const [truncateHover, setTruncateHover] = useState(false)
  const customFetch = useCustomFetch()

  const divRef = useScrollTo({
    id: feedback.id,
    card: false,
    entityToScrollTo: scrollToFeedback,
    setScrollTo: setScrollToFeedback,
    scrollTopContainer: scrollTopContainer,
  })

  useEffect(() => {
    if (textRef.current) {
      // Get the computed styles for the <p> element
      let styles = window.getComputedStyle(textRef.current)
      let lineHeight = parseFloat(styles.lineHeight)
      let height = textRef.current.offsetHeight
      let lines = Math.ceil(height / lineHeight)

      if (lines < 6) return
      setSmallFontSize(true)

      textRef.current.style.fontSize = '12px'
      textRef.current.style.lineHeight = '16px'

      styles = window.getComputedStyle(textRef.current)
      lineHeight = parseFloat(styles.lineHeight)
      height = textRef.current.offsetHeight
      lines = Math.ceil(height / lineHeight)

      if (lines > 6) {
        // here I need to truncate the text to get 6 lines no more
        // I want amount of chars from note.content to be equal to 6 lines
        styles = window.getComputedStyle(textRef.current)
        lineHeight = parseFloat(styles.lineHeight)
        let amountChars = feedback.content.length
        while (lines > 6 && amountChars > 0) {
          amountChars -= 3
          textRef.current.innerText = feedback.content.slice(0, amountChars)
          height = textRef.current.offsetHeight
          lines = Math.ceil(height / lineHeight)
        }
        textRef.current.innerText += feedback.content
        setTruncateText(amountChars - 3)
      }
    }
  }, [feedback.content])

  useEffect(() => {
    if (!feedbackContent) return
    const handleChangeFeedbackContent = async (content) => {
      try {
        setFeedbacks((prevFeedbacks) => prevFeedbacks.map((f) => (f.id === feedback.id ? { ...f, content } : f)))
        await customFetch('/updateFeedbackContent', 'PUT', { feedbackId: feedback.id, content })
      } catch (error) {
        console.log(error)
        toast.error('Whoops! Something went wrong. Please try again.')
      }
    }
    handleChangeFeedbackContent(feedbackContent)
  }, [debouncedFeedbackContent])

  const handleDeleteFeedback = async () => {
    try {
      setFeedbacks((prevFeedbacks) => prevFeedbacks.filter((f) => f.id !== feedback.id))
      setShowEditDialog(false)
      toast.success('Feedback deleted successfully')
      await customFetch('/deleteFeedbacks', 'DELETE', { feedbacks: [feedback.id], projectId: feedback.projectId })
    } catch (error) {
      console.log(error)
      toast.error('Whoops! Something went wrong. Please try again.')
    }
  }

  return (
    <div
      onDoubleClick={() => setShowEditDialog(true)}
      key={feedback.id}
      onMouseEnter={() => selected && setFeedbackHover(feedback.id)}
      onClick={(event) => handleFeedbackSelect(feedback.id, event)}
      className={'m-2 relative'}
    >
      {selected && feedbackHover === feedback.id && <SelectBar noteOrFeedback="feedback" />}
      {showEditDialog &&
        createPortal(
          <EditDialog
            entity={feedback}
            project={project}
            participants={participants}
            setProject={setProject}
            rating={false}
            setEntities={setFeedbacks}
            entityName={'feedback'}
            meeting={{ ...meeting, url }}
            close={() => setShowEditDialog(false)}
            changeContent={(content) => setFeedbackContent(content)}
            deleteEntity={handleDeleteFeedback}
          />,
          document.body
        )}
      <div
        ref={divRef}
        onMouseEnter={() => truncateText && setTruncateHover(true)}
        onMouseLeave={() => truncateText && setTruncateHover(false)}
        style={{
          backgroundColor: `${feedback.sentiment_analysis === 'positive' ? '#BBE6B7' : feedback.sentiment_analysis === 'negative' ? '#E5A8AC' : '#FFF98E'}`,
        }}
        className={`w-[210px] h-[134px] p-5 rounded shadow ${truncateText && 'transition-transform duration-300 hover:w-[230px] hover:h-[154px] hover:-m-[10px]'}  ${selected && 'border-2 border-pink rounded-[6px]'}`}
      >
        <p
          ref={textRef}
          className={`text-black font-semibold font-['Manrope'] ${smallFontSize ? 'text-xs' : 'text-sm'}`}
        >
          {truncateText && !truncateHover ? feedback.content.slice(0, truncateText) + '...' : feedback.content}
        </p>
      </div>
    </div>
  )
}

Feedback.propTypes = {
  feedback: PropTypes.object,
  selected: PropTypes.bool,
  handleFeedbackSelect: PropTypes.func,
  feedbackHover: PropTypes.string,
  setFeedbackHover: PropTypes.func,
}
