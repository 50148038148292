import React, { useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { MeetingsContext } from '../../context/MeetingContext'
import { UserDropdown } from '../dashboard/topbar/userDropdown'

export function Topbar () {
  const { project, meeting, setMeeting } = useContext(MeetingsContext)
  const navigate = useNavigate()
  const nameRef = useRef()

  return (
        <div className="py-[15px] px-[26px] flex items-center justify-between border-b border-b-[#e4e4e4]">
            <button onClick={() => navigate(-1)} className='grow'>
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 8.71484H19" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 8.71484L8.71429 16.4291" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1 8.71429L8.71429 1" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
            <div className='flex items-center justify-center grow'>
                <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_4006_18103)">
                    <path d="M24.125 6.125H18V13.125H24.125V6.125Z" fill="#E4E4E4"/>
                    <path d="M18 6H11V16.5H18V6Z" fill="#FF4A8E"/>
                    <path opacity="0.2" d="M11 13.125H4.875V22.75C4.875 22.9821 4.96719 23.2046 5.13128 23.3687C5.29538 23.5328 5.51794 23.625 5.75 23.625H10.125C10.3571 23.625 10.5796 23.5328 10.7437 23.3687C10.9078 23.2046 11 22.9821 11 22.75V13.125Z" fill="#FF4A8E"/>
                    <path d="M4.875 6.125H24.125V19.25C24.125 19.4821 24.0328 19.7046 23.8687 19.8687C23.7046 20.0328 23.4821 20.125 23.25 20.125H18.875C18.6429 20.125 18.4204 20.0328 18.2563 19.8687C18.0922 19.7046 18 19.4821 18 19.25V16.625H11V22.75C11 22.9821 10.9078 23.2046 10.7437 23.3687C10.5796 23.5328 10.3571 23.625 10.125 23.625H5.75C5.51794 23.625 5.29538 23.5328 5.13128 23.3687C4.96719 23.2046 4.875 22.9821 4.875 22.75V6.125Z" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11 13.125H4.875" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11 6.125V16.625" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18 13.125H24.125" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18 6.125V16.625" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_4006_18103">
                    <rect width="28" height="28" fill="white" transform="translate(0.5)"/>
                    </clipPath>
                    </defs>
                </svg>

                <p className='flex items-center justify-center grow'>
                    <span className="text-black text-lg font-normal font-['Manrope'] leading-tight tracking-wide">{project.name} / </span>
                    <input
                        ref={nameRef}
                        type='text'
                        className="text-black bg-white text-lg font-bold font-['Manrope'] leading-tight tracking-wide grow"
                        value={meeting.name}
                        onChange={(e) => setMeeting({ ...meeting, name: e.target.value })}
                        onKeyDown={e => e.key === 'Enter' && nameRef.current.blur()}
                    />
                    {/* <span className="text-black text-lg font-bold font-['Manrope'] leading-tight tracking-wide">{meeting.name}</span> */}
                </p>
            </div>

            <UserDropdown />
        </div>
  )
}
