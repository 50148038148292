import React from 'react'

const ProblemsIcon = ({ checked }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.8333 15C4.79353 15 3.79634 14.6049 3.06112 13.9017C2.32589 13.1984 1.91284 12.2446 1.91284 11.25C1.91284 10.2554 2.32589 9.30161 3.06112 8.59835C3.79634 7.89509 4.79353 7.5 5.8333 7.5C6.07887 6.40598 6.79727 5.44457 7.83045 4.82726C8.34203 4.5216 8.9155 4.30962 9.51811 4.20343C10.1207 4.09723 10.7407 4.0989 11.3426 4.20833C11.9444 4.31777 12.5165 4.53283 13.026 4.84123C13.5356 5.14964 13.9726 5.54535 14.3123 6.00577C14.6519 6.4662 14.8874 6.98232 15.0054 7.52466C15.1234 8.06701 15.1216 8.62496 15 9.16667H15.8333C16.6068 9.16667 17.3487 9.47396 17.8957 10.0209C18.4427 10.5679 18.75 11.3098 18.75 12.0833C18.75 12.8569 18.4427 13.5987 17.8957 14.1457C17.3487 14.6927 16.6068 15 15.8333 15H15" stroke={checked ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.8333 11.666L9.16663 14.9993H11.6666L9.99996 18.3327" stroke={checked ? '#FF4A8E' : '#40424A'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ProblemsIcon
