import React from 'react'

export const useScrollTo = (props) => {
  const { id, entityToScrollTo, setScrollTo, scrollTopContainer, card = true, scrollInnerContainer } = props
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (!ref.current || !scrollTopContainer || !entityToScrollTo) return
    if (entityToScrollTo !== id) return
    try {
      if (card) {
        let element = ref.current
        let leftOffset = 0
        while (element) {
          leftOffset += element.offsetLeft
          element = element.offsetParent
        }

        // Scroll to the element
        if (scrollInnerContainer) {
          scrollInnerContainer.scrollTo({
            top: 0,
            left: leftOffset - window.innerWidth * 0.32,
            behavior: 'smooth',
          })
        }
        scrollTopContainer.scrollTo({
          top: ref.current.offsetTop - 100,
          behavior: 'smooth',
        })
      } else {
        const topPosition = ref.current.getBoundingClientRect().top + scrollTopContainer.scrollTop
        scrollTopContainer.scrollTo({
          top: topPosition - 100,
          behavior: 'smooth',
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setScrollTo('')
    }
  }, [entityToScrollTo])
  return ref
}
