/* eslint-disable react/no-unknown-property */
import React, { useContext } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { QA } from '../overview/qa'
import { Facilitators } from '../overview/sidebar/facilitators'

export default function QAsIndex() {
  const { isLoading, overview } = useContext(ProjectContext)

  return (
    <div className="px-[50px] py-[30px] w-full overflow-y-auto">
      <div className="w-full mb-[25px]">
        <p className="text-[#3f424a] text-4xl font-bold font-['DM_Sans'] leading-[41.40px]">
          Interview at a Glance
        </p>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center">
          <svg className="w-40 h-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
              <stop offset="0" stopColor="#FF156D"></stop>
              <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
              <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
              <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
              <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
            </radialGradient>
            <circle
              transform-origin="center"
              fill="none"
              stroke="url(#a1)"
              strokeWidth="15"
              strokeLinecap="round"
              strokeDasharray="200 1000"
              strokeDashoffset="0"
              cx="100"
              cy="100"
              r="70"
            >
              <animateTransform
                type="rotate"
                attributeName="transform"
                calcMode="spline"
                dur="2"
                values="360;0"
                keyTimes="0;1"
                keySplines="0 0 1 1"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              transform-origin="center"
              fill="none"
              opacity=".2"
              stroke="#FF156D"
              strokeWidth="15"
              strokeLinecap="round"
              cx="100"
              cy="100"
              r="70"
            ></circle>
          </svg>
        </div>
      ) : (
        <div className="w-full flex justify-between gap-[40px]">
          <div className="grow px-[59px] bg-white rounded-2xl mb-[80px]">
            {(overview?.qas || []).map((qa, index) => (
              <div key={index}>
                <QA question={qa.key_question} answer={qa.answers_overview} />
              </div>
            ))}
          </div>

          <div className="min-w-[360px]">
            <Facilitators overviewDesign={false} />
          </div>
        </div>
      )}
    </div>
  )
}
