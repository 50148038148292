import React, { useContext, useEffect, useState } from 'react'
import { ProjectContext } from '../../context/ProjectContext'
import { v4 as uuidv4 } from 'uuid'
import { Transcript } from './transcript'

export function Transcripts () {
  const { transcripts, meetings, participants } = useContext(ProjectContext)
  const [texts, setTexts] = useState([])

  useEffect(() => {
    const newMeetings = []
    for (const meet of meetings) {
      let content = []
      let participant
      const transcriptsByMeeting = transcripts.filter(transcript => transcript.meeting_id === meet.id)
      transcriptsByMeeting.sort((a, b) => {
        // Extract the first number in square brackets using a regular expression.
        const numberPattern = /\[(\d+(\.\d+)?)\]/
        const numberA = a.transcript.match(numberPattern) ? parseFloat(a.transcript.match(numberPattern)[1]) : 0
        const numberB = b.transcript.match(numberPattern) ? parseFloat(b.transcript.match(numberPattern)[1]) : 0
        return numberA - numberB
      })
      transcriptsByMeeting.forEach((doc) => {
        const textWithNoTime = doc.transcript.replace(/\[[^\]]+\]/g, '')
        // const matches = regex.exec(doc.transcript) // matches[1] is the first time, matches[2] is the content without time
        if (doc.participant_id !== participant) {
          const time = doc.transcript.match(/\[[^\]]+\]/)[0]
          const timeSeconds = parseFloat(time.substring(1, time.length - 1))
          const minutes = Math.floor(timeSeconds / 60)
          const secondsLeft = Math.floor(timeSeconds % 60)
          const timeRes = `[${minutes} min. ${secondsLeft} sec.]`
          content.push(<div key={uuidv4()}><br /><p className="font-bold">{participants[doc.participant_id] + ' ' + timeRes}</p></div>)
          participant = doc.participant_id
        }
        content.push(<p key={uuidv4()}>{textWithNoTime}</p>)
      })

      if (content.length === 0 && !meet.meeting_url && !meet.processing && meet.thread_id) {
        content.push(<p key={uuidv4()}>Transcription failed: please verify that the file has audible content, and try again.</p>)
      }
      newMeetings.push({ id: meet.id, meeting: meet, content })
      content = []
      participant = ''
    }

    setTexts(newMeetings)
  }, [transcripts, meetings])

  return texts.map(text => <Transcript text={text} key={text.id} />)
}
