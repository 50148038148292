import React, { useContext, useMemo } from 'react'
import { DashboardContext } from '../../../context/DashboardContext'

export function WorkspaceUsage () {
  const { activeWorkspace } = useContext(DashboardContext)

  const percentage = useMemo(() => {
    const percentage = Math.round(100 - (activeWorkspace?.additional_amount + activeWorkspace?.sub_hours_left) * 100 / activeWorkspace?.total_amount)
    if (percentage < 0) return 0
    if (percentage > 100) return 100
    return percentage
  }, [activeWorkspace?.additional_amount, activeWorkspace?.sub_hours_left, activeWorkspace?.total_amount])

  const timeLeft = useMemo(() => {
    if (activeWorkspace.additional_amount + activeWorkspace.sub_hours_left < 0) return 0
    const hours = (activeWorkspace.additional_amount + activeWorkspace.sub_hours_left) / 60
    if (Number.isInteger(hours)) {
      return hours
    } else if ((hours * 10) % 1 === 0) {
      return Number(hours.toFixed(1))
    }
    return Number(hours.toFixed(2))
  }, [activeWorkspace.additional_amount, activeWorkspace.sub_hours_left])

  return (
      <div className='ml-[10px] mr-[20px] w-[210px] pt-[22px] px-[10px] border-t border-grey-200'>
        <p className="opacity-80 text-[#62718D] text-xs font-extrabold font-['Manrope'] uppercase leading-[13.20px] tracking-wide">workspace usage</p>
        <div className='mt-[14px] flex items-center justify-between'>
          <p className="opacity-80 text-black text-[15px] font-bold font-['Manrope'] leading-[18.75px]">{percentage || 0}%</p>
          <p className="opacity-80"><span className="text-[#40424A] text-[13px] font-bold font-['Manrope'] leading-none">{timeLeft || 0}</span><span className="text-[#40424A] text-[13px] font-medium font-['Manrope'] leading-none"> hours remaining</span></p>
        </div>
        <div className="mt-[10px] w-full h-[5px] relative bg-[#E6E6E6] rounded-[30px]">
          <div style={{ width: `${percentage}%` }} className="h-[5px] left-0 top-0 absolute bg-[#FF4A8E] rounded-[30px]" />
        </div>
        {activeWorkspace.sub_extra_hour_charge
          ? <p className="mt-[14px] opacity-80"><span className="text-[#62718D] text-xs font-medium font-['Manrope'] leading-none">Under your current plan, overage hours are billed at </span><span className="text-[#62718D] text-xs font-bold font-['Manrope'] leading-none">${activeWorkspace.sub_extra_hour_charge.toFixed(2)}/hour</span><span className="text-[#62718D] text-xs font-medium font-['Manrope'] leading-none">.</span></p>
          : <p className="mt-[14px] opacity-80"><span className="text-[#62718D] text-xs font-medium font-['Manrope'] leading-none">{activeWorkspace.additional_amount ? 'Once your free hours run out, all AI functions will be suspended.' : 'This workspace has run out of free hours. Please upgrade to continue using our AI features.'}</span></p>}
        <div className='flex justify-center'>
          <button className="mt-[22px] w-[190px] h-8 px-4 py-2 rounded shadow-inner border border-[#62718D] justify-center items-center gap-3 inline-flex" onClick={() => window.open('https://www.usermuse.ai/pricing/', '_blank')}>
            <div className="text-[#62718D] text-[13px] font-medium font-['Manrope'] leading-none">Upgrade</div>
          </button>
        </div>
      </div>
  )
}
