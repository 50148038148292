import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { DashboardContext } from '../../../context/DashboardContext'

export function ChooseExistingProject ({ handleChooseExistingProject }) {
  const { allProjects } = useContext(DashboardContext)

  return (
    <div className="overflow-auto h-[420px] w-full py-5 bg-grey-100 rounded shadow-inner">
      {allProjects.map(project => (
        <div key={project.id} className="mx-8">
          <div className='my-2 flex items-center'>
            <div className='w-2/5 flex items-center'>
              <div className='w-1/5'>
                <svg width="64" height="63" viewBox="0 0 64 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1167_451)">
                  <path d="M53.6044 13.7812H39.8232V29.5312H53.6044V13.7812Z" fill="#E4E4E4"/>
                  <path d="M39.8232 13.5H24.0732V37.125H39.8232V13.5Z" fill="#FF4A8E"/>
                  <path opacity="0.2" d="M24.0732 29.5312H10.2919V51.1875C10.2919 51.7096 10.4994 52.2104 10.8686 52.5796C11.2378 52.9488 11.7385 53.1562 12.2607 53.1562H22.1044C22.6266 53.1562 23.1273 52.9488 23.4965 52.5796C23.8658 52.2104 24.0732 51.7096 24.0732 51.1875V29.5312Z" fill="#FF4A8E"/>
                  <path d="M10.2919 13.7812H53.6044V43.3125C53.6044 43.8346 53.397 44.3354 53.0278 44.7046C52.6586 45.0738 52.1578 45.2812 51.6357 45.2812H41.7919C41.2698 45.2812 40.769 45.0738 40.3998 44.7046C40.0306 44.3354 39.8232 43.8346 39.8232 43.3125V37.4062H24.0732V51.1875C24.0732 51.7096 23.8658 52.2104 23.4965 52.5796C23.1273 52.9488 22.6266 53.1562 22.1044 53.1562H12.2607C11.7385 53.1562 11.2378 52.9488 10.8686 52.5796C10.4994 52.2104 10.2919 51.7096 10.2919 51.1875V13.7812Z" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M24.0732 29.5312H10.2919" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M24.0732 13.7812V37.4062" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M39.8232 29.5312H53.6044" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M39.8232 13.7812V37.4062" stroke="#AAB0BA" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1167_451">
                  <rect width="63" height="63" fill="white" transform="translate(0.448181)"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              <div className='w-4/5 ml-6'>
                <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">{project.name.length > 18 ? project.name.substring(0, 18) + '...' : project.name }</p>
                <div className='flex items-center'>
                  {/* <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.625 11L11 6.625" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.625 11V7.25C6.625 7.08424 6.69085 6.92527 6.80806 6.80806C6.92527 6.69085 7.08424 6.625 7.25 6.625H11V2.25C11 1.91848 10.8683 1.60054 10.6339 1.36612C10.3995 1.1317 10.0815 1 9.75 1H2.25C1.91848 1 1.60054 1.1317 1.36612 1.36612C1.1317 1.60054 1 1.91848 1 2.25V9.75C1 10.0815 1.1317 10.3995 1.36612 10.6339C1.60054 10.8683 1.91848 11 2.25 11H6.625Z" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg> */}
                  <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">
                    {project.method === 'user_interviews' ? 'User Interviews' : project.method === 'user_testing' ? 'User Testing' : project.method === 'focus_group' ? 'Focus Group' : 'Mixed Methods'}
                    ・{new Date(project.created_at).getMonth() + 1}/{new Date(project.created_at).getDate()}/{new Date(project.created_at).getFullYear()}</p>
                </div>
              </div>
            </div>
            <div className='w-3/5 flex items-center'>
              <div className='w-1/6 text-center'>
                <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">{project.needs_count}</p>
                <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">Notes</p>
              </div>
              <div className='w-2/6 text-center'>
                <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">{project.categories_count}</p>
                <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">Categories</p>
              </div>
              <div className='w-1/6 text-center'>
                <p className="mb-1.5 text-zinc text-base font-semibold font-['Manrope']">{project.meetings_count}</p>
                <p className="text-slate text-[13px] font-medium font-['Manrope'] leading-none">Sessions</p>
              </div>
              <div className='w-2/6 flex items-center justify-center'>
                <button className="px-5 py-2.5 bg-pink rounded shadow-inner justify-center items-center gap-3 inline-flex" onClick={() => handleChooseExistingProject(project)}>
                  <p className="text-white text-base font-semibold font-['Manrope'] leading-tight">Select</p>
                </button>
              </div>
            </div>
          </div>
          <div className="w-full border-b-2 border-grey-200"></div>
        </div>
      ))}
    </div>
  )
}

ChooseExistingProject.propTypes = {
  handleChooseExistingProject: PropTypes.func.isRequired
}
