import React, { useContext, useEffect, useRef, useState } from 'react'
import { MeetingsContext } from '../../../../../context/MeetingContext';
import { truncateText } from '../../../../../utils';

export function CategoriesFilter () {
  const { needsFilters, setNeedsFilters, categories } = useContext(MeetingsContext)
  const [isOpen, setIsOpen] = useState(false)
  const categoryFilterRef = useRef(null)

  // Handle outside clicks
  useEffect(() => {
    function handleClickOutside(event) {
      if (categoryFilterRef.current && !categoryFilterRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const handleSelect = (option) => {
    if (needsFilters.categories.includes(option)) {
      setNeedsFilters(prevFilters => ({ ...prevFilters, categories: prevFilters.categories.filter(item => item !== option) }))
    } else {
      setNeedsFilters(prevFilters => ({ ...prevFilters, categories: [...prevFilters.categories, option] }))
    }
  }

  return (
    <div className='relative w-1/2 border-r border-[#e4e4e4] flex justify-center items-center' ref={categoryFilterRef}>
      <button className="px-1 py-3.5 flex justify-center items-center gap-2" onClick={() => setIsOpen(!isOpen)}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.80078 1.38477L0.800781 5.33477L8.80078 9.28477L16.8008 5.33477L8.80078 1.38477Z" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M0.800781 9.2832L8.80078 13.2332L16.8008 9.2832" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M0.800781 13.2344L8.80078 17.1844L16.8008 13.2344" stroke="#40424A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <p className="whitespace-nowrap text-[#3f424a] text-base font-semibold font-['Manrope'] leading-tight">{!needsFilters.categories.length ? 'Any Categories' : needsFilters.categories.length === 1 ? truncateText(categories.find(category => category.id === needsFilters.categories[0]).content, 20) : needsFilters.categories.length + ' Selected'}</p>
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_3498_24364)">
          <circle cx="10.4925" cy="10.2835" r="7.27964" fill="#EBEBEB"/>
          <path d="M12.9195 9.47656L10.493 11.9031L8.06641 9.47656" stroke="#40424A" strokeWidth="1.3866" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
          <clipPath id="clip0_3498_24364">
          <rect width="19.4124" height="19.4124" fill="white" transform="translate(0.787109 0.578125)"/>
          </clipPath>
          </defs>
        </svg>
      </button>

      {isOpen && (
        <div className="absolute top-10 z-10 mt-2 min-w-56 max-w-[350px] overflow-hidden rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {categories.length === 0 ? (
              <div className="flex items-center justify-center px-4 py-2">
                <span className="text-gray-700">No Categories</span>
              </div>
            ) : categories.map(category => (
              <div key={category.id} className="flex items-center px-4 py-2 cursor-pointer hover:bg-grey-100" onClick={() => handleSelect(category.id)}>
                <input
                  type="checkbox"
                  className="form-checkbox min-h-5 min-w-5 text-blue-600"
                  checked={needsFilters.categories.includes(category.id)}
                  readOnly
                />
                <span className="whitespace-nowrap ml-2 text-gray-700">{category.content}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
