/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useState } from 'react'
import { Sidebar } from './sidebar'
import { Topbar } from './topbar'
import { Projects } from './projects'
import { Events } from './events'
import { Team } from './team'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useCustomFetch } from '../../hooks/useCustomFetch'
import { AuthContext } from '../../context/AuthContext'
import { DashboardContext } from '../../context/DashboardContext'
import { Meetings } from './meetings'
import { Integrations } from './integrations'
import { WelcomeDashboard } from './WelcomeDashboard'
import { DynamicItems } from './DynamicItems'
import clsx from 'clsx'

export function Dashboard() {
  const [isLoading, setIsLoading] = useState(true)
  const [searchParams] = useSearchParams()
  const { menu } = useParams()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const { refreshActiveWorkspace, activeWorkspace, events, eventsLoading, projectsLoading } =
    useContext(DashboardContext)
  const { filteredProjects, allProjects } = useContext(DashboardContext)
  const setMenu = (newMenu) => navigate(`/dashboard/${newMenu}`)
  const [showLoader, setShowLoader] = useState(true)
  const limit = 10
  const customFetch = useCustomFetch()

  const handleSubscribeToPlan = async (workspaceId, planId) => {
    try {
      const response = await customFetch('/createCheckoutUrl', 'POST', { workspaceId, planId })
      if (!response.hostedPageUrl) throw new Error('No plan URL found')
      window.location.href = response.hostedPageUrl
    } catch (error) {
      console.error('Error initiating payment', error)
    }
  }

  useEffect(() => {
    if (isLoading || eventsLoading) return
    ;(async function () {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      setShowLoader(false)
    })()
  }, [filteredProjects, events])

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    if (!activeWorkspace) return
    const paymentStatus = searchParams.get('payment_status')
    if (paymentStatus === 'success') return
    const planId = searchParams.get('plan_id')
    if (!planId) return
    const workspacesAmount = Object.keys(user.workspaces).length
    if (workspacesAmount !== 1) return
    if (activeWorkspace.plan_id) {
      navigate(`/workspace/select?plan_id=${planId}`)
    } else {
      handleSubscribeToPlan(Object.keys(user.workspaces)[0], planId)
    }
  }, [activeWorkspace])

  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return
    // check if this is a redirect from the payment gateway
    const paymentStatus = searchParams.get('payment_status')
    const planId = searchParams.get('plan_id')
    if (paymentStatus === 'success') {
      toast.success('Payment successful, might take a few minutes to reflect in your account')
      setTimeout(refreshActiveWorkspace, 30000)
      return
    }
    if (planId) {
      const workspacesAmount = Object.keys(user.workspaces).length
      if (workspacesAmount === 0) {
        navigate(`/workspace/create?plan_id=${planId}`)
      } else if (workspacesAmount > 1) {
        navigate(`/workspace/select?plan_id=${planId}`)
      }
    }
  }, [user.workspaces])

  useEffect(() => {
    const paymentStatus = searchParams.get('payment_status')
    const planId = searchParams.get('plan_id')
    if (paymentStatus === 'success' || !planId) {
      setIsLoading(false)
    }
  }, [searchParams])

  if (showLoader || projectsLoading)
    return (
      <div className="h-screen bg-white">
        <div className="h-full w-60 fixed top-0 border-r-2 border-grey-200 overflow-auto hideScrollbar">
          <Sidebar menu={menu} setMenu={setMenu} />
        </div>
        <div
          className="h-[70px] fixed top-0 left-60 border-b-2 border-grey-200 z-10"
          style={{ width: 'calc(100% - 240px)' }}
        >
          <Topbar />
        </div>

        <div className="fixed" style={{ top: 'calc(50% + 35px)', left: 'calc(50% + 120px)' }}>
          <svg className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
              <stop offset="0" stopColor="#FF156D"></stop>
              <stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop>
              <stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop>
              <stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop>
              <stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop>
            </radialGradient>
            <circle
              transform-origin="center"
              fill="none"
              stroke="url(#a1)"
              strokeWidth="15"
              strokeLinecap="round"
              strokeDasharray="200 1000"
              strokeDashoffset="0"
              cx="100"
              cy="100"
              r="70"
            >
              <animateTransform
                type="rotate"
                attributeName="transform"
                calcMode="spline"
                dur="2"
                values="360;0"
                keyTimes="0;1"
                keySplines="0 0 1 1"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
            <circle
              transform-origin="center"
              fill="none"
              opacity=".2"
              stroke="#FF156D"
              strokeWidth="15"
              strokeLinecap="round"
              cx="100"
              cy="100"
              r="70"
            ></circle>
          </svg>
        </div>
      </div>
    )

  return (
    <div className="h-screen bg-white">
      <div className="h-full w-60 fixed top-0 border-r-2 border-grey-200 overflow-auto hideScrollbar">
        <Sidebar menu={menu} setMenu={setMenu} />
      </div>
      <div
        className="h-[70px] fixed top-0 left-60 border-b-2 border-grey-200 z-10"
        style={{ width: 'calc(100% - 240px)' }}
      >
        <Topbar />
      </div>

      <div
        className={clsx(
          'pl-12 pb-12 fixed top-[70px] left-60 border-b-2 border-grey-200 bg-grey-100 overflow-auto hideScrollbar z-0',
          (!!allProjects.length || !!events.length) && menu === 'home' && 'grid grid-cols-1 md:grid-cols-6 gap-4'
        )}
        style={{
          width: 'calc(100% - 240px)',
          height: 'calc(100% - 70px)',
          paddingLeft: !allProjects.length && !events.length && menu === 'home' ? '0px' : null,
        }}
      >
        <div className="md:col-span-6 xl:col-span-4">
          {menu === 'home' && (
            <>
              {!!allProjects.length || !!events.length ? (
                <>
                  <Events limit={limit} events={events} eventsLoading={eventsLoading} />
                  <Projects limit={limit} />
                </>
              ) : (
                <WelcomeDashboard />
              )}
            </>
          )}
          {menu === 'projects' && <Projects />}
          {menu === 'meetings' && <Meetings />}
          {menu === 'integrations' && <Integrations />}
          {menu === 'team' && <Team />}
        </div>

        {menu === 'home' && (!!allProjects.length || !!events.length) && (
          <div className="md:col-span-6 xl:col-span-2">
            <DynamicItems isFullWidth={!!allProjects.length || !!events.length}></DynamicItems>
          </div>
        )}
      </div>
    </div>
  )
}
