import React from 'react'

const QASIcon = ({ checked }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4179_19216)">
        <path
          d="M20.375 6.27154C21.075 6.66954 21.505 7.41454 21.5 8.21954V15.5035C21.5 16.3125 21.057 17.0585 20.342 17.4515L13.592 21.7215C13.2574 21.9053 12.8818 22.0016 12.5 22.0016C12.1182 22.0016 11.7426 21.9053 11.408 21.7215L4.658 17.4515C4.30817 17.2604 4.01612 16.9787 3.81241 16.636C3.6087 16.2933 3.5008 15.9022 3.5 15.5035V8.21854C3.5 7.40954 3.943 6.66454 4.658 6.27154L11.408 2.29154C11.7525 2.10158 12.1396 2.00195 12.533 2.00195C12.9264 2.00195 13.3135 2.10158 13.658 2.29154L20.408 6.27154H20.375Z"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 16V16.01"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 12.9998C12.9498 13.0011 13.3868 12.8508 13.7407 12.5732C14.0945 12.2956 14.3444 11.9068 14.45 11.4696C14.5557 11.0324 14.5109 10.5724 14.3229 10.1638C14.1349 9.75524 13.8147 9.42195 13.414 9.21776C13.0162 9.01397 12.5611 8.95079 12.1228 9.03848C11.6845 9.12618 11.2888 9.3596 11 9.70076"
          stroke={checked ? '#FF4A8E' : '#40424A'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4179_19216">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QASIcon
