import React, { useContext } from 'react'
import { MeetingsContext } from '../../../../context/MeetingContext'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import { NeedTooltip } from '../../tooltips/needTooltip'
import { HelpIcon } from '../../../icons/Help'

export function TimelineNeeds({ duration }) {
  const { needs } = useContext(MeetingsContext)

  return (
    <div className="w-full flex items-center gap-[10px]">
      <div className="flex w-[120px] items-center gap-[5px]">
        <p className="text-[#3f424a] text-xs font-extrabold font-['Manrope'] uppercase leading-3 tracking-wide">
          Needs
        </p>
        <HelpIcon content={'Needs & challenges identified.'} />
      </div>

      <div className="relative w-full border-b border-b-[#E4E4E4]">
        {needs.map((need) => (
          <div key={need.id}>
            <Tippy
              interactive={true}
              placement="top"
              maxWidth={350}
              content={
                <NeedTooltip
                  id={need.id}
                  content={need.content}
                  participantId={need.participant_id}
                  whoSaidIt={need.who_said_it}
                  startTime={need.start_time}
                />
              }
            >
              <div
                style={{ left: `${((need.start_time * 100) / duration).toFixed(2)}%` }}
                className="absolute top-[-4px] w-[8.68px] h-[8.68px] rounded-full bg-[#FFC921] border border-[#DCAE1D]"
              ></div>
            </Tippy>
          </div>
        ))}
      </div>
    </div>
  )
}

TimelineNeeds.propTypes = {
  duration: PropTypes.number,
}
