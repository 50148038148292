import React, { useContext } from 'react'
import { useCollapse } from 'react-collapsed'
import PropTypes from 'prop-types'
import { MeetingsContext } from '../../../../context/MeetingContext'
import { useScrollTo } from '../../../../hooks/useScrollTo'

export function QA({ question, answer, id }) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false })
  const { scrollToQA, scrollTopContainer, setScrollToQA } = useContext(MeetingsContext)
  const qaRef = useScrollTo({
    id,
    card: false,
    scrollTopContainer,
    setScrollTo: setScrollToQA,
    entityToScrollTo: scrollToQA,
  })

  return (
    <div {...getToggleProps()}>
      <div className="mt-[28px] w-full" ref={qaRef}>
        <div className="mb-1 pb-[8px] flex justify-between items-center border-b-2 border-grey-200">
          <p className="text-[#40424A] text-[22px] font-medium font-['DM_Sans'] leading-relaxed">{question}</p>
          <button>
            {!isExpanded ? (
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1947_10897)">
                  <circle cx="24" cy="24" r="18" fill="#EBEBEB" />
                  <path
                    d="M30 22L24 28L18 22"
                    stroke="#40424A"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1947_10897">
                    <rect width="48" height="48" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="18"
                  cy="18"
                  r="18"
                  transform="matrix(1 1.74846e-07 1.74846e-07 -1 6 42.2852)"
                  fill="#EBEBEB"
                />
                <path
                  d="M30 26.2852L24 20.2852L18 26.2852"
                  stroke="#40424A"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </button>
        </div>

        <div {...getCollapseProps()}>
          <div className="flex pt-[14px] pb-[28px]">
            <div className="w-[20px] h-[20px]">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3944_2362)">
                  <path
                    d="M13.3333 15.0007C13.7754 15.0007 14.1993 15.1762 14.5118 15.4888C14.8244 15.8014 15 16.2253 15 16.6673C15 16.2253 15.1756 15.8014 15.4882 15.4888C15.8007 15.1762 16.2246 15.0007 16.6667 15.0007C16.2246 15.0007 15.8007 14.8251 15.4882 14.5125C15.1756 14.1999 15 13.776 15 13.334C15 13.776 14.8244 14.1999 14.5118 14.5125C14.1993 14.8251 13.7754 15.0007 13.3333 15.0007ZM13.3333 5.00065C13.7754 5.00065 14.1993 5.17625 14.5118 5.48881C14.8244 5.80137 15 6.22529 15 6.66732C15 6.22529 15.1756 5.80137 15.4882 5.48881C15.8007 5.17625 16.2246 5.00065 16.6667 5.00065C16.2246 5.00065 15.8007 4.82506 15.4882 4.5125C15.1756 4.19994 15 3.77601 15 3.33398C15 3.77601 14.8244 4.19994 14.5118 4.5125C14.1993 4.82506 13.7754 5.00065 13.3333 5.00065ZM7.5 15.0007C7.5 13.6746 8.02678 12.4028 8.96447 11.4651C9.90215 10.5274 11.1739 10.0007 12.5 10.0007C11.1739 10.0007 9.90215 9.47387 8.96447 8.53618C8.02678 7.5985 7.5 6.32673 7.5 5.00065C7.5 6.32673 6.97322 7.5985 6.03553 8.53618C5.09785 9.47387 3.82608 10.0007 2.5 10.0007C3.82608 10.0007 5.09785 10.5274 6.03553 11.4651C6.97322 12.4028 7.5 13.6746 7.5 15.0007Z"
                    stroke="#FF4A8E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3944_2362">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p className="ml-[14px] text-[#62708d] text-lg font-normal font-['DM_Sans'] leading-[23px]">{answer}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

QA.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  id: PropTypes.string,
}
