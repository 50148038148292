import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectContext } from '../../../context/ProjectContext'
import { useCustomFetch } from '../../../hooks/useCustomFetch'
import toast from 'react-hot-toast'
import Tippy from '@tippyjs/react'
import cnt from '../../../constants'

export function AddMeeting () {
  const {
    project,
    setListenRealTime,
    setMeetings,
    workspace
  } = useContext(ProjectContext)
  const [link, setLink] = useState('')
  const param = useParams()
  const customFetch = useCustomFetch()

  async function sendBot () {
    try {
      // have to reload real time listening with the new meeting
      setListenRealTime(false)
      const newMeet = await customFetch('/createBot', 'POST', { link, projectId: param.id, projectName: project.name, projectDescription: project.description })
      if (newMeet.error) {
        toast.error(newMeet.error)
        return
      }
      setMeetings(prevMeetings => [newMeet.meeting, ...prevMeetings])
      setListenRealTime(true)
      toast.success('Great! Go accept the bot into the meeting.', { duration: 3000 })
    } catch (error) {
      console.error('Error sending bot:', error)
    }
  }

  return (
    <Tippy disabled={workspace.status === 'active' || workspace.additional_amount + workspace.sub_hours_left > cnt.min_time_needed} maxWidth={300} content='This feature is disabled since your account is out of free hours. Please upgrade to continue using our AI features.'>
    <div className="relative h-8">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg width="17" height="11" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.667 5.00061L18.4729 2.72461C18.6338 2.64844 18.8126 2.61249 18.9923 2.62015C19.1719 2.62782 19.3466 2.67885 19.4996 2.76841C19.6526 2.85797 19.7789 2.98308 19.8665 3.13188C19.9542 3.28067 20.0002 3.44822 20.0003 3.61861V10.3826C20.0002 10.553 19.9542 10.7205 19.8665 10.8693C19.7789 11.0181 19.6526 11.1432 19.4996 11.2328C19.3466 11.3224 19.1719 11.3734 18.9923 11.3811C18.8126 11.3887 18.6338 11.3528 18.4729 11.2766L13.667 9.00061V5.00061Z" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.5556 1H3.11111C1.94518 1 1 1.89543 1 3V11C1 12.1046 1.94518 13 3.11111 13H11.5556C12.7215 13 13.6667 12.1046 13.6667 11V3C13.6667 1.89543 12.7215 1 11.5556 1Z" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
        <input
            disabled={workspace.status !== 'active' && workspace.additional_amount + workspace.sub_hours_left <= cnt.min_time_needed}
            type="url"
            id="default-search"
            className="h-full w-full ps-10 text-sm placeholder-grey-400 text-grey-400 bg-grey-100 rounded border border-grey-200"
            placeholder="Paste meeting link to add"
            onChange={e => setLink(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && sendBot()}
            required
        />
    </div>
    </Tippy>
  )
}
