import React from 'react'

const GroupNotesByMeetingIcon = ({ groupNotesBy }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1287_4882)">
        <path
          d="M10.4998 4.66602H5.83317C5.18884 4.66602 4.6665 5.18835 4.6665 5.83268V10.4993C4.6665 11.1437 5.18884 11.666 5.83317 11.666H10.4998C11.1442 11.666 11.6665 11.1437 11.6665 10.4993V5.83268C11.6665 5.18835 11.1442 4.66602 10.4998 4.66602Z"
          stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.1668 4.66602H17.5002C16.8558 4.66602 16.3335 5.18835 16.3335 5.83268V10.4993C16.3335 11.1437 16.8558 11.666 17.5002 11.666H22.1668C22.8112 11.666 23.3335 11.1437 23.3335 10.4993V5.83268C23.3335 5.18835 22.8112 4.66602 22.1668 4.66602Z"
          stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4998 16.334H5.83317C5.18884 16.334 4.6665 16.8563 4.6665 17.5007V22.1673C4.6665 22.8116 5.18884 23.334 5.83317 23.334H10.4998C11.1442 23.334 11.6665 22.8116 11.6665 22.1673V17.5007C11.6665 16.8563 11.1442 16.334 10.4998 16.334Z"
          stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.1668 16.334H17.5002C16.8558 16.334 16.3335 16.8563 16.3335 17.5007V22.1673C16.3335 22.8116 16.8558 23.334 17.5002 23.334H22.1668C22.8112 23.334 23.3335 22.8116 23.3335 22.1673V17.5007C23.3335 16.8563 22.8112 16.334 22.1668 16.334Z"
          stroke={groupNotesBy === 'meeting' ? '#FF4A8E' : 'black'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1287_4882">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GroupNotesByMeetingIcon
