/* eslint-disable react/no-unknown-property */
import React, { useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { MeetingProvider } from '../../utils/meetingProvider'
import { formatFileDuration, getDayAndMonth, getHour, getMeetingProvider } from '../../../utils'
import { ProjectContext } from '../../../context/ProjectContext'
import recording from '../../../assets/recording.gif'

export function MeetingInfo ({ meeting, isEditing, setIsEditing }) {
  const { handleRenameMeeting } = useContext(ProjectContext)
  const meetingRef = useRef()
  const meetingNameRef = useRef()

  if (meeting.duration) {
    let start
    if (meeting.start && typeof meeting.start !== 'number') {
      start = new Date(meeting.start).getTime()
    } else {
      start = meeting.start ? meeting.start : meeting.created_at
    }
    meeting.end = start + meeting.duration * 1000
  }

  return (
      <div ref={meetingRef} className='w-full py-5 flex justify-between items-center'>
        <div className='flex'>
          {meeting.processing
            ? <svg className='w-8 h-8' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a1" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stopColor="#FF156D"></stop><stop offset=".3" stopColor="#FF156D" stopOpacity=".9"></stop><stop offset=".6" stopColor="#FF156D" stopOpacity=".6"></stop><stop offset=".8" stopColor="#FF156D" stopOpacity=".3"></stop><stop offset="1" stopColor="#FF156D" stopOpacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a1)" strokeWidth="15" strokeLinecap="round" strokeDasharray="200 1000" strokeDashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FF156D" strokeWidth="15" strokeLinecap="round" cx="100" cy="100" r="70"></circle></svg>
            : meeting.isLive
              ? <div className='w-8 h-8'><img src={recording} alt="Recording" /></div>
              : <MeetingProvider provider={!meeting.stored_name && !meeting.meeting_url ? 'custom' : getMeetingProvider(meeting.meeting_url)} />}
          <div className='ml-2'>
          {isEditing
            ? (
              <input
                ref={meetingNameRef}
                className="text-zinc text-base font-semibold font-['Manrope'] leading-tight"
                type="text"
                placeholder='Unnamed Meeting.'
                value={meeting.name}
                onChange={e => handleRenameMeeting(e.target.value, meeting.id)}
                onKeyDown={e => e.key === 'Enter' && meetingNameRef.current.blur()}
                onBlur={() => setIsEditing(false)}
                autoFocus
              />
              )
            : (
              <p
                className="text-zinc text-base font-semibold font-['Manrope'] leading-tight"
                onDoubleClick={(e) => {
                  e.stopPropagation()
                  setIsEditing(true)
                }}
              >
                {meeting.name.length > 29 ? meeting.name.substring(0, 29) + '...' : meeting.name}
              </p>
              )}
            <div className="flex flex-wrap gap-y-1 mt-1 items-center text-slate text-[13px] font-medium font-['Manrope'] leading-none">
              {!meeting.start
                ? (
                  <>
                    <p>{getDayAndMonth(new Date(meeting.created_at))}・</p>
                    <p>{getHour(new Date(meeting.created_at))}</p>
                    <p>{meeting.end ? <span>&nbsp;- {getHour(new Date(meeting.end))}</span> : ''}</p>
                  </>
                  )
                : (
                  <>
                    <p>{getDayAndMonth(new Date(meeting.start))}・</p>
                    <p>{getHour(new Date(meeting.start))}</p>
                    <p>{meeting.end ? <span>&nbsp;- {getHour(new Date(meeting.end))}</span> : ''}</p>
                  </>
                  )
              }
              {!!meeting.duration && <p>・&nbsp;{formatFileDuration(meeting.duration)}</p>}
            </div>
          </div>
        </div>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="14" cy="14" r="10.5" transform="rotate(-90 14 14)" fill="#EBEBEB"/>
          <path d="M12.834 10.5L16.334 14L12.834 17.5" stroke="#40424A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
  )
}

MeetingInfo.propTypes = {
  meeting: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired
}
