import React from 'react'

export const StarIcon = ({checked}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={checked ? '#FFC921' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1983_5083)">
        <path
          d="M12.3748 17.75L6.20284 20.995L7.38184 14.122L2.38184 9.25495L9.28184 8.25495L12.3678 2.00195L15.4538 8.25495L22.3538 9.25495L17.3538 14.122L18.5328 20.995L12.3748 17.75Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1983_5083">
          <rect width="24" height="24" fill="white" transform="translate(0.375)" />
        </clipPath>
      </defs>
    </svg>
  )
}
