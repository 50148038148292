import React, { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useCustomFetch } from '../hooks/useCustomFetch'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { toast } from 'react-hot-toast'
import { AuthContext } from './AuthContext'

export const EventsContext = createContext()

export const EventsContextProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const [finishProcessingMeeting, setFinishProcessingMeeting] = useState({ projectId: '', isLastFile: false })
  const [finishProcessingOverview, setFinishProcessingOverview] = useState({ projectId: '' })
  const navigate = useNavigate()
  const customFetch = useCustomFetch()

  async function readEventNotesReady(eventId, projectId, projectName, isLastFile, error) {
    await customFetch('/readEvent', 'POST', { eventId })
    if (error) {
      toast.error('There was an error processing your notes. Please try again later.', { duration: 5000 })
    }
    setFinishProcessingMeeting({ projectId, isLastFile })
  }

  async function readEventOverviewReady(eventId, projectId, projectName, error) {
    await customFetch('/readEvent', 'POST', { eventId })
    if (error) {
      toast.error('There was an error processing your overview. Please try again later.', { duration: 5000 })
    } else {
      function handleClick() {
        setFinishProcessingOverview({ projectId })
        navigate(`/project/${projectId}`)
      }

      toast.custom(
        () => (
          <div
            style={{
              background: '#fff',
              color: '#363636',
              boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05)',
              padding: '16px 20px',
              borderRadius: '8px',
            }}
          >
            Your overview is ready!&nbsp;
            <button style={{ color: '#0052CC' }} onClick={handleClick}>
              {projectName}
            </button>
          </div>
        ),
        { duration: 5000 }
      )
    }
  }

  // Listen for events
  useEffect(() => {
    if (typeof user !== 'object' || Object.keys(user).length === 0) return

    const q = query(collection(db, 'events'), where('user_id', '==', user.id), where('read', '==', false))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          if (change.doc.data().type === 'overview') {
            readEventOverviewReady(
              change.doc.id,
              change.doc.data().project_id,
              change.doc.data().project_name,
              change.doc.data().error
            )
          } else {
            readEventNotesReady(
              change.doc.id,
              change.doc.data().project_id,
              change.doc.data().project_name,
              change.doc.data().is_last_file,
              change.doc.data().error
            )
          }
        }
      })
    })
    return unsubscribe
  }, [user])

  return (
    <EventsContext.Provider
      value={{
        finishProcessingMeeting,
        setFinishProcessingMeeting,
        finishProcessingOverview,
        setFinishProcessingOverview,
      }}
    >
      {children}
    </EventsContext.Provider>
  )
}

EventsContextProvider.propTypes = {
  children: PropTypes.node,
}
