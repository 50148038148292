import React, { useContext, useEffect, useRef, useState } from 'react'
import defaultAvatar from '../../../assets/default_avatar.png'
import { MeetingsContext } from '../../../context/MeetingContext'
import { Rename } from '../../utils/rename'
import PropTypes from 'prop-types'

export function Participant ({ participantId }) {
  const { participants, handleRenameParticipant } = useContext(MeetingsContext)
  const [openRenameMenu, setOpenRenameMenu] = useState(false)
  const [rename, setRename] = useState(false)
  const participantRef = useRef(null)

  useEffect(() => {
    if (rename) participantRef.current.focus()
  }, [rename])

  return (
      <div className='relative'>
        <input
          ref={participantRef}
          type='text'
          className={`w-full bg-grey-100 text-zinc text-xl font-bold font-['Manrope'] ${!rename && 'hidden'}`}
          value={participants[participantId].name}
          onChange={e => handleRenameParticipant(e.target.value, participantId)}
          onKeyDown={e => e.key === 'Enter' && setRename(false)}
          onBlur={() => setRename(false)}
          autoFocus
        />

        <button onClick={() => setOpenRenameMenu(true)} className={`bg-white py-[4px] px-[6px] rounded-[5px] border border-[#e4e4e4] flex items-center gap-[6px] ${rename && 'hidden'}`}>
          <img className='h-[16px] w-[16px] rounded-full' src={defaultAvatar} alt="user avatar" />
          <p className="text-[#3f424a] text-[13px] font-bold font-['Manrope'] leading-none">{participants[participantId].name}</p>
          {participants[participantId].role === 'facilitator' && (
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 7.44979C8.02655 7.55694 8.5736 7.4916 9.06009 7.26343C9.54658 7.03527 9.94661 6.65643 10.2009 6.18307C10.4552 5.70971 10.5502 5.16702 10.4718 4.63543C10.3935 4.10384 10.1459 3.61164 9.76585 3.23178C9.3858 2.85193 8.89348 2.60463 8.36185 2.52654C7.83021 2.44846 7.28757 2.54373 6.81434 2.79826C6.34111 3.05278 5.96248 3.45301 5.73457 3.93961C5.50665 4.42622 5.44158 4.9733 5.549 5.49979" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.4998 7.4495L5.5488 5.5L1.7923 9.792C1.60466 9.97944 1.49916 10.2337 1.49902 10.499C1.49895 10.6303 1.52475 10.7603 1.57494 10.8817C1.62513 11.0031 1.69874 11.1133 1.79155 11.2062C1.88436 11.2992 1.99456 11.3729 2.11587 11.4232C2.23717 11.4735 2.36719 11.4995 2.49852 11.4995C2.76374 11.4997 3.01816 11.3944 3.2058 11.207L7.4998 7.4495Z" stroke="#62718D" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          )}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 14 2)" fill="#EBEBEB"/>
            <path d="M6 7.33398L8 9.33398L10 7.33398" stroke="#40424A" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>

        {openRenameMenu && (
          <Rename
            onClose={() => setOpenRenameMenu(false)}
            handleRename={() => {
              setRename(true)
              setOpenRenameMenu(false)
            }}
          />
        )}
      </div>
  )
}

Participant.propTypes = {
  participantId: PropTypes.string
}
