import React, { useContext, useRef } from 'react'
import { ProjectContext } from '../../../context/ProjectContext'
import { Feedback } from './feedback'
import PropTypes from 'prop-types'
import useOutsideClick from '../../../hooks/useOutsideClick'
import { createPortal } from 'react-dom'
import { DeleteFeedbacksDialog } from './deleteFeedbacksDialog'

export const Feedbacks = ({ feedbacks, overviewFlag = false }) => {
  const {
    setSelectedFeedbacks,
    setSelectedNotes,
    firstFeedbackSelected,
    setFirstFeedbackSelected,
    feedbackHover,
    setFeedbackHover,
    showDeleteFeedbacksDialog,
    setShowDeleteFeedbacksDialog,
    selectedFeedbacks,
    handleDeleteFeedbacks
  } = useContext(ProjectContext)
  const feedbacksRef = useRef(null)

  useOutsideClick(feedbacksRef, () => {
    if (!showDeleteFeedbacksDialog) {
      setSelectedFeedbacks([])
      setFirstFeedbackSelected(null)
      setFeedbackHover(null)
    }
  })

  const handleFeedbackSelect = (feedbackId, event) => {
    event.stopPropagation()
    setSelectedNotes([])
    if (event.ctrlKey || event.metaKey) {
      // Ctrl (or Cmd) key for multiple select
      setSelectedFeedbacks(prevSelectedFeedbacks =>
        prevSelectedFeedbacks.includes(feedbackId)
          ? prevSelectedFeedbacks.filter(id => id !== feedbackId)
          : [...prevSelectedFeedbacks, feedbackId]
      )
      if (selectedFeedbacks.length === 0) {
        setFirstFeedbackSelected(feedbackId)
        setFeedbackHover(feedbackId)
      }
    } else if (event.shiftKey) {
      // Shift key for range select
      if (selectedFeedbacks.length === 0) {
        setSelectedFeedbacks([feedbackId])
        setFirstFeedbackSelected(feedbackId)
        setFeedbackHover(feedbackId)
        return
      }
      const currentFeedbackIndex = feedbacks.findIndex(feedback => feedback.id === feedbackId)
      const firstFeedbackIndex = feedbacks.findIndex(feedback => feedback.id === firstFeedbackSelected)

      if (currentFeedbackIndex < firstFeedbackIndex) {
        setSelectedFeedbacks(feedbacks.slice(currentFeedbackIndex, firstFeedbackIndex + 1).map(feedback => feedback.id))
      } else if (currentFeedbackIndex > firstFeedbackIndex) {
        setSelectedFeedbacks(feedbacks.slice(firstFeedbackIndex, currentFeedbackIndex + 1).map(feedback => feedback.id))
      }
    } else {
      // Single select
      setSelectedFeedbacks([feedbackId])
      setFirstFeedbackSelected(feedbackId)
      setFeedbackHover(feedbackId)
    }
  }

  return (
    <>
      <div className='w-full flex flex-wrap pt-5 relative' ref={feedbacksRef} onClick={() => {
        setSelectedFeedbacks([])
        setFirstFeedbackSelected(null)
        setFeedbackHover(null)
      }}>
        {feedbacks.map(feedback =>
          <Feedback
            key={feedback.id}
            feedback={feedback}
            selected={selectedFeedbacks.includes(feedback.id)}
            handleFeedbackSelect={handleFeedbackSelect}
            feedbackHover={feedbackHover}
            setFeedbackHover={setFeedbackHover}
          />
        )}
      </div>

      {showDeleteFeedbacksDialog && createPortal(
        <DeleteFeedbacksDialog handleDeleteFeedbacks={handleDeleteFeedbacks} feedbacksCount={selectedFeedbacks.length} handleClose={() => setShowDeleteFeedbacksDialog(false)} />,
        document.body
      )}
    </>
  )
}

Feedbacks.propTypes = {
  feedbacks: PropTypes.array,
  overviewFlag: PropTypes.bool
}
