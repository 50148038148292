import { useState, useEffect } from 'react'

export const useFilteredData = (data, initialFilters = {}) => {
  const [filteredData, setFilteredData] = useState([])
  const [filters, setFilters] = useState({
    star: 'all', // true, false, all
    tags: [],
    meetings: [],
    categories: [],
    ...initialFilters,
  })

  useEffect(() => {
    if (data.length) {
      let newData = JSON.parse(JSON.stringify(data))

      if (typeof filters.star === 'boolean') {
        if (filters.star) {
          newData = newData.filter((item) => item.star)
        } else {
          newData = newData.filter((item) => !item.star)
        }
      }

      if (filters.tags.length) {
        newData = newData.filter((item) => filters.tags.every((tag) => item.tags.includes(tag)))
      }

      if (filters.meetings.length) {
        newData = newData.filter((item) => filters.meetings.includes(item.meetingId || item.meeting_id))
      }

      if (filters.categories?.length) {
        newData = newData.filter((item) => filters.categories.includes(item.category_id))
      }

      newData.sort((a, b) => a.start_time - b.start_time)
      setFilteredData(newData)
    } else {
      setFilteredData([])
    }
  }, [data, filters])

  return { data: filteredData, filters, setFilters, setFilteredData }
}
